import {RESPONSE} from "../../../../../constants/ResponseCodes";
import {NOTIFICATION_TYPE} from "../../../../../constants/NotificationType";
import {COMMON_STRINGS, HELP_CENTER_STRINGS, NOTIFICATION_STRING} from "../../../../../constants/Strings";
import {VIEW, WORKFLOW} from "../../../../workflow/Workflow";
import {ROUTING} from "../../../../../constants/Routing";
import {SCOPE} from "../../../../../constants/Scope";
import {getNotificationFormat, SLASH} from "../../../../utils/Util";
import {PROBLEM_SOLVE} from "../../../../../constants/ProblemSolveCodes";
import {CUSTOM_HELP_OPTIONS} from "../../../../app-navigation/AppNavigation";
import {ApplicationActions} from "../../../../player/action/ApplicationActions";

export const getStowScanLocationViewData = (input) => {
    const values = {
        displayPackageId: input.displayPackageId,
        locationLabel: input.locationLabel
    }
    switch (input.responseCode) {
        case RESPONSE.SUCCESS:
        case RESPONSE.PACKAGE_ALREADY_STOWED:
            return {
                view: VIEW.STOW_PACKAGE,
                notification: getNotificationFormat(
                    NOTIFICATION_TYPE.SUCCESS,
                    NOTIFICATION_STRING.ALERT_SUCCESS_PACKAGE_STOWED,
                    values
                )
            }
        case RESPONSE.NO_PLAN_PRESENT:
            return {
                nextRoute: ROUTING.PROBLEM_SOLVE + SLASH + PROBLEM_SOLVE.STOW_FAILURE,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_PACKAGE_CANNOT_BE_STOWED,
                    values)
            }
        case RESPONSE.BAG_NOT_OPEN:
            return {
                nextRoute: ROUTING.MOVE_TO_BUFFER,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_BAG_NOT_OPEN,
                    values)
            }
        case RESPONSE.INVALID_MOVE:
            return {
                view: VIEW.STOW_RESCAN_PACKAGE,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_INCORRECT_LOCATION,
                    values)
            }
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                view: VIEW.STOW_RESCAN_PACKAGE,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_INVALID_LOCATION_SCANNED)
            }
        case RESPONSE.INVALID_PACKAGE_STATE:
            return {
                view: VIEW.STOW_RESCAN_PACKAGE,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_SOMETHING_HAPPENED_WITH_PACKAGE)
            }
        case RESPONSE.BAG_WEIGHT_LIMIT_REACHED:
            return {
                view: VIEW.STOW_PACKAGE,
                notification: getNotificationsForBagWeightLimitReached(input)
            }
        case RESPONSE.INVALID_PACKAGE_SORTGROUP:
            return {
                nextRoute: ROUTING.MOVE_TO_BUFFER,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_INVALID_PACKAGE_SORT_GROUP,
                    values),
                workflow: WORKFLOW.STOW
            }
        case RESPONSE.NO_INTERNET:
            return {
                nextRoute: ROUTING.NO_INTERNET
            }
        default:
            return {
                view: VIEW.STOW_PACKAGE,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    COMMON_STRINGS.default_error)
            }
    }
}

const getNotificationsForBagWeightLimitReached = (input) => {
    const values = {
        displayPackageId: input.displayPackageId,
        locationLabel: input.locationLabel,
        bagLabel: input.bagLabel
    }

    switch (input.scope) {
        case SCOPE.SSD:
        case SCOPE.AR_NEXT_GEN:
        case SCOPE.AR:
            return getNotificationFormat(
                NOTIFICATION_TYPE.SUCCESS,
                NOTIFICATION_STRING.ALERT_SUCCESS_PACKAGE_STOWED,
                values)
        default:
            return getNotificationFormat(NOTIFICATION_TYPE.WARNING,
                NOTIFICATION_STRING.ALERT_WARNING_BAG_WEIGHT_LIMIT_REACHED,
                values)
    }
}

export const getHelpData = (props, scope, closeHelpOption, openCustomHelpOption, setBagFullWorkflow, dispatchPlayer) => {

    switch (scope) {
        case SCOPE.AMZL:
            return getAMZLHelpData(props, closeHelpOption, openCustomHelpOption, setBagFullWorkflow, dispatchPlayer)
        case SCOPE.AR:
        case SCOPE.AR_NEXT_GEN:
            return getARHelpData(props, closeHelpOption)
        case SCOPE.SSD:
            return getSSDHelpData(props, closeHelpOption, dispatchPlayer)
        case SCOPE.AMZL_IN:
            return getAMZLINHelpData(props, closeHelpOption, openCustomHelpOption, dispatchPlayer)
        default:
            return {list: []}
    }
}

const getSSDHelpData = (props, closeHelpOption, dispatchPlayer) => {
    return {
        pageTitle: HELP_CENTER_STRINGS.STOW_PROBLEMS_HELP,
        list: [
            {
                title: props.intl.formatMessage({id: HELP_CENTER_STRINGS.DAMAGED_PACKAGE}),
                onClick: () => {
                    closeHelpOption()
                    dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HELP_CENTER_STRINGS.DAMAGED_PACKAGE}});
                    props.history.push(ROUTING.PROBLEM_SOLVE + SLASH + PROBLEM_SOLVE.DAMAGED)
                }
            }
        ]
    }
}

const getARHelpData = (props, closeHelpOption) => {
    return {
        pageTitle: HELP_CENTER_STRINGS.STOW_PROBLEMS_HELP,
        list: [
            {
                title: props.intl.formatMessage({id: HELP_CENTER_STRINGS.PACKAGE_DOESNT_FIT}),
                onClick: () => {
                    closeHelpOption()
                    props.history.push(ROUTING.PROBLEM_SOLVE + SLASH + PROBLEM_SOLVE.PACKAGE_DOESNT_FIT)
                }
            },
            {
                title: props.intl.formatMessage({id: HELP_CENTER_STRINGS.BAG_IS_FULL}),
                onClick: () => {
                    closeHelpOption()
                    props.history.push(ROUTING.PROBLEM_SOLVE + SLASH + PROBLEM_SOLVE.STOW_FAILURE)
                }
            },
            {
                title: props.intl.formatMessage({id: HELP_CENTER_STRINGS.CART_NOT_AVAILABLE}),
                onClick: () => {
                    closeHelpOption()
                    props.history.push(ROUTING.PROBLEM_SOLVE + SLASH + PROBLEM_SOLVE.STOW_FAILURE)
                }
            },
            {
                title: props.intl.formatMessage({id: HELP_CENTER_STRINGS.DAMAGED_PACKAGE}),
                onClick: () => {
                    closeHelpOption()
                    props.history.push(ROUTING.PROBLEM_SOLVE + SLASH + PROBLEM_SOLVE.DAMAGED)
                }
            },
            {
                title: props.intl.formatMessage({id: HELP_CENTER_STRINGS.CANT_FIND_LOCATION}),
                onClick: () => {
                    closeHelpOption()
                    props.history.push(ROUTING.PROBLEM_SOLVE + SLASH + PROBLEM_SOLVE.STOW_FAILURE)
                }
            }
        ]
    }
}

const getAMZLHelpData = (props, closeHelpOption, openCustomHelpOption, setBagFullWorkflow, dispatchPlayer) => {
    return {
        pageTitle: HELP_CENTER_STRINGS.STOW_PROBLEMS_HELP,
        list: [
            markForProblemSolveHelpData(props, closeHelpOption, openCustomHelpOption, dispatchPlayer),
            {
                title: props.intl.formatMessage({id: HELP_CENTER_STRINGS.BAG_FULL}),
                onClick: () => {
                    dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HELP_CENTER_STRINGS.BAG_FULL}});
                    props.history.push(ROUTING.MOVE_TO_BUFFER)
                    closeHelpOption()
                    setBagFullWorkflow()
                }
            }
        ]
    }
}

const getAMZLINHelpData = (props, closeHelpOption, openCustomHelpOption, dispatchPlayer) => {
    return {
        pageTitle: HELP_CENTER_STRINGS.STOW_PROBLEMS_HELP,
        list: [
            markForProblemSolveHelpData(props, closeHelpOption, openCustomHelpOption, dispatchPlayer)
        ]
    }
}

const markForProblemSolveHelpData = (props, closeHelpOption, openCustomHelpOption, dispatchPlayer) => {
    return {
        title: props.intl.formatMessage({id: HELP_CENTER_STRINGS.MARK_FOR_PROBLEM_SOLVE}),
        onClick: () => {
            dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HELP_CENTER_STRINGS.MARK_FOR_PROBLEM_SOLVE}});
            closeHelpOption()
            openCustomHelpOption(CUSTOM_HELP_OPTIONS.PROBLEM_SOLVE)
        }
    }
}
