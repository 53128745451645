import {getProblemSolveViewData} from "../view/data/ProblemSolveViewData";
import {COMMON_ACTIONS} from "../../../constants/ActionType";

export const MOVE_TO_PROBLEM_SOLVE_RESPONSE = "MOVE_TO_PROBLEM_SOLVE_RESPONSE"

export const ProblemSolveReducer = (state, action) => {
    switch (action.type) {
        case MOVE_TO_PROBLEM_SOLVE_RESPONSE:
            const problemSolveViewData = getProblemSolveViewData(action.data)
            return {
                ...state,
                ...action.data,
                ...problemSolveViewData
            }
        case COMMON_ACTIONS.LOADING:
            return {
                ...state,
                loading: true,
            }
        default:
            return state;
    }
}
