import {AR_VIEW_STRINGS} from "../../../../constants/Strings";
import {CONSTANT} from "../../../../constants/Constants";
import {SCOPE} from "../../../../constants/Scope";
import {getFormattedString} from "../../../utils/Util";

const init = (onClick, props) => {
    return {
        pageTitle: getFormattedString(props, AR_VIEW_STRINGS.select_sortation_type),
        tasks: [
            {
                title: getFormattedString(props, AR_VIEW_STRINGS.conveyable_packages),
                description: getFormattedString(props, AR_VIEW_STRINGS.conveyable_packages_desc),
                onClick: () => onClick(CONSTANT.CON_PACKAGE)
            },
            {
                title: getFormattedString(props, AR_VIEW_STRINGS.non_conveyable_packages),
                description: getFormattedString(props, AR_VIEW_STRINGS.non_conveyable_packages_desc),
                onClick: () => onClick(CONSTANT.NON_CON_PACKAGE)
            }
        ]
    }
}

const init_ar_next_gen = (onClick, props) => {
    return {
        pageTitle: getFormattedString(props, AR_VIEW_STRINGS.select_sortation_type),
        tasks: [
            {
                title: getFormattedString(props, AR_VIEW_STRINGS.select_stow_type_ar_field),
                description: getFormattedString(props, AR_VIEW_STRINGS.select_stow_type_ar_field_desc),
                onClick: () => onClick(CONSTANT.CON_PACKAGE)
            },
            {
                title: getFormattedString(props, AR_VIEW_STRINGS.select_stow_type_drs),
                description: getFormattedString(props, AR_VIEW_STRINGS.select_stow_type_drs_desc),
                onClick: () => onClick(CONSTANT.NON_CON_PACKAGE)
            }
        ]
    }
}

export const getTask = (scope, onClick, props) =>  {

    switch (scope) {
        case SCOPE.AR_NEXT_GEN:
            return init_ar_next_gen(onClick, props)
        case SCOPE.AR:
        default:
            return init(onClick, props)

    }
}
