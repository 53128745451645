import React, {useContext, useEffect} from "react";
import {injectIntl} from "react-intl";
import {APP_NAVIGATION_STRINGS, NOTIFICATION_STRING} from "../../../../constants/Strings";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {VIEW, WORKFLOW} from "../../../workflow/Workflow";
import {getFormattedString, isNonNull} from "../../../utils/Util";
import {NotificationContext} from "../../../notification/context/NotificationContext";
import BagScanView from "../../prepare-cart/view/bag-scan/BagScanView";
import BagScanLocationView from "../../prepare-cart/view/bag-scan-location/BagScanLocationView";
import ScanContainerLocationView from "./scan-container-location/ScanContainerLocationView";
import {NOTIFICATION_TYPE} from "../../../../constants/NotificationType";
import {getContainerRoutingPath, ROUTING} from "../../../../constants/Routing";
import {ConfigContext} from "../../../config/context/ConfigContext";
import {CONTAINER_TYPE} from "../../../../constants/ContainerType";
import {setGlobalContext, setScope} from "../../../config/action/ConfigAction";
import {PrepareContainerContext} from "../../prepare-container/context/PrepareContainerContext";
import ScanCartView from "../../prepare-container/view/scan-cart/ScanCartView";
import ReplaceBagView from "../../prepare-cart/view/replace-bag/ReplaceBagView";
import {ScanTypes} from "../../../player/PlayerConstant";
import {ApplicationActions} from "../../../player/action/ApplicationActions";
import {PlayerContext} from "../../../player/context/PlayerContext";
import {interceptBackButton, interceptBackButtonStop} from "../../../bridge/BackButton";
import AppDataStore from "../../../app-data";
import {SCOPE} from "../../../../constants/Scope";
import CartStageReadinessPollerInstance from "../../../../util/CartStageReadinessPoller";

const PrepareStowLocationView = (props) => {

    const {prepareContainerModel} = useContext(PrepareContainerContext)
    const {navActions: {clearContext, initContext, openBackDrop, setNavTitle}} = useContext(AppNavigationContext)
    const {notificationActions: {setNotification}} = useContext(NotificationContext)
    const {configViewDispatch, configViewModel} = useContext(ConfigContext)
    const {statePlayer, dispatchPlayer} = useContext(PlayerContext);

    const dispatchReplaceContainerAction = () => {
        setGlobalContext(configViewDispatch, {
            notification: {
                type: NOTIFICATION_TYPE.SUCCESS,
                message: NOTIFICATION_STRING.ALERT_ERROR_CART_MOVED_TO_PS,
                values: {scannedCartId: prepareContainerModel.activeCartId}
            }
        })
    }

    useEffect(() => {
        if (isNonNull(prepareContainerModel.notification)) {
            setNotification(prepareContainerModel.notification)
        }
        if (isNonNull(prepareContainerModel.nextRoute)) {
            if (getContainerRoutingPath(ROUTING.REPLACE_CONTAINER, CONTAINER_TYPE.CART) === prepareContainerModel.nextRoute) {
                dispatchReplaceContainerAction();
            }
            props.history.push(prepareContainerModel.nextRoute)
        }
        if (isNonNull(prepareContainerModel.responseCode) && !(prepareContainerModel.loading)) {
            switch (statePlayer.scanType) {
                case ScanTypes.PREP_CART_SCAN:
                    dispatchPlayer({type: ApplicationActions.CONTAINER_CART_SCAN, data: {errorResponse: prepareContainerModel.responseCode}});
                    break;
                case ScanTypes.PREP_CART_LOCATION_SCAN:
                    dispatchPlayer({type: ApplicationActions.CONTAINER_CART_LOCATION_SCAN, data: {errorResponse: prepareContainerModel.responseCode, workflow: prepareContainerModel.workflow}});
                    break;
                default:
                    break;
            }
        }
    }, [prepareContainerModel]);

    useEffect(() => {
        const scope = (configViewModel && configViewModel.scope) ? configViewModel.scope : AppDataStore.getProgramType();
        setScope(configViewDispatch, {scope: scope});

        AppDataStore.redirectComplete();
        if (AppDataStore.isHotSwapInProgress() && (SCOPE.AR_NEXT_GEN === scope)) {
            CartStageReadinessPollerInstance.initializeCartList(AppDataStore.getCartList());
        }

        setNavTitle(getFormattedString(props, APP_NAVIGATION_STRINGS.prepare_stow_location))
        let workflow = WORKFLOW.ATTACH_CART
        if (isNonNull(configViewModel.workflow)) {
            workflow = configViewModel.workflow
        }
        setGlobalContext(configViewDispatch, {workflow: workflow})
        const onBackPressed = () => {
            openBackDrop({
                secondaryButtonOnClick: () => props.history.push(ROUTING.ENTRY)
            });
        };
        initContext({onBackPressed});
        interceptBackButton('prepare cart view')
        return clearContext
    }, []);

    const renderView = () => {
        if (VIEW.BAG_SCAN_VIEW === prepareContainerModel.view) {
            return <BagScanView history={props.history}/>
        } else if (VIEW.BAG_SCAN_LOCATION_VIEW === prepareContainerModel.view) {
            return <BagScanLocationView history={props.history}/>
        } else if (VIEW.SCAN_CONTAINER_LOCATION === prepareContainerModel.view) {
            return <ScanContainerLocationView history={props.history} intl={props.intl}/>
        } else if (VIEW.REPLACE_BAG === prepareContainerModel.view) {
            return <ReplaceBagView/>
        } else if (VIEW.STOW_PACKAGE === prepareContainerModel.view) {
            interceptBackButtonStop();
            props.history.push(ROUTING.STOW_PACKAGE);
        }
        return <ScanCartView history={props.history}/>
    }

    return renderView()
}

export default injectIntl(PrepareStowLocationView)
