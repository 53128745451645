import React, {useContext, useEffect} from 'react';
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import {FormattedMessage, injectIntl} from "react-intl";
import {getButtonText, getHeader} from "./data/ViewData";
import {NotificationContext} from "../../../notification/context/NotificationContext";
import {ROUTING} from "../../../../constants/Routing";
import {isNonNull, SLASH} from "../../../utils/Util";
import {ConfigContext} from "../../../config/context/ConfigContext";
import HeadingView, {HEADING_TYPE} from "../../../heading-view/HeadingView";
import {CONTAINER_TYPE} from "../../../../constants/ContainerType";
import {interceptBackButton, interceptBackButtonStop} from "../../../bridge/BackButton";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {PlayerContext} from "../../../player/context/PlayerContext";
import {ApplicationActions} from "../../../player/action/ApplicationActions";

const ReplaceContainerView = (props) => {

    const {notificationActions: {setNotification}} = useContext(NotificationContext);
    const {configViewModel} = useContext(ConfigContext)
    const {navActions: {closeBackEnabled}} = useContext(AppNavigationContext)
    const {dispatchPlayer} = useContext(PlayerContext);

    const onClick = () => {
        if (isNonNull(configViewModel.notification)) {
            setNotification(configViewModel.notification)
        }
        if (props.containerScannableType === CONTAINER_TYPE.BAG) {
            dispatchPlayer({type: ApplicationActions.CONTAINER_SCRUB_BAG_ACCEPTED});
            props.history.push(ROUTING.PREPARE_CONTAINER + SLASH + CONTAINER_TYPE.BAG)
        } else {
            dispatchPlayer({type: ApplicationActions.REPLACE_CART});
            props.history.push(ROUTING.PREPARE_STOW_LOCATION)
        }
    }

    useEffect(() => {
        closeBackEnabled()
        interceptBackButton('replace container view')
        return interceptBackButtonStop
    }, [])

    return (
        <div>
            <Column height="100%" spacingInset="medium" heights="fill">
                <Column alignmentHorizontal="center">
                    <HeadingView {...getHeader(props.containerScannableType)} type={HEADING_TYPE.PRIMARY}/>
                </Column>
                <Column className={"footer"} spacingInset={"small"}>
                    <Button onClick={onClick} size="large" type="primary">
                        <FormattedMessage id={getButtonText(props.containerScannableType)}/>
                    </Button>
                </Column>
            </Column>
        </div>
    )
}

export default injectIntl(ReplaceContainerView)
