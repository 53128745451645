import React from "react";
import QrCode from "../../images/ic_qr_code.png";
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import {injectIntl} from "react-intl";

export const HEADING_TYPE = {
    PRIMARY: "PRIMARY",
    SECONDARY: "SECONDARY"
}

const ScanQrCodeView = (props) => {

    const getTitles = () => {
        return HEADING_TYPE.PRIMARY === props.type ?
            {
                primaryTitle: getBoldTitle(props.primaryTitle),
                secondaryTitle: getNormalTitle(props.secondaryTitle)
            } :
            {
                primaryTitle: getNormalTitle(props.primaryTitle),
                secondaryTitle: getBoldTitle(props.secondaryTitle)
            }
    }

    const getBoldTitle = (title) => {
        return {
            title: props.intl.formatMessage({id: title}),
            type: "Bold",
            size: "Large"
        }
    }

    const getNormalTitle = (title) => {
        return {
            title: props.intl.formatMessage({id: title})
        }
    }

    return (
        <ScanWithCodeView {...getTitles()} image={QrCode}/>
    )
}

export default injectIntl(ScanQrCodeView)
