import {Constants} from "@amzn/dolphin-web-framework";
import {Modules} from  "../../../constants/Modules";
import {PWADomainHelper} from "@amzn/dolphin-web-framework";

export const getDolphinBaseURL = () => {
    const region = getRegion();
    switch (getStage()) {
        case Constants.Stage.BETA:
            switch (region) {
                case Constants.Region.REGION_NA:
                    return Constants.DolphinURL.NA.PREPROD;
                case Constants.Region.REGION_EU:
                    return Constants.DolphinURL.EU.PREPROD;
                case Constants.Region.REGION_IN:
                    return Constants.DolphinURL.IN.PREPROD;
                // no default
            }
            /* istanbul ignore next */
            break;
        case Constants.Stage.GAMMA:
            switch (region) {
                case Constants.Region.REGION_NA:
                    return Constants.DolphinURL.NA.PREPROD;
                case Constants.Region.REGION_EU:
                    return Constants.DolphinURL.EU.PREPROD;
                case Constants.Region.REGION_FE:
                    return Constants.DolphinURL.FE.PREPROD;
                case Constants.Region.REGION_IN:
                    return Constants.DolphinURL.IN.PREPROD;
                // no default
            }
            /* istanbul ignore next */
            break;
        case Constants.Stage.PROD:
            switch (region) {
                case Constants.Region.REGION_NA:
                    return Constants.DolphinURL.NA.PROD;
                case Constants.Region.REGION_EU:
                    return Constants.DolphinURL.EU.PROD;
                case Constants.Region.REGION_FE:
                    return Constants.DolphinURL.FE.PROD;
                case Constants.Region.REGION_IN:
                    return Constants.DolphinURL.IN.PROD;
                // no default
            }
            /* istanbul ignore next */
            break;
        // no default
    }
};

export const getSblServiceBaseURL = () => {
    switch (getRegion()) {
        case Constants.Region.REGION_NA:
            return "https://tv5ijhd2s0.execute-api.us-west-2.amazonaws.com/prod/";
        case Constants.Region.REGION_IN:
        case Constants.Region.REGION_EU:
            return "https://mxvc82twnd.execute-api.eu-west-1.amazonaws.com/prod/";
        case Constants.Region.REGION_FE:
            return "https://e1ioluw7k9.execute-api.ap-northeast-1.amazonaws.com/prod/";
        // no default
    }
}

export const getSblApiKey = () => {
    switch (getRegion()) {
        case Constants.Region.REGION_NA:
            return "EKWCh0MbyD7SF228uKq8E9oVC6zjEEBB4oEZFSo8";
        case Constants.Region.REGION_IN:
        case Constants.Region.REGION_EU:
            return "EJPQ4tmKcBEWKgJA8aBq6uGY4IiSiwU85UIipqsa";
        case Constants.Region.REGION_FE:
            return "u5NOzZ1qLOabu2VfxvSDpapiNMFkDOLc3CtUgqyA";
        // no default
    }
}

export const getRegion = () => {
    const hostname = window.location.hostname;
    if (hostname.startsWith(Constants.RegionLowerCase.REGION_NA)) return Constants.Region.REGION_NA;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_EU)) return Constants.Region.REGION_EU;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_FE)) return Constants.Region.REGION_FE;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_IN)) return Constants.Region.REGION_IN;
    return Constants.Region.REGION_NA;
}

export const getAnalyticsRegion = () => {
    const hostname = window.location.hostname;
    if (hostname.startsWith(Constants.RegionLowerCase.REGION_NA)) return Constants.Region.REGION_NA;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_EU)) return Constants.Region.REGION_EU;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_FE)) return Constants.Region.REGION_FE;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_IN)) return Constants.Region.REGION_EU;
    return Constants.Region.REGION_NA;
}

export function getStage() {
    const hostname = window.location.hostname.split('.');
    if (hostname[1] != null) {
        const stage = hostname[1];
        if (stage.endsWith(Constants.Stage.INTEG)) return Constants.Stage.BETA;
        else if (stage.endsWith('sew')) return Constants.Stage.PROD;
    }
    return Constants.Stage.GAMMA;
}

export function getPUMARegion() {
    const hostname = window.location.hostname;
    if (hostname.startsWith(Constants.RegionLowerCase.REGION_NA)) return Constants.RegionLowerCase.REGION_NA;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_EU)) return Constants.RegionLowerCase.REGION_EU;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_FE)) return Constants.RegionLowerCase.REGION_JP;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_IN)) return Constants.RegionLowerCase.REGION_EU;
    return Constants.RegionLowerCase.REGION_JP;
}

export function getPUMACountry() {
    const hostname = window.location.hostname;
    if (hostname.startsWith(Constants.RegionLowerCase.REGION_NA)) return Constants.Region.REGION_US;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_EU)) return Constants.Region.REGION_UK;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_FE)) return Constants.Region.REGION_JP;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_IN)) return Constants.Region.REGION_IN;
    return Constants.Region.REGION_JP;
}

export function getPUMAStage() {
    const hostname = window.location.hostname.split('.');
    if (hostname[1] != null) {
        const stage = hostname[1];
        if (stage.endsWith(Constants.Stage.INTEG)) return Constants.Stage.TEST;
        else if (stage.endsWith(Constants.Stage.MASTER)) return Constants.Stage.TEST;
        else if (stage.endsWith('sew')) return Constants.Stage.PROD;
        return Constants.Stage.TEST;
    }
    return Constants.Stage.TEST;
}

export function getLoggerUrl() {
    switch(getStage()) {
        case Constants.Stage.BETA:
            return "https://zn20tufct7.execute-api.us-west-2.amazonaws.com/prod/v1/log";
        case Constants.Stage.GAMMA:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return "https://xsatm5vu8c.execute-api.us-east-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_EU: return "https://9srd2hgyic.execute-api.eu-west-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_FE: return "https://v1q0wftan6.execute-api.us-west-2.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_IN: return "https://c2ouin2ob5.execute-api.eu-west-2.amazonaws.com/prod/v1/log";
                // no default
            }
            /* istanbul ignore next */
            break;
        case Constants.Stage.PROD:
            switch(getRegion()) {
                case Constants.Region.REGION_NA: return "https://lakjpiwlel.execute-api.us-east-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_EU: return "https://utvru8ttq4.execute-api.eu-west-1.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_FE: return "https://4bw5cmyb9l.execute-api.us-west-2.amazonaws.com/prod/v1/log";
                case Constants.Region.REGION_IN: return "https://hn3g6jt2kf.execute-api.eu-west-1.amazonaws.com/prod/v1/log";
                // no default
            }
            /* istanbul ignore next */
            break;
        // no default
    }
}

export function getStageExecutionURL() {
    const region = getRegion();
    const env = getStage().toLowerCase();
    const baseUrlData = PWADomainHelper.getVersionedBaseURLForModule(Modules.STAGE);
    let baseURL = baseUrlData.url[env][region];
    const version = baseUrlData.version;
    if(version) {
        baseURL += version + "/index.html";
    }
    return new URL(baseURL);
}
