import {RESPONSE} from "../../../constants/ResponseCodes";
import {chainWalk, getDisplayContent, isNonNull} from "../../utils/Util";
import {getSortInfoForPackage, getValidateLocationBeforeStow, processStow, sblSourceScan, sblDestinationScan} from "../network/StowPackageNetwork";

export const getStowScanPackageResource = async (packageScannableId, scope) => {
    const response = await getSortInfoForPackage(packageScannableId, scope)
    const sortLocations = transformSortLocations(response.sortLocations)
    const targetSortLocation = chainWalk(() => sortLocations[0], null)
    const locationInfo = chainWalk(() => targetSortLocation.locationInfo, null)
    const floorInfo = chainWalk(() => targetSortLocation.floorInfo, null)
    const salContent =  transformSalContent(response.salContent)
    return {
        responseCode: RESPONSE[response.responseCode],
        packageId: isNonNull(response.packageTrackingId) ? response.packageTrackingId : packageScannableId,
        packageInfo: response.packageInfo,
        displayPackageId: getDisplayContent(response.packageTrackingId, true),
        packageTrackingId: response.packageTrackingId,
        isOVPackage: response.isOVPackage,
        packageHolderStation: response.packageHolderStation,
        sortLocations: sortLocations,
        targetSortLocation: targetSortLocation,
        rackSize: getRackConfiguration(locationInfo),
        stowCoordinates: getStowCoordinates(locationInfo),
        aisle: chainWalk(() => locationInfo.aisle, null),
        rack: chainWalk(() => locationInfo.rack, null),
        packageLocationLabel: chainWalk(() => locationInfo.locationLabel, null),
        packageLocationType: chainWalk(() => locationInfo.locationType, null),
        scope: scope,
        floorLabel: isNonNull(floorInfo) ? floorInfo.label : null,
        salContent: salContent,
        getSortInfoForPackageOutput: response
    }
}

export const getStowSblSourceScanResource = async (sblEndpoint, packageScannableId) => {
    return sblSourceScan(sblEndpoint, packageScannableId);
};

export const getStowSblDestinationScanResource = async (sblEndpoint, packageScannableId, destination) => {
    return sblDestinationScan(sblEndpoint, packageScannableId, destination);
};

export const getStowScanLocationResource = async (packageDestinationInfo) => {
    const response = await processStow(packageDestinationInfo)
    return {
        responseCode: RESPONSE[response.responseCode],
        bagLabel: response.bagLabel,
        destinationLabel: response.destinationLabel,
        packageLabel: response.packageLabel
    }
}

export const getStowScanPackageFlowResource = async (packageDestinationInfo) => {
    const response = await processStow(packageDestinationInfo)
    return {
        responseCode: RESPONSE[response.responseCode],
        packageLabel: response.packageLabel,
        packageId: packageDestinationInfo.packageTrackingId,
        displayPackageId: getDisplayContent(packageDestinationInfo.packageTrackingId, true)
    }
}


export const getValidateLocationBeforeStowResource = async (scannableId, scope) => {
    const response = await getValidateLocationBeforeStow(scannableId)
    const targetSortLocation = transformSortLocation(response.targetSortLocation)
    return {
        responseCode: RESPONSE[response.responseCode],
        targetSortLocation: targetSortLocation,
        destinationLabel: getDestinationLabel(response.targetSortLocation),
        scope: scope,
        locationScannable: scannableId
    }
}

export const getDestinationLabel = (targetSortLocation) => {

    if(isNonNull(targetSortLocation)) {
        if (isNonNull(targetSortLocation.bagInfo) && isNonNull(targetSortLocation.bagInfo.bagLabel)) {
            return targetSortLocation.bagInfo.bagLabel
        }

        if (isNonNull(targetSortLocation.cartInfo) && isNonNull(targetSortLocation.cartInfo.scannableId)) {
            return targetSortLocation.cartInfo.scannableId
        }

        if (isNonNull(targetSortLocation.locationInfo) && isNonNull(targetSortLocation.locationInfo.scannableId)) {
            return targetSortLocation.locationInfo.scannableId
        }
    }

    return null
}

const transformSortLocation = (sortLocation) => {
    return sortLocation ? {
            bagInfo: transformBagInfo(sortLocation.bagInfo),
            cartInfo: transformCartInfo(sortLocation.cartInfo),
            chuteInfo: transformChuteInfo(sortLocation.chuteInfo),
            floorInfo: transformFloorInfo(sortLocation.floorInfo),
            locationInfo: transformLocationInfo(sortLocation.locationInfo)
        }
     : null
}

const transformSortLocations = (sortLocations) => {
    return sortLocations ? sortLocations.map(sortLocation => ({
        bagInfo: transformBagInfo(sortLocation.bagInfo),
        cartInfo: transformCartInfo(sortLocation.cartInfo),
        chuteInfo: transformChuteInfo(sortLocation.chuteInfo),
        floorInfo: transformFloorInfo(sortLocation.floorInfo),
        locationInfo: transformLocationInfo(sortLocation.locationInfo)
    })) : null
}

const transformBagInfo = (bagInfo) => {
    return isNonNull(bagInfo) ? {
        label: bagInfo.bagLabel,
        color: bagInfo.color
    } : null
}


const transformCartInfo = (cartInfo) => {
    return cartInfo ? {
        label: cartInfo.label,
        scannableId: cartInfo.scannableId
    } : null
}

const transformChuteInfo = (chuteInfo) => {
    return chuteInfo ? {
        id: chuteInfo.chuteId,
        label: chuteInfo.chuteLabel,
        printableLabel: chuteInfo.chutePrintableLabel
    } : null
}

const transformFloorInfo = (floorInfo) => {
    return floorInfo ? {
        id: floorInfo.floorId,
        label: floorInfo.floorLabel
    } : null
}

const transformLocationInfo = (locationInfo) => {
    return locationInfo ? {
        scannableId: locationInfo.scannableId,
        locationType: locationInfo.locationType,
        aisle: locationInfo.aisle,
        rack: locationInfo.rack,
        locationLabel: locationInfo.locationLabel,
        rackSize: transformCoordinates(locationInfo.rackSize),
        stowCoordinates: transformCoordinates(locationInfo.stowCoordinates)
    } : null
}

const transformCoordinates = (coordinates) => {
    return coordinates ? {
        horizontalLimit: coordinates.horizontalLimit,
        verticalLimit: coordinates.verticalLimit
    } : null
}

const getRackConfiguration = (locationInfo) => {
    const horizontalLimit = chainWalk(() => locationInfo.rackSize.horizontalLimit, null)
    const verticalLimit = chainWalk(() => locationInfo.rackSize.verticalLimit, null)
    return getRackSize(horizontalLimit, verticalLimit)
}

const getStowCoordinates = (locationInfo) => {
    const horizontalLimit = chainWalk(() => locationInfo.stowCoordinates.horizontalLimit, null)
    const verticalLimit = chainWalk(() => locationInfo.stowCoordinates.verticalLimit, null)
    return getRackSize(horizontalLimit, verticalLimit)
}

const getRackSize = (horizontalLimit, verticalLimit) => {
    if (horizontalLimit != null && verticalLimit != null) {
        return {
            horizontalLimit: horizontalLimit,
            verticalLimit: verticalLimit,
        }
    } else {
        return {
            horizontalLimit: -1,
            verticalLimit: -1,
        }
    }
}

const transformSalContent = (salContent) => {
    if (isNonNull(salContent)) {
        return {
            binLabel: salContent.binLabel,
            chuteLabel: salContent.chuteLabel,
            conveyance: transformConveyance(salContent.conveyance),
            deliveryAssistLabel: salContent.deliveryAssistLabel,
            packageTrackingId: salContent.packageTrackingId,
            routeCode: salContent.routeCode
        }
    }
    return null
}

const transformConveyance = (conveyance) => {

    if (isNonNull(conveyance)) {
        return {
            printableLabel: conveyance.printableLabel
        }
    }
    return null
}