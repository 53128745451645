import {CONTAINER_TYPE} from "../../../../../constants/ContainerType";
import {REPLACE_CONTAINER_VIEW_STRINGS} from "../../../../../constants/Strings";

export const getHeader = (containerScannableType) => {

    switch (containerScannableType) {
        case CONTAINER_TYPE.CART:
            return getHeaderData(REPLACE_CONTAINER_VIEW_STRINGS.replace_cart, REPLACE_CONTAINER_VIEW_STRINGS.move_cart_to_PS)
        case CONTAINER_TYPE.BAG:
            return getHeaderData(REPLACE_CONTAINER_VIEW_STRINGS.replace_bag, REPLACE_CONTAINER_VIEW_STRINGS.move_bag_to_PS)
        default:
            return
    }
}

const getHeaderData = (primaryTitle, secondaryTitle) => {

    return {
        primaryTitle: primaryTitle,
        secondaryTitle: secondaryTitle,
    }
}

export const getButtonText = (containerScannableType) => {

    switch (containerScannableType) {
        case CONTAINER_TYPE.CART:
            return REPLACE_CONTAINER_VIEW_STRINGS.confirm_cart_replaced
        case CONTAINER_TYPE.BAG:
            return REPLACE_CONTAINER_VIEW_STRINGS.confirm_bag_moved
        default:
            return
    }
}
