import React, {Component} from 'react';
import ScrubContainerView from "./view/ScrubContainerView";
import ScrubContainerContextProvider from "../context/ScrubContainerContext";

class ScrubContainer extends Component {

    render() {

        return (
            <div>
                <ScrubContainerContextProvider>
                    <ScrubContainerView history={this.props.history} />
                </ScrubContainerContextProvider>
            </div>
        )
    }
}

export default ScrubContainer
