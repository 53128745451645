import {dev_debug, NO_INTERNET_RESPONSE, UNKNOWN_ERROR_OCCURRED_RESPONSE} from "../../../utils/Util";
import {API_PATH, axiosGET, axiosPOST} from "../../../utils/network/NetworkUtils";
import * as getBagSortInfoForCloseTestData
    from "../../../../tst/components/manage-stow-area/close-container/view/scan-bag/test-data/CloseScanBagTestData";
import * as closeBagTestData
    from "../../../../tst/components/manage-stow-area/close-container/view/scan-bag-location/test-data/CloseScanBagLocationTestData";
import {AnalyticEventKeys, Constants, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {METRIC_KEYS} from "../../../workflow/Workflow";

export const getBagSortInfoForClose = async (bagScannableId, scope) => {
    const startTime = Date.now();
    const params = {
        params: {
            scannableId: bagScannableId,
            scope: scope
        }
    };
    try {
        const response = dev_debug === true
            ? getBagSortInfoForCloseTestData.TEST_RESPONSE_200()
            : await axiosGET(AnalyticEventKeys.Modules.GET_BAG_SORT_INFO_FOR_CLOSE, API_PATH.GET_BAG_SORT_INFO_CLOSE, params);

        if (response.status === 200) {
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_BAG_SORT_INFO_FOR_CLOSE, startTime);
            return response.data
        }
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_BAG_SORT_INFO_FOR_CLOSE, startTime, true);
    } catch (error) {
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_BAG_SORT_INFO_FOR_CLOSE, startTime, true);
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE
        }
        return UNKNOWN_ERROR_OCCURRED_RESPONSE
    }
}

export const closeBag = async (bagScannableId, destinationScannableId, scope) => {
    const startTime = Date.now();
    const request = {
        bagScannableId: bagScannableId,
        destinationScannableId: destinationScannableId,
        scope: scope
    }
    try {
        const response = dev_debug === true
            ? closeBagTestData.TEST_RESPONSE_200()
            : await axiosPOST(AnalyticEventKeys.Modules.CLOSE_BAG, API_PATH.CLOSE_BAG, request);

        if (response.status === 200) {
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.CLOSE_BAG, startTime);
            return response.data
        }
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.CLOSE_BAG, startTime, true);
    } catch(error) {
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.CLOSE_BAG, startTime, true);
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE
        }
    }
    MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.CLOSE_BAG, startTime, true);
}

export const closeContainer = async (request) => {
    const startTime = Date.now();
    try {
        const response = await axiosPOST(AnalyticEventKeys.Modules.CLOSE_CONTAINER, API_PATH.CLOSE_CONTAINER, request);
        if (response.status === 200) {
            const locationLabel = (response.data && response.data.locationLabel) ? response.data.locationLabel : ""
            MobileAnalyticsHelper.processAnalytics(startTime, AnalyticEventKeys.Modules.PWA, response,
                AnalyticEventKeys.Modules.CLOSE_CONTAINER_OUTPUT, request.containerScannable,
                locationLabel, AnalyticEventKeys.Modules.CLOSE_CONTAINER_OUTPUT);
            MobileAnalyticsHelper.processAnalytics(startTime, response.responseCode, response, AnalyticEventKeys.Modules.STOW,
                request.containerScannable, locationLabel, AnalyticEventKeys.Events.USER_SCANNED_PACKAGE, false,
                METRIC_KEYS.CLOSE_CONTAINER_SUCCESSFUL)
            return response.data
        }
        MobileAnalyticsHelper.processAnalytics(startTime, AnalyticEventKeys.Modules.PWA, response,
            AnalyticEventKeys.Modules.CLOSE_CONTAINER_OUTPUT, request.containerScannable,
            "", AnalyticEventKeys.Modules.CLOSE_CONTAINER_OUTPUT, true);
        MobileAnalyticsHelper.processAnalytics(startTime, response.responseCode, response, AnalyticEventKeys.Modules.STOW,
            request.containerScannable, "", AnalyticEventKeys.Events.USER_SCANNED_PACKAGE, true,
            METRIC_KEYS.CLOSE_CONTAINER_FAILURE)
    } catch (error) {
        MobileAnalyticsHelper.processAnalytics(startTime, AnalyticEventKeys.Modules.PWA, "",
            AnalyticEventKeys.Modules.CLOSE_CONTAINER_OUTPUT, request.containerScannable,
            "", AnalyticEventKeys.Modules.CLOSE_CONTAINER_OUTPUT, true);
        MobileAnalyticsHelper.processAnalytics(startTime, "", "", AnalyticEventKeys.Modules.STOW,
            request.containerScannable, "", AnalyticEventKeys.Events.USER_SCANNED_PACKAGE, true,
            METRIC_KEYS.CLOSE_CONTAINER_FAILURE)
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE
        }
       return  UNKNOWN_ERROR_OCCURRED_RESPONSE
    }
}
