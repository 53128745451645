import React, {useContext, useEffect} from 'react';
import {ProblemSolveContext} from "../context/ProblemSolveContext";
import {PROBLEM_SOLVE_VIEW_STRINGS} from "../../../constants/Strings";
import {ConfigContext} from "../../config/context/ConfigContext";
import {moveToProblemSolve} from "../action/ProblemSolveAction";
import {isNonNull} from "../../utils/Util";
import {NotificationContext} from "../../notification/context/NotificationContext";
import ScanView from "../../scan-view/ScanView";
import Column from "@amzn/meridian/column";
import ScanQrCodeView from "../../scan-qr-code-view/ScanQrCodeView";
import {injectIntl} from "react-intl";
import {PlayerContext} from "../../player/context/PlayerContext";
import {ApplicationActions} from "../../player/action/ApplicationActions";

const ProblemSolveView = (props) => {

    const {configViewModel} = useContext(ConfigContext)
    const {problemSolveViewModel, problemSolveDispatch} = useContext(ProblemSolveContext);
    const {notificationActions: {setNotification}} = useContext(NotificationContext);
    const {dispatchPlayer} = useContext(PlayerContext);

    const onScan = (input) => {
        const request = {
            packageId: configViewModel.packageId,
            destinationScannableId: input,
            problemSolveReasonCode: props.problemSolveReasonCode,
            scope: configViewModel.scope,
            displayPackageId: configViewModel.displayPackageId
        }
        moveToProblemSolve(problemSolveDispatch, request, configViewModel.workflow)
    }

    useEffect(() => {
        if (isNonNull(problemSolveViewModel.notification)) {
            setNotification(problemSolveViewModel.notification)
        }
        if (isNonNull(problemSolveViewModel.nextRoute)) {
            props.history.push(problemSolveViewModel.nextRoute)
        }
        if(isNonNull(problemSolveViewModel.responseCode)) {
            dispatchPlayer({type: ApplicationActions.PROBLEM_SOLVE_SCAN, data: {errorResponse: problemSolveViewModel.responseCode}});
        }
    }, [problemSolveViewModel]);

    const init = {
        primaryTitle: PROBLEM_SOLVE_VIEW_STRINGS.problem_solve,
        secondaryTitle: PROBLEM_SOLVE_VIEW_STRINGS.scan_bin
    }

    return (
        <Column spacingInset="medium" alignmentHorizontal="center">
            <ScanQrCodeView {...init}/>
            <ScanView onScan={onScan}/>
        </Column>
    )
}

export default injectIntl(ProblemSolveView)
