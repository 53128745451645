import {getRoutesStowProgressForSortLocationResource} from "../resource/StowProgressResource";

export const GET_ROUTES_FOR_STOW_PROGRESS = "GET_ROUTES_FOR_STOW_PROGRESS"

export const getRoutesStowProgressForSortLocation = (dispatcher, scannableId) => {

    getRoutesStowProgressForSortLocationResource(scannableId).then(response => {
        dispatcher({
            type: GET_ROUTES_FOR_STOW_PROGRESS,
            data: response
        })
    }).catch(() => {
    })
}
