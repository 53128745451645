import {ROUTING} from "../../constants/Routing";
import {SCOPE} from "../../constants/Scope";
import {WORKFLOW} from "../workflow/Workflow";
import {RESPONSE} from "../../constants/ResponseCodes";
import {interceptBackButton} from "../bridge/BackButton";
import {NO_OP} from "../../constants/Constants";

export const EMPTY = ''
export const SLASH = '/'
export const EMPTY_ARRAY = []
const VIBRATION_TIME = 200;

export const MODULE_NAME = 'DirectedStow'

//training status
export const TRAINING_STATUS = {
    EXPIRED: 'EXPIRED'
}

export const chainWalk = (func, defaultValue) => {
    try {
        const value = func()
        return (value === null || value === undefined) ? defaultValue : value;
    } catch (e) {
        return defaultValue
    }
}

export const NO_INTERNET_RESPONSE = {
    responseCode: RESPONSE.NO_INTERNET
}

export const UNKNOWN_ERROR_OCCURRED_RESPONSE = {
    responseCode: RESPONSE.UNKNOWN_ERROR_OCCURRED
}

export const isNonNull = (obj) => {
    return obj !== null && obj !== undefined
}

export const isNonEmpty = (obj) => {
    return isNonNull(obj) && obj !== EMPTY
}

export const dev_debug = false

export const convertToString = (obj) => {
    return isNonNull(obj) ? obj.toString() : EMPTY
}

export const getDisplayContent = (itemId, isPackage) => {
    if (itemId === null || itemId === undefined) {
        return null;
    }
    const itemLength = itemId.length
    if (isPackage) {
        if (itemLength >= 5) {
            return itemId.substring(itemLength - 5,
                itemLength);
        }
        return itemId;
    } else {
        if (itemLength >= 3) {
            return itemId.substring(itemLength - 3,
                itemLength);
        }
        return itemId;
    }
}

export const getSelectContainerRoutingPath = (operation) => {
    return ROUTING.SELECT_CONTAINER + SLASH + operation
}

export const getHelpOptions = (callbacks, pageTitle, pageText) => {
    return {
        pageTitle: pageTitle,
        list: [{
            title: pageText,
            onClick: () => {
                callbacks.forEach(callback => callback())
            }
        }]
    }
};

export const getNotificationFormat = (type, message, values) => {
    return {
        type: type,
        message: message,
        values: values
    }
}

export const isArScope = (scope) => {
    return SCOPE.AR === scope || SCOPE.AR_NEXT_GEN === scope
}

export const isWorkflowBagHeavyOrBagFull = (workflow) => {
    return WORKFLOW.BAG_WEIGHT_LIMIT_AT_PACKAGE_SCAN === workflow || WORKFLOW.BAG_FULL === workflow
}

export const isAttachCartWorkflow = (workflow) => {
    return WORKFLOW.ATTACH_CART === workflow;
}

export const isPrepCartWorkflow = (workflow) => {
    return WORKFLOW.PREP_CART === workflow;
}

export const getFormattedString = (props, text) => {
    if(isNonEmpty(text)) {
        return props.intl.formatMessage({id: text})
    }
    return EMPTY
}

export const blockBackPress = (initContext, closeBackEnabled) => {
    const onBackPressed = ()=>{
        window.navigator.vibrate(VIBRATION_TIME);
    };
    initContext({onBackPressed});
    interceptBackButton(NO_OP);
    closeBackEnabled();
};

export const resetBlockedBackPress = (openBackEnabled, initContext, openBackDrop) => {
    openBackEnabled();
    const onBackPressed = openBackDrop;
    initContext({onBackPressed})
};

export const doesScannedCartMatchesWithExpectedCart = (scannedCart, expectedCart) => {
    if (scannedCart && expectedCart && (scannedCart === expectedCart)) {
        return true;
    }
    return false;
};