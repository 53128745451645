import React, {Component} from 'react';
import ScrubLocationView from "./view/ScrubLocationView";
import ScrubContainerContextProvider from "../context/ScrubContainerContext";

class ScrubLocation extends Component {

    render() {

        return (
            <div>
                <ScrubContainerContextProvider>
                    <ScrubLocationView history={this.props.history}/>
                </ScrubContainerContextProvider>
            </div>
        )
    }
}

export default ScrubLocation
