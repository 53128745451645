import {RESPONSE} from "../../../../../../constants/ResponseCodes";
import {NOTIFICATION_TYPE} from "../../../../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../../../../constants/Strings";
import {VIEW} from "../../../../../workflow/Workflow";
import {getNotificationFormat} from "../../../../../utils/Util";
import {ROUTING} from "../../../../../../constants/Routing";

export const getCloseScanBagViewData = (notificationInput) => {

    switch (notificationInput.responseCode) {
        case RESPONSE.SUCCESS:
            return {
                view: VIEW.CLOSE_SCAN_BAG_LOCATION
            }
        case RESPONSE.NO_INTERNET:
            return {
                nextRoute: ROUTING.NO_INTERNET
            }
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_INVALID_SCAN)
            }
        case RESPONSE.BAG_ALREADY_CLOSED:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.WARNING,
                    NOTIFICATION_STRING.ALERT_WARNING_BAG_ALREADY_CLOSED,
                    {bagLabel: notificationInput.bagLabel})
            }
        case RESPONSE.NO_PLAN_PRESENT:
            //There is no valid destination for the bag, comes when bag is empty.
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_BAG_EMPTY,
                    {bagLabel: notificationInput.bagLabel})
            }
        default:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.default_error)
            }
    }
}
