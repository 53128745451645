import React from 'react';
import {HeaderView} from "@amzn/dolphin-uiweb-framework";
import {COMMON_STRINGS, SCAN_BAG_VIEW_STRINGS} from "../../../../../../constants/Strings";
import {FormattedMessage} from "react-intl";
import Column from "@amzn/meridian/column";
import LocationView from "./LocationView";

const ScanWithLocationView = (props) => {

    const VIEW_TO_TITLE_MAP = {
        SCAN_BAG: "Place a new bag in location",
        SCAN_BAG_LOCATION: "Confirm bag is in right location",
    }

    const VIEW_TO_SUBTITLE_MAP = {
        SCAN_BAG: <FormattedMessage id={SCAN_BAG_VIEW_STRINGS.scan_empty_bag}/>,
        SCAN_BAG_LOCATION: <FormattedMessage id={COMMON_STRINGS.scan_location}/>,
    }

    const primaryTitle = {
        title: <FormattedMessage id={VIEW_TO_TITLE_MAP[props.view]}/>,
    }

    const secondaryTitle = {
        title: <FormattedMessage id={VIEW_TO_SUBTITLE_MAP[props.view]}/>,
        type: "Bold"
    }

    return <div>
        <Column spacingInset="medium" alignmentHorizontal="center">
            <HeaderView primaryTitle={primaryTitle}
                        secondaryTitle={secondaryTitle}/>
        </Column>
        <LocationView location={props.location}/>
    </div>
}

export default ScanWithLocationView
