import React, {Component} from 'react';
import CartHotSwapContextProvider from "./context/CartHotSwapContext";
import CartHotSwapView from "./view/CartHotSwapView";

class CartHotSwap extends Component {

    render() {
        return (
            <div>
                <CartHotSwapContextProvider>
                    <CartHotSwapView history={this.props.history} state={this.props.location.state}/>
                </CartHotSwapContextProvider>
            </div>
        )
    }
}

export default CartHotSwap
