import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {HashRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import "@amzn/meridian-tokens/base/font/amazon-ember.css";
import {addLocaleData, IntlProvider} from "react-intl";
import de from 'react-intl/locale-data/de'
import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'
import fr from 'react-intl/locale-data/fr'
import hi from 'react-intl/locale-data/hi'
import it from 'react-intl/locale-data/it'
import ja from 'react-intl/locale-data/ja'
import pt from 'react-intl/locale-data/pt'
import ro from 'react-intl/locale-data/ro'
import ar from 'react-intl/locale-data/ar'
import nl from 'react-intl/locale-data/nl'
import tr from 'react-intl/locale-data/tr'
import App from "./components/app";
import {getDirection, getLocale, getMessages} from "./components/handler/LocaleHandler";
import ErrorBoundary from "./components/ErrorBoundary";
import {
    getAnalyticsRegion,
    getLoggerUrl,
    getPUMACountry,
    getPUMARegion,
    getPUMAStage,
    getStage
} from "./components/utils/network/BaseUrlUtils";
import {PUMA_APP_ID, PUMA_MODULE_NAME} from "./constants/PUMAConstants";
import {PUMAMetricHelper, DataHandler, Logger, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {SoundType} from "./components/sound/SoundManager";

addLocaleData(de);
addLocaleData(en);
addLocaleData(es);
addLocaleData(fr);
addLocaleData(hi);
addLocaleData(it);
addLocaleData(ja);
addLocaleData(pt);
addLocaleData(ro);
addLocaleData(ar);
addLocaleData(nl);
addLocaleData(tr);

function playSilentSound() {
    // do things, then
    SoundType.SILENT.play().catch(error => {
        Logger.log.warn("Unable to play sound : " + error);
    });
    document.removeEventListener('click', playSilentSound);
}

/**
 * Playing a silent sound on first click to capture the user gesture.
 */
document.addEventListener('click', playSilentSound);
DataHandler.parseSearchParams();
Logger.setLogger(getLoggerUrl(), getStage());
PUMAMetricHelper.pumaInit(getPUMACountry(), getPUMARegion(), getPUMAStage(), PUMA_APP_ID, PUMA_MODULE_NAME);
MobileAnalyticsHelper.initializeAnalytics(getAnalyticsRegion());
document.getElementsByTagName(`html`)[0].setAttribute("dir", getDirection());

ReactDOM.render((
    <IntlProvider locale={getLocale()} messages={getMessages()}>
        <HashRouter>
            <ErrorBoundary>
                <App/>
            </ErrorBoundary>
        </HashRouter>
    </IntlProvider>
), document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: (serviceWorkerRegistration) => {
        const registrationWaiting = serviceWorkerRegistration.waiting;
        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
            registrationWaiting.addEventListener('statechange', e => {
                if (e.target.state === 'activated') {
                    window.location = "/";
                }
            });
        }
    }
});
