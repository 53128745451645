export const TEST_CONTAINER_SCANNABLE_ID = "DU-M-1230-950-YLO"

export const TEST_VALIDATE_RESPONSE_200 = () => {
    return {
        status: 200,
        data: TEST_VALIDATE_SUCCESS_RESPONSE
    }
}

const TEST_VALIDATE_SUCCESS_RESPONSE = {
    "responseCode": "SUCCESS",
    "containerLabel": "test123",
    "containerType": "STACKING_AREA"
}

export const TEST_SCRUB_CONTAINER_RESPONSE_200 = () => {
    return {
        status: 200,
        data: {
            "responseCode": "SUCCESS",
        }
    }
}

export const TEST_RESPONSE_INVALID_SCAN = () => {
    return {
        status: 200,
        data: {
            responseCode: "INVALID_SCAN_DONE",
        }
    }
}

export const TEST_RESPONSE_UNKNOWN_ERROR = () => {
    return {
        status: 200,
        data: {
            responseCode: "UNKNOWN_ERROR_OCCURRED",
        }
    }
}
