import React, {useContext} from 'react';
import {DetailsCardView, HeaderView} from "@amzn/dolphin-uiweb-framework";
import QrCode from "../../../../../images/ic_qr_code.png";
import {AR_VIEW_STRINGS, COMMON_STRINGS} from "../../../../../constants/Strings";
import {FormattedMessage} from "react-intl";
import Column from "@amzn/meridian/column";
import {CONTAINER_TYPE} from "../../../../../constants/ContainerType";
import {ConfigContext} from "../../../../config/context/ConfigContext";
import ScanView from "../../../../scan-view/ScanView";
import {VIEW} from "../../../../workflow/Workflow";
import {
    attachContainerToDestination,
    invalidLocationScanned
} from "../../../prepare-container/action/PrepareContainerAction";
import {PrepareContainerContext} from "../../../prepare-container/context/PrepareContainerContext";
import {COMMON_ACTIONS} from "../../../../../constants/ActionType";

const BagScanLocationView = () => {

    const {prepareContainerModel, prepareContainerDispatch} = useContext(PrepareContainerContext)
    const {configViewModel} = useContext(ConfigContext)

    const onScan = (input) => {
        if (input === prepareContainerModel.activeBin.binLabel) {
            const request = {
                workflow: configViewModel.workflow,
                containerScannableId: prepareContainerModel.activeBagId,
                destinationScannableId: input,
                containerScannableType: CONTAINER_TYPE.BAG,
                scope: configViewModel.scope
            }
            prepareContainerDispatch({type: COMMON_ACTIONS.LOADING})
            attachContainerToDestination(prepareContainerDispatch, request)
        } else {
            const request = {
                view: VIEW.BAG_SCAN_VIEW,
                scannedInput: input
            }
            invalidLocationScanned(prepareContainerDispatch, request)
        }
    }

    const init = {
        primaryTitle: {
            title: <FormattedMessage id={AR_VIEW_STRINGS.confirm_bag_location}/>,
        },
        secondaryTitle: {
            title: <FormattedMessage id={AR_VIEW_STRINGS.scan_location}/>,
            type: "Bold"
        },
        image: QrCode,
        onScan: onScan
    }

    const locationView = () => {
        return [{
            description: <FormattedMessage id={COMMON_STRINGS.shelf}/>,
            title: prepareContainerModel.activeBin.binShelf
        }, {
            description: <FormattedMessage id={COMMON_STRINGS.cart}/>,
            title: prepareContainerModel.activeCartId
        }]
    }

    const renderLocationView = () => {
        return <DetailsCardView snapshots={locationView()} headerFontSize="Large" display={"Column"}/>
    }

    return (
        <div>
            <Column spacingInset="medium" alignmentHorizontal="center">
                <HeaderView primaryTitle={init.primaryTitle}
                            secondaryTitle={init.secondaryTitle}/>
            </Column>
            <Column spacingInset="medium">
                {renderLocationView()}
                <ScanView onScan={onScan}/>
            </Column>
        </div>
    )
}

export default BagScanLocationView
