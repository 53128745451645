import React, {Component} from 'react';
import MoveToBufferView from "./view/MoveToBufferView";

class MoveToBuffer extends Component {

    render() {
        return (
            <div>
                <MoveToBufferView history={this.props.history}/>
            </div>
        )
    }
}

export default MoveToBuffer
