import {RESPONSE} from "../../../constants/ResponseCodes";
import {NOTIFICATION_TYPE} from "../../../constants/NotificationType";
import {SCRUB_CONTAINER_STRINGS} from "../../../constants/Strings";
import {getDisplayContent} from "../../utils/Util";

export const getNotificationForScrubContainer = (notificationInput) => {
    switch (notificationInput.responseCode) {
        case RESPONSE.SUCCESS:
            if(notificationInput.containerType === SCRUB_CONTAINER_STRINGS.STACKING_AREA) {
                return {
                    type: NOTIFICATION_TYPE.SUCCESS,
                    message: SCRUB_CONTAINER_STRINGS.scrub_location_successful,
                    values: {containerLabel: notificationInput.containerLabel}
                }
            } else {
                return {
                    type: NOTIFICATION_TYPE.SUCCESS,
                    message: SCRUB_CONTAINER_STRINGS.scrub_container_successful,
                    values: {containerLabel: getDisplayContent(notificationInput.containerLabel, false)}
                }
            }

        case RESPONSE.INVALID_SCAN_DONE:
        case RESPONSE.INVALID_MOVE:
            return {
                type: NOTIFICATION_TYPE.ERROR,
                message: notificationInput.containerType === SCRUB_CONTAINER_STRINGS.STACKING_AREA ?
                    SCRUB_CONTAINER_STRINGS.scrub_location_invalid_scan:
                    SCRUB_CONTAINER_STRINGS.scrub_container_invalid_scan,
                values: {containerLabel: notificationInput.containerLabel}
            }

        default:
            return {
                type: NOTIFICATION_TYPE.ERROR,
                message: SCRUB_CONTAINER_STRINGS.api_error
            }
    }
}

export const getNotificationForValidateContainerBeforeScrub = (notificationInput) => {
    switch (notificationInput.responseCode) {

        case RESPONSE.SUCCESS:
            return
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                type: NOTIFICATION_TYPE.ERROR,
                message: notificationInput.containerType === SCRUB_CONTAINER_STRINGS.STACKING_AREA?
                    SCRUB_CONTAINER_STRINGS.incorrect_location_scan:
                    SCRUB_CONTAINER_STRINGS.invalid_container_scan,
                values: {containerLabel: notificationInput.containerType === SCRUB_CONTAINER_STRINGS.STACKING_AREA?
                    "": notificationInput.containerLabel},
            }
        default:
            return {
                type: NOTIFICATION_TYPE.ERROR,
            message: SCRUB_CONTAINER_STRINGS.api_error
            }
    }
}
