import {convertToString, getFormattedString} from "../../../../utils/Util";
import {COMMON_STRINGS, STOW_PROGRESS_VIEW_STRINGS} from "../../../../../constants/Strings";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import React from "react";

export const constructViewData = (props, routeStowProgress) => {

    return [{
        description: getFormattedString(props, STOW_PROGRESS_VIEW_STRINGS.ROUTE_CODE),
        title: convertToString(routeStowProgress.routeCode)
    }, {
        description: getFormattedString(props, STOW_PROGRESS_VIEW_STRINGS.PLANNED),
        title: convertToString(routeStowProgress.plannedPackagesCount)
    },{
        description: getFormattedString(props, STOW_PROGRESS_VIEW_STRINGS.STOWED),
        title: convertToString(routeStowProgress.plannedStowedCount)
    }, {
        description: getFormattedString(props, STOW_PROGRESS_VIEW_STRINGS.STAGED),
        title: convertToString(routeStowProgress.isStaged
            ? getFormattedString(props, COMMON_STRINGS.YES)
            : getFormattedString(props, COMMON_STRINGS.NO))
    },{
        description: getFormattedString(props, STOW_PROGRESS_VIEW_STRINGS.NOT_STOWED),
        title: convertToString(routeStowProgress.plannedNotStowedCount)
    }, {
        description: getFormattedString(props, STOW_PROGRESS_VIEW_STRINGS.TOPUP),
        title: convertToString(routeStowProgress.unplannedStowedCount)
    }]

}

export const modalData = (routeStowProgress) => {
    return {
        title: routeStowProgress.routeCode,
        text: <Column spacing="none">{routeStowProgress.plannedNotStowedPackages.map((data, index) =>
            <Text key={index} type="h200" alignment="center">{data}</Text>)}</Column>
    }
}