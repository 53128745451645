import React, {Component} from "react";
import PrepareStowLocationView from "./view/PrepareStowLocationView";
import PrepareContainerContextProvider from "../prepare-container/context/PrepareContainerContext";

class PrepareStowLocation extends Component {

    render() {

        return (
            <PrepareContainerContextProvider>
                <PrepareStowLocationView history={this.props.history}/>
            </PrepareContainerContextProvider>
        )
    }
}

export default PrepareStowLocation
