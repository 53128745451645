import React, {useContext} from 'react';
import {DetailsCardView, HeaderView} from "@amzn/dolphin-uiweb-framework";
import {injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import {ConfigContext} from "../../../../config/context/ConfigContext";
import ScanView from "../../../../scan-view/ScanView";
import {PrepareContainerContext} from "../../../prepare-container/context/PrepareContainerContext";
import {getCardData, getHeaderData} from "./data/ScanContainerViewData";
import {COMMON_ACTIONS} from "../../../../../constants/ActionType";
import {attachContainerToDestination} from "../../../prepare-container/action/PrepareContainerAction";

const ScanContainerView = (props) => {

    const {prepareContainerModel, prepareContainerDispatch} = useContext(PrepareContainerContext)
    const {configViewModel} = useContext(ConfigContext)

    const onScan = (input) => {
        const request = {
            workflow: configViewModel.workflow,
            containerScannableId: input,
            destinationScannableId: prepareContainerModel.activeSortLocationScannableId,
            containerScannableType: prepareContainerModel.activeRoute.targetContainerType,
            scope: configViewModel.scope,
            routeId: prepareContainerModel.activeRoute.routeId,
        }
        prepareContainerDispatch({type: COMMON_ACTIONS.LOADING})
        attachContainerToDestination(prepareContainerDispatch, request)
    }

    const headerData = getHeaderData(props, prepareContainerModel.activeRoute)

    const renderLocationView = () => {
        return <DetailsCardView snapshots={getCardData(props, prepareContainerModel.activeRoute)} headerFontSize="Large"
                                display={"Column"}/>
    }

    return (
        <div>
            <Column spacingInset="medium" alignmentHorizontal="center">
                <HeaderView primaryTitle={headerData.primaryTitle}
                            secondaryTitle={headerData.secondaryTitle}/>
            </Column>
            <Column spacingInset="medium">
                {renderLocationView()}
                <ScanView onScan={onScan}/>
            </Column>
        </div>
    )
}

export default injectIntl(ScanContainerView)
