import {HELP_CENTER_STRINGS} from "../../../constants/Strings";
import CartStageReadinessPoller from "../../../util/CartStageReadinessPoller";
import AppDataStore, {APP_DATA_KEYS} from "../../app-data";
import {ROUTING} from "../../../constants/Routing";

export const getHotSwapExitButton = (props, closeHelpOption) => {
    const check = {
        title: props.intl.formatMessage({id: HELP_CENTER_STRINGS.EXIT_HOT_SWAP}),
        onClick: () => {
            if ((props.state && props.state.cartId)) {
                CartStageReadinessPoller.removeCart(props.state.cartId);
            }
            if (AppDataStore.getCurrentCartInfo()) {
                CartStageReadinessPoller.removeCart(AppDataStore.getCurrentCartInfo());
                AppDataStore.removeCurrentCartInfo(APP_DATA_KEYS.CURRENT_CART_INFO);
            }
            closeHelpOption();
            props.history.push(ROUTING.STOW_PACKAGE)
        }
    }
    return check;
};

export const getHelpData = (props, closeHelpOption) => {
    const list = [];
    list.push(getHotSwapExitButton(props, closeHelpOption));
    return {
        pageTitle: HELP_CENTER_STRINGS.HOT_SWAP_HELP,
        list: list
    }
};