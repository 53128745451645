import {RESPONSE} from "../../../constants/ResponseCodes";
import {moveToProblemSolve} from "../network/ProblemSolveNetworkAPI";


export const getProblemSolveResource = async (request) => {
    const response = await moveToProblemSolve(request)
    return {
        responseCode: RESPONSE[response.responseCode],
        scannableLabel: response.scannableLabel,
        destinationLabel: response.destinationLabel,
        displayPackageId: request.displayPackageId
    }
}