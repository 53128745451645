import React, {createContext, useReducer} from "react";
import {PlayerReducer} from "../reducer/PlayerReducer";
import {HelpTypes, PlayerConstants} from "../PlayerConstant";
import {SCOPE} from "../../../constants/Scope";
import {PlayerHelper} from "@amzn/dolphin-web-framework";
import {getRegion, getStage} from "../../utils/network/BaseUrlUtils";

export const PlayerContext = createContext();

const PlayerContextProvider = (props) => {
    const initState = {
        isInitialized: false,
        isFirstPackage: true,
        isFirstCart: true,
        scope: undefined,
        helpType: undefined,
        backState: undefined,
        scanType: undefined,
        isValidPackageReScan: undefined
    };

    const [statePlayer, dispatchPlayer] = useReducer(PlayerReducer, initState);

    const initPlayerState = (scope) => {
        statePlayer.scope = scope
        statePlayer.helpType = (statePlayer.scope === SCOPE.AMZL) ? HelpTypes.CONTAINER_CLOSE : HelpTypes.DEFAULT
        if(!statePlayer.isInitialized) {
            if(scope === SCOPE.AMZL) {
                PlayerHelper.init(PlayerConstants.PROCESS_PATH_STOW, PlayerConstants.VERSION, getStage(), getRegion())
                    .then(() => {
                        PlayerHelper.sendMessage(new window.DolphinPlayer.Stow.ProcessSelectedEvent(), new window.DolphinPlayer.Stow.TaskSelectState());
                    });
            } else if(scope === SCOPE.SSD){
                PlayerHelper.init(PlayerConstants.PROCESS_PATH_STOW_SSD, PlayerConstants.VERSION, getStage(), getRegion())
                    .then(() => {
                        PlayerHelper.sendMessage(new window.DolphinPlayer.DrsStow.ProcessSelectedEvent(), new window.DolphinPlayer.DrsStow.TaskSelectState());
                    });
            }
            statePlayer.isInitialized = true;
        }
    }

    return (
        <PlayerContext.Provider value={{statePlayer, dispatchPlayer, initPlayerState}}>
            {props.children}
        </PlayerContext.Provider>
    )
}

export default PlayerContextProvider;
