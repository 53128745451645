import React, {Component} from 'react';
import ManageStowAreaView from "./view/ManageStowAreaView";

class ManageStowArea extends Component {

    render() {
        return (
            <div>
                <ManageStowAreaView history={this.props.history}/>
            </div>
        )
    }
}

export default ManageStowArea
