import React, {Component} from 'react'
import CloseContainerContextProvider from './context/CloseContainerContext'
import CloseContainerView from './view/CloseContainerView'

class CloseContainer extends Component {

    render() {
        return (
            <CloseContainerContextProvider>
                <CloseContainerView history={this.props.history} containerScannableType={this.props.match.params.containerScannableType}/>
            </CloseContainerContextProvider>
        )

    }
}

export default CloseContainer