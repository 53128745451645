import {HotSwapActions} from "../reducer/CartHotSwapReducer";

export const redirectToStage = (dispatcher, props, cartId, routeId) => {
    dispatcher(getRedirectData(props, cartId, routeId));
};

const getRedirectData = (props, cartId, routeId) => {
    return {
        type: HotSwapActions.REDIRECT_TO_STAGE,
        data: {
            props: props,
            cartId: cartId,
            routeId: routeId
        }
    }
};
