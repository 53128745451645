import {VIEW} from "../../workflow/Workflow";
import {isNonNull} from "../../utils/Util";
import {
    getNotificationForScrubContainer,
    getNotificationForValidateContainerBeforeScrub
} from "../data/NotificationData";
import {SCRUB_CONTAINER_STRINGS} from "../../../constants/Strings";
import {RESPONSE} from "../../../constants/ResponseCodes";

export const SCRUB_CONTAINER_RESPONSE = "SCRUB_CONTAINER_RESPONSE"
export const VALIDATE_CONTAINER_BEFORE_SCRUB_RESPONSE = "VALIDATE_CONTAINER_BEFORE_SCRUB_RESPONSE"

export const ScrubContainerReducer = (state, action) => {
    let view, notification, notificationInput
    switch (action.type) {
        case SCRUB_CONTAINER_RESPONSE:
            if (state.containerType === SCRUB_CONTAINER_STRINGS.STACKING_AREA) {
                view = VIEW.SCRUB_LOCATION
            } else {
                view = VIEW.SCRUB_CONTAINER
            }
            notificationInput = constructNotificationInput(state, action.data)
            notification = getNotification(notificationInput)
            return {
                ...state,
                ...action.data,
                view: view,
                notification: notification
            }

        case VALIDATE_CONTAINER_BEFORE_SCRUB_RESPONSE:
            if (state.containerType === SCRUB_CONTAINER_STRINGS.STACKING_AREA) {
                if (RESPONSE.SUCCESS === action.data.responseCode) {
                    view = VIEW.SCRUB_LOCATION_CONFIRM
                } else {
                    view = VIEW.SCRUB_LOCATION
                }
            } else {
                if (RESPONSE.SUCCESS === action.data.responseCode) {
                    view = VIEW.SCRUB_CONTAINER_CONFIRM
                } else {
                    view = VIEW.SCRUB_CONTAINER
                }
            }
            notificationInput = constructNotificationInputForValidate(state, action.data)
            notification = getNotificationForValidate(notificationInput)

            return {
                ...state,
                ...action.data,
                view: view,
                notification: notification
            }

        default:
            return state;
    }
}

const constructNotificationInput = (currentState, newState) => {
    return {
        responseCode: newState.responseCode,
        containerType: currentState.containerType,
        containerLabel: currentState.containerLabel
    }
}

const getNotification = (notificationInput) => {
    if (!isNonNull(notificationInput.responseCode)) {
        return
    }

    return getNotificationForScrubContainer(notificationInput);
}

const constructNotificationInputForValidate = (currentState, newState) => {
    if (newState.responseCode !== RESPONSE.SUCCESS) {
        return {
            responseCode: newState.responseCode,
            containerType: currentState.containerType,
            containerLabel: currentState.containerScannableId,
        }
    }
    return {
        responseCode: newState.responseCode,
        containerLabel: newState.containerLabel,
        containerType: newState.containerType
    }
}

const getNotificationForValidate = (notificationInput) => {
    if (!isNonNull(notificationInput.responseCode)) {
        return
    }
    return getNotificationForValidateContainerBeforeScrub(notificationInput);
}
