import {dev_debug} from "../../utils/Util";
import {API_PATH, axiosGET} from "../../utils/network/NetworkUtils";
import {TEST_RESPONSE_200} from "../../../tst/components/stow-progress/view/scan-location/test-data/ScanLocationForStowProgressTestData"
import {RESPONSE} from "../../../constants/ResponseCodes";
import {AnalyticEventKeys, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";

export const getRoutesStowProgressForSortLocation = async (scannableId) => {
    const startTime = Date.now();
    const params = {
        params: {
            scannableId: scannableId
        }
    };
    const response = dev_debug === true
        ? TEST_RESPONSE_200()
        : await axiosGET(AnalyticEventKeys.Modules.GET_STOW_PROGRESS_ROUTES_FOR_SORT_LOCATION, API_PATH.GET_STOW_PROGRESS_ROUTES_FOR_SORT_LOCATION, params);

    if (response.status === 200) {
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_ROUTES_STOW_PROGRESS_FOR_SORT_LOCATION, startTime);
        return response.data
    }
    MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_ROUTES_STOW_PROGRESS_FOR_SORT_LOCATION, startTime, true);
    return {
        responseCode: RESPONSE.UNKNOWN_ERROR_OCCURRED
    }
}
