import axios from "axios";
import {getSblApiKey, getSblServiceBaseURL} from "./network/BaseUrlUtils";
import {DataHandler} from "@amzn/dolphin-web-framework";
import {sblInterceptorBuilder} from "../../interceptors/SBLInterceptor";

const SBLAPIClient = axios;
const interceptor = sblInterceptorBuilder(axios);
SBLAPIClient.interceptors.request.use(interceptor.onBeforeRequest, interceptor.onRequestError);
SBLAPIClient.interceptors.response.use(interceptor.onResponseSuccess, interceptor.onResponseError);

const GET_SBL_ENDPOINT = "spider/v1/mastercontroller/local-endpoint";

export const sblInitialize = async () => {
    const response = await SBLAPIClient.get(getSblServiceBaseURL() + GET_SBL_ENDPOINT, {
        headers: {
            'x-api-key': getSblApiKey(),
            'Content-Type': 'application/json'
        },
        params: {
            siteCode : DataHandler.getStationCode()
        }
    });
    return response.data;
};