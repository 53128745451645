import React from 'react';
import {injectIntl} from "react-intl";
import {HeaderView} from "@amzn/dolphin-uiweb-framework";

export const HEADING_TYPE = {
    PRIMARY: "PRIMARY",
    SECONDARY: "SECONDARY"
}

const HeadingView = (props) => {

    const getTitles = () => {
        return HEADING_TYPE.PRIMARY === props.type ?
            {
                primaryTitle: getBoldTitle(props.primaryTitle),
                secondaryTitle: getNormalTitle(props.secondaryTitle)
            } :
            {
                primaryTitle: getNormalTitle(props.primaryTitle),
                secondaryTitle: getBoldTitle(props.secondaryTitle)
            }
    }

    const getBoldTitle = (title) => {
        return {
            title: props.intl.formatMessage({id: title}),
            type: "Bold",
            size: "Large"
        }
    }

    const getNormalTitle = (title) => {
        return {
            title: props.intl.formatMessage({id: title})
        }
    }

    return <HeaderView {...getTitles()}/>
}

export default injectIntl(HeadingView)
