import React, {useContext, useEffect} from 'react';
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import {HELP_CENTER_STRINGS, SCRUB_CONTAINER_STRINGS} from "../../../../../constants/Strings";
import {FormattedMessage} from "react-intl";
import {AppNavigationContext} from "../../../../app-navigation/context/AppNavigationContext";
import {getHelpOptions, getSelectContainerRoutingPath, SLASH} from "../../../../utils/Util";
import {STOW_AREA_OPERATION} from "../../../../../constants/StowAreaOperation";
import {PrepareContainerContext} from "../../context/PrepareContainerContext";
import {VIEW} from "../../../../workflow/Workflow";
import {ROUTING} from "../../../../../constants/Routing";
import {CONTAINER_TYPE} from "../../../../../constants/ContainerType";
import {NotificationContext} from "../../../../notification/context/NotificationContext";
import {PlayerContext} from "../../../../player/context/PlayerContext";
import {ApplicationActions} from "../../../../player/action/ApplicationActions";

const ScrubNonEmptyBagView = (props) => {

    const {navActions: {setHelpOption, closeHelpOption,  openBackEnabled}} = useContext(AppNavigationContext)
    const {prepareContainerModel} = useContext(PrepareContainerContext);
    const {notificationActions: {clearNotification}} = useContext(NotificationContext);
    const {dispatchPlayer} = useContext(PlayerContext);

    const handleClick = (scrubOperation) => {
        prepareContainerModel.view = scrubOperation.view;
        clearNotification();
        dispatchPlayer({type: ApplicationActions.CONTAINER_SCRUB_OPTION_SELECTION, data: {operation: scrubOperation.operation}});
        props.history.push(ROUTING.PREPARE_CONTAINER + SLASH + CONTAINER_TYPE.BAG)
    }

    const init = {
        tasks: [
            {
                title: <FormattedMessage id={SCRUB_CONTAINER_STRINGS.open_bag_error_scrub_bag}/>,
                description: <FormattedMessage id={SCRUB_CONTAINER_STRINGS.open_bag_error_scrub_bag_desc}/>,
                onClick: () => handleClick({view: VIEW.SCRUB_NON_EMPTY_BAG_CONFIRM, operation: SCRUB_CONTAINER_STRINGS.open_bag_error_scrub_bag})
            },
            {
                title: <FormattedMessage id={SCRUB_CONTAINER_STRINGS.open_bag_error_cancel_option}/>,
                onClick: () => handleClick({view: VIEW.SCAN_BAG, operation: SCRUB_CONTAINER_STRINGS.open_bag_error_cancel_option})
            }
        ]
    }

    useEffect(() => {
        openBackEnabled()
    }, []);

    useEffect(() => {
        const callbacks = [
            () => {
                dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP}});
                props.history.push(getSelectContainerRoutingPath(STOW_AREA_OPERATION.PREPARE_CONTAINER))
            },
            closeHelpOption
        ]
        setHelpOption(true, getHelpOptions(callbacks,
            <FormattedMessage id={HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP}/>,
            <FormattedMessage id={HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP_TEXT}/>));
        return () => setHelpOption(false, {});
    }, [])


    return (
        <div>
            <TaskView {...init} />
        </div>
    )
}

export default ScrubNonEmptyBagView
