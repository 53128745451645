import {chainWalk, EMPTY, getFormattedString} from "../../../../utils/Util";
import {COMMON_STRINGS, SCAN_LOCATION_VIEW_STRINGS} from "../../../../../constants/Strings";
import {CYCLE_TYPE} from "../../../../../constants/CycleType";
import {SCOPE} from "../../../../../constants/Scope";
import {CONTAINER_TYPE} from "../../../../../constants/ContainerType";

export const LOCATION_VIEW_TYPE = {
    CORE: "CORE",
    DRS: "DRS",
    STACKING_AREA: "STACKING_AREA",
    AR: "AR",
    SSD: "SSD"
}

export const getHeaderData = (locationViewType) => {
    return {
        primaryTitle: SCAN_LOCATION_VIEW_STRINGS.scan_location,
        secondaryTitle: LOCATION_VIEW_TYPE.STACKING_AREA === locationViewType ? "Place the package in container" :
            SCAN_LOCATION_VIEW_STRINGS.scan_location_guidance
    }
}

export const getLocationCardViewData = (locationViewType, stowPackageViewModel, props) => {
    const sortLocation = stowPackageViewModel.targetSortLocation
    switch (locationViewType) {
        case LOCATION_VIEW_TYPE.CORE:
            return [{
                description: getFormattedString(props, COMMON_STRINGS.aisle),
                title: stowPackageViewModel.aisle
            }, {
                description: getFormattedString(props, COMMON_STRINGS.shelf),
                title: stowPackageViewModel.rack
            }]
        case LOCATION_VIEW_TYPE.DRS:
            return [{
                description: getFormattedString(props, COMMON_STRINGS.route_code),
                title: stowPackageViewModel.aisle
            }]
        case LOCATION_VIEW_TYPE.STACKING_AREA:
            return [{
                description: COMMON_STRINGS.stacking_area,
                title: stowPackageViewModel.packageLocationLabel
            }]
        case LOCATION_VIEW_TYPE.AR:
            return [{
                description: getFormattedString(props, COMMON_STRINGS.location),
                title: sortLocation.floorInfo ? sortLocation.floorInfo.label : EMPTY
            }, {
                description: getFormattedString(props, COMMON_STRINGS.shelf),
                title: stowPackageViewModel.rack
            }, {
                description: getFormattedString(props, COMMON_STRINGS.cart),
                title: sortLocation.cartInfo ? sortLocation.cartInfo.label : EMPTY
            }
            ]
        case LOCATION_VIEW_TYPE.SSD:
            return [{
                description: getFormattedString(props, COMMON_STRINGS.cart),
                title: sortLocation.cartInfo ? sortLocation.cartInfo.label : EMPTY
            }, {
                description: getFormattedString(props, COMMON_STRINGS.location),
                title: sortLocation.floorInfo ? sortLocation.floorInfo.label : EMPTY
            }
            ]
        default:
            return
    }
}

export const getLocationViewType = (stowPackageViewModel, scope) => {
    switch (scope) {
        case SCOPE.AR:
        case SCOPE.AR_NEXT_GEN:
            return LOCATION_VIEW_TYPE.AR
        case SCOPE.SSD:
            return LOCATION_VIEW_TYPE.SSD
        default:
            return getLocationViewTypeByProperty(stowPackageViewModel)
    }
}

const getLocationViewTypeByProperty = (stowPackageViewModel) => {

    const locationType = chainWalk(() => stowPackageViewModel.targetSortLocation.locationInfo.locationType, null)
    if (LOCATION_VIEW_TYPE.STACKING_AREA === locationType) {
        return LOCATION_VIEW_TYPE.STACKING_AREA
    } else if (CYCLE_TYPE.DRS === chainWalk(() => stowPackageViewModel.packageInfo.packageAttributes.cycleType, null)) {
        return LOCATION_VIEW_TYPE.DRS
    } else if (CONTAINER_TYPE.ROUTE === locationType){
        return LOCATION_VIEW_TYPE.DRS
    } else {
        return LOCATION_VIEW_TYPE.CORE
    }
}
