import React from "react"
import {Loader} from "@amzn/dolphin-uiweb-framework";

const Loading = () => {
    return (
        <Loader/>
    )
}

export default Loading
