import {SCOPE} from "../../../../../constants/Scope";
import {EMPTY, isNonNull} from "../../../../utils/Util";

export const getRescanCardViewData = (scope, stowPackageModel) => {

    let defaultWrongLocationScannedTitle = isNonNull(stowPackageModel.destinationLabel) ? stowPackageModel.destinationLabel : EMPTY
    let defaultCorrectLocationScannedTitle = stowPackageModel.packageLocationLabel
    switch (scope) {
        case SCOPE.AR:
        case SCOPE.AR_NEXT_GEN:
            return {
                wrongLocationScannedTitle: defaultWrongLocationScannedTitle,
                correctLocationScannedTitle: isNonNull(stowPackageModel.floorLabel) ? defaultCorrectLocationScannedTitle : EMPTY
            }
        case SCOPE.SSD:
            return {
                wrongLocationScannedTitle: defaultWrongLocationScannedTitle,
                correctLocationScannedTitle: isNonNull(stowPackageModel.targetSortLocation.cartInfo)
                && isNonNull(stowPackageModel.targetSortLocation.cartInfo.label)
                    ? stowPackageModel.targetSortLocation.cartInfo.label : EMPTY
            }
        default:
            return {
                wrongLocationScannedTitle: stowPackageModel.incorrectDestinationScanned,
                correctLocationScannedTitle: defaultCorrectLocationScannedTitle
            }

    }
}
