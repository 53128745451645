import React, {Component} from "react";
import StowProgressView from "./view/StowProgressView";
import StowProgressContextProvider from "./context/StowProgressContext";

class StowProgress extends Component {

    render() {
        return (
            <StowProgressContextProvider>
                <StowProgressView history={this.props.history}/>
            </StowProgressContextProvider>
        )
    }
}

export default StowProgress
