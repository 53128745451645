
export const PRINTER = {
    PRINTER_TYPE_AVERY: "AVERY",
    AVERY_SAL_RESOURCE_LABEL_TAG: "StowSALTemplate",
    PRINTER_BAR_CODE_LENGTH: 8,
    UNSUPPORTED_BARCODE: "UnsupportedBarcode",
    PRINTER_CANCEL: "PrinterCancel",
    PRINTER_CONNECTION_SUCCESS: "PrinterConnectionSuccess",
    PRINTER_CONNECTION_ERROR: "PrinterConnectionError",
    PRINTER_CONNECTION_ERROR_PRINTER_OFF: "PrinterConnectionErrorPrinterOff",
    PRINTER_CONNECTION_ERROR_UNPAIRED_DEVICE: "PrinterConnectionErrorUnpairedDevice",
    PRINTER_OFF_ERROR_CODE: "13",
    PRINTER_NOT_PAIRED: "PrinterNotPaired"
}