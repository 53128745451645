import {RESPONSE} from "../../../../constants/ResponseCodes";
import {closeBag, closeContainer, getBagSortInfoForClose} from "../network/CloseContainerNetworkAPI";
import {chainWalk} from "../../../utils/Util";

export const getBagSortInfoForCloseResource = async (bagScannableId, scope) => {
    const response = await getBagSortInfoForClose(bagScannableId, scope)
    return transformGetBagSortInfoForCloseResponse(response)
}

export const getCloseBagResource = async (containerScannableId, destinationScannableId, scope) => {
    const response = await closeBag(containerScannableId, destinationScannableId, scope)
    return {
        bagLabel: response.bagLabel,
        destinationLabel: response.destinationLabel,
        responseCode: RESPONSE[response.responseCode]
    }
}

export const getCloseContainerResource = async (request) => {
    const response = await closeContainer(request)
    return {
        responseCode: RESPONSE[response.responseCode],
        containerScannableType: request.containerScannableType,
        containerLabel: response.containerLabel,
        locationLabel: response.locationLabel
    }
}

const transformGetBagSortInfoForCloseResponse = (data) => {
    const sortLocation = transformSortLocation(data.sortLocations)
    return {
        responseCode: RESPONSE[data.responseCode],
        targetSortLocation: sortLocation,
        aisle: chainWalk(() => sortLocation.locationInfo.aisle, undefined),
        rack: chainWalk(() => sortLocation.locationInfo.rack, undefined)
    }
}

const transformSortLocation = (sortLocations) => {
    const locationInfo = chainWalk(() => sortLocations[0].locationInfo, undefined)
    return locationInfo ? {
        locationInfo: {
            scannableId: locationInfo.scannableId,
            locationType: locationInfo.locationType,
            aisle: locationInfo.aisle,
            rack: locationInfo.rack,
            locationLabel: locationInfo.locationLabel
        }
    } : undefined
}
