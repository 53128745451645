import React, {createContext, useReducer, useContext} from 'react'
import {
    AppNavigationReducer,
    CLOSE_CUSTOM_HELP_OPTION,
    CLOSE_BACK_DROP,
    CLOSE_HELP_OPTION,
    INIT_CONTEXT,
    OPEN_BACK_DROP,
    OPEN_CUSTOM_HELP_OPTION,
    OPEN_HELP_OPTION,
    RESET_NAVIGATION_COL,
    SET_HELP_OPTION,
    SET_NAVIGATION_ERROR_COL,
    SET_NAVIGATION_TITLE,
    CLOSE_BACK_ENABLED,
    OPEN_BACK_ENABLED
} from "../reducer/AppNavigationReducer"
import {Colors} from "../../utils/Colors"
import {APP_NAVIGATION_STRINGS} from "../../../constants/Strings";
import {COMMON_ACTIONS} from "../../../constants/ActionType";
import {ApplicationActions} from "../../player/action/ApplicationActions";
import {PlayerContext} from "../../player/context/PlayerContext";

export const AppNavigationContext = createContext();

export const init_state = {
    navTitle: APP_NAVIGATION_STRINGS.stow,
    navColor: Colors.NAV_COL,
    helpEnabled: false,
    backEnabled: true,
    backDropOpen: false,
    helpOptions: {
        open: false,
        title: APP_NAVIGATION_STRINGS.help_center,
        pageTitle: "",
        list: []
    },
    customHelpOption: {
        open: false
    }
}

const AppNavigationContextProvider = (props) => {
    const [navigationModel, navigationDispatch] = useReducer(AppNavigationReducer, init_state)
    const {dispatchPlayer} = useContext(PlayerContext);

    const setNavErrorCol = () => {
        navigationDispatch({type: SET_NAVIGATION_ERROR_COL});
    }

    const resetNavCol = () => {
        navigationDispatch({type: RESET_NAVIGATION_COL});
    }

    const setNavTitle = (title) => {
        navigationDispatch({type: SET_NAVIGATION_TITLE, data: {title: title}})
    }

    const setHelpOption = (enabled, options) => {
        navigationDispatch({type: SET_HELP_OPTION, data: {enabled, options}});
    }

    const openHelpOption = () => {
        dispatchPlayer({type: ApplicationActions.HELP_SELECTED});
        navigationDispatch({type: OPEN_HELP_OPTION});
    }

    const closeHelpOption = () => {
        navigationDispatch({type: CLOSE_HELP_OPTION});
    }

    const openCustomHelpOption = (type) => {
        navigationDispatch({type: OPEN_CUSTOM_HELP_OPTION, data: {type: type}});
    }

    const closeCustomHelpOption = (type) => {
        navigationDispatch({type: CLOSE_CUSTOM_HELP_OPTION, data: {type: type}});
    }

    const initContext = (data) => {
        navigationDispatch({type: INIT_CONTEXT, data});
    }

    const clearContext = () => {
        navigationDispatch({type: COMMON_ACTIONS.CLEAR_CONTEXT});
    }

    const openBackDrop = () => {
        dispatchPlayer({type: ApplicationActions.BACK_SELECTED});
        navigationDispatch({type: OPEN_BACK_DROP});
    }

    const closeBackDrop = () => {
        navigationDispatch({type: CLOSE_BACK_DROP});
    }

    const closeBackEnabled = () => {
        navigationDispatch({type: CLOSE_BACK_ENABLED});
    }

    const openBackEnabled = () => {
        navigationDispatch({type: OPEN_BACK_ENABLED});
    }


    return (
        <AppNavigationContext.Provider value={{
            navigationModel, navActions:
                {
                    initContext,
                    clearContext,
                    resetNavCol,
                    setNavTitle,
                    setNavErrorCol,
                    setHelpOption,
                    openHelpOption,
                    closeHelpOption,
                    openCustomHelpOption,
                    closeCustomHelpOption,
                    openBackDrop,
                    closeBackDrop,
                    closeBackEnabled,
                    openBackEnabled
                }
        }}>
            {props.children}
        </AppNavigationContext.Provider>
    )
}

export default AppNavigationContextProvider
