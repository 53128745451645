import React, {createContext, useContext, useReducer} from 'react'
import {CLEAR_NOTIFICATION, NotificationReducer, SET_NOTIFICATION} from "../reducer/NotificationReducer";
import {AppNavigationContext} from "../../app-navigation/context/AppNavigationContext";
import {NOTIFICATION_TYPE} from "../../../constants/NotificationType";
import {playSoundByAlertType} from "../../sound/SoundManager";

export const NotificationContext = createContext();

export const model = {
    type: '',
    message: '',
    values: ''
}

const NotificationContextProvider = (props) => {
    const [notificationModel, notificationDispatch] = useReducer(NotificationReducer, model)
    const {navActions: {resetNavCol, setNavErrorCol}} = useContext(AppNavigationContext)

    const clearNotification = () => {
        notificationDispatch({type: CLEAR_NOTIFICATION})
        resetNavCol()
    }

    const setNotification = (notification) => {
        playSoundByAlertType(notification.type);
        notificationDispatch({
            type: SET_NOTIFICATION,
            data: notification
        })
        if (NOTIFICATION_TYPE.ERROR === notification.type) {
            setNavErrorCol()
        } else {
            resetNavCol()
        }
    }

    return (
        <NotificationContext.Provider value={{
            notificationModel, notificationDispatch,
            notificationActions: {clearNotification, setNotification}
        }}>
            {props.children}
        </NotificationContext.Provider>
    )
}

export default NotificationContextProvider
