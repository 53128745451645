import React, {useContext, useEffect} from 'react';
import {
    COMMON_STRINGS,
    MANAGE_STOW_AREA_VIEW_STRINGS,
    SCAN_BAG_LOCATION_VIEW_STRINGS
} from "../../../../../constants/Strings";
import {PrepareContainerContext} from "../../context/PrepareContainerContext";
import {openBag} from "../../action/PrepareContainerAction";
import {WORKFLOW} from "../../../../workflow/Workflow";
import ScanWithLocationView from "../scan-bag/sub/ScanWithLocationView";
import {ConfigContext} from "../../../../config/context/ConfigContext";
import {AppNavigationContext} from "../../../../app-navigation/context/AppNavigationContext";
import ScanView from "../../../../scan-view/ScanView";
import Column from "@amzn/meridian/column";
import ScanQrCodeView from "../../../../scan-qr-code-view/ScanQrCodeView";
import {COMMON_ACTIONS} from "../../../../../constants/ActionType";
import {FormattedMessage} from "react-intl";
import {PlayerContext} from "../../../../player/context/PlayerContext";
import {ScanTypes} from "../../../../player/PlayerConstant";

const ScanBagLocationView = () => {

    const {configViewModel} = useContext(ConfigContext);
    const {prepareContainerModel, prepareContainerDispatch} = useContext(PrepareContainerContext);
    const {navActions: {setHelpOption, closeBackEnabled, setNavTitle}} = useContext(AppNavigationContext)
    const {statePlayer} = useContext(PlayerContext);

    const onScan = (input) => {
        prepareContainerDispatch({type: COMMON_ACTIONS.LOADING})
        statePlayer.scanType = ScanTypes.PREP_BAG_LOCATION_SCAN
        openBag(prepareContainerDispatch, prepareContainerModel.bagLabel, input, configViewModel.scope, configViewModel.workflow)
    }

    const init = {
        primaryTitle: SCAN_BAG_LOCATION_VIEW_STRINGS.scan_bag_location_guidance,
        secondaryTitle: COMMON_STRINGS.scan_location
    }

    const renderView = () => {
        if (WORKFLOW.OPEN_BAG_AT_PACKAGE_SCAN === configViewModel.workflow) {
            return <ScanWithLocationView view={prepareContainerModel.view}
                                         location={configViewModel.locationLabel}/>
        }
        return <Column spacingInset="medium" alignmentHorizontal="center">
            <ScanQrCodeView {...init}/>
        </Column>
    }

    useEffect(() => {
        setNavTitle(<FormattedMessage id={MANAGE_STOW_AREA_VIEW_STRINGS.prepare_container}/>);
        setHelpOption(true, {list: []});
        return () => setHelpOption(false, {});
    }, []);

    useEffect(() => {
        closeBackEnabled()
    }, []);

    return (
        <div>
            {renderView()}
            <ScanView onScan={onScan}/>
        </div>
    )
}

export default ScanBagLocationView
