import React, {useContext} from "react";
import {injectIntl} from "react-intl";
import {COMMON_STRINGS} from "../../../../constants/Strings";
import QrCode from "../../../../images/ic_qr_code.png";
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import {NotificationContext} from "../../../notification/context/NotificationContext";
import {getPrinterConnection, processPrinterResponse} from "./data/StowPrintingData";
import {PRINTER} from "../../../utils/PrinterUtils";
import {getFormattedString} from "../../../utils/Util";
import ScanView from "../../../scan-view/ScanView";
import Column from "@amzn/meridian/column";

const StowPrinterView = (props) => {

    const {notificationActions: {clearNotification, setNotification}} = useContext(NotificationContext)

    const onScan = (macAddress) => {
        getPrinterConnection(macAddress, setNotification, props.history)
    }

    const onCancel = () => {
        clearNotification()
        props.history.push(processPrinterResponse(null, PRINTER.PRINTER_CANCEL))
    }

    const init = {
        primaryTitle: {
            title: getFormattedString(props, COMMON_STRINGS.scan_printer),
            type: "Bold"
        },
        secondaryTitle: {
            title: getFormattedString(props, COMMON_STRINGS.grab_new_printer),
        },
        image: QrCode,
        button: {
            title: getFormattedString(props, COMMON_STRINGS.CANCEL),
            onClick: onCancel
        }
    }

    const renderView = () => {
        return <ScanWithCodeView {...init}/>
    }

    return <Column spacingInset="medium" alignmentHorizontal="center" height="100%">
        {renderView()}
        <ScanView onScan={onScan}/>
    </Column>
}

export default injectIntl(StowPrinterView)