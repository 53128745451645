import {model} from "../context/StowPackageContext";
import {RESPONSE} from "../../../constants/ResponseCodes";
import {chainWalk} from "../../utils/Util";
import {VIEW, WORKFLOW} from "../../workflow/Workflow";
import {handleSALPrintingForConPackageType} from "../../stow-conveyable-selection/view/con-package/data/StowPrintingData";
import {getStowRescanPackageData} from "../view/rescan-package/data/StowRescanPackageViewData";
import {getStowScanPackageData} from "../view/scan-package/data/StowScanPackageViewData";
import {getStowScanLocationViewData} from "../view/scan-location/data/StowScanLocationViewData";
import {getStowScanLocationFlowViewData} from "../flow-mode/view/scan-location-flow/data/StowScanLocationFlowViewData";
import {getStowScanPackageFlowViewData} from "../flow-mode/view/scan-package-flow/data/StowScanPackageFlowViewData";
import {COMMON_ACTIONS} from "../../../constants/ActionType";

export const CLEAR_STOW_PACKAGE_CONTEXT = "CLEAR_STOW_PACKAGE_CONTEXT"
export const GET_SORT_INFO_FOR_PACKAGE_RESPONSE = "GET_SORT_INFO_FOR_PACKAGE_RESPONSE";
export const PROCESS_STOW_RESPONSE = "PROCESS_STOW_RESPONSE"
export const VALIDATE_LOCATION_BEFORE_STOW = "VALIDATE_LOCATION_BEFORE_STOW"

export const StowPackageReducer = (state, action) => {

    let stowViewData = undefined
    switch (action.type) {
        case CLEAR_STOW_PACKAGE_CONTEXT:
            return {...model}
        case GET_SORT_INFO_FOR_PACKAGE_RESPONSE:
            if(VIEW.STOW_PACKAGE === state.view) {
                stowViewData = getStowScanPackageData(action.data);
                printSALForConPackage(action.data)
                return {
                    ...state,
                    ...action.data,
                    ...stowViewData,
                    expectedBagLabel: chainWalk(() => action.data.targetSortLocation.bagInfo.label, undefined),
                    loading: false
                }
            } else {
                return {...state, ...getStowRescanPackageData(action.data), loading: false};
            }
        case PROCESS_STOW_RESPONSE:
            if (WORKFLOW.STOW_FLOW === action.data.workflow) {
                stowViewData = getStowScanPackageFlowViewData(constructFlowInput(state, action.data))
            } else {
                stowViewData = getStowScanLocationViewData(constructInput(state, action.data))
            }
            return {
                ...state,
                ...action.data,
                ...stowViewData,
                incorrectDestinationScanned: RESPONSE.INVALID_MOVE === action.data.responseCode ? action.data.destinationLabel : undefined,
                loading: false
            }
        case VALIDATE_LOCATION_BEFORE_STOW:
            stowViewData = getStowScanLocationFlowViewData(constructFlowInput(state, action.data))
            return {
                ...state,
                ...action.data,
                ...stowViewData,
                loading: false
            }
        case COMMON_ACTIONS.LOADING:
            return {
                ...state,
                loading: true,
                notification: null
            }
        default:
            return state;
    }
}

const constructInput = (currentState, newState) => {
    return {
        view: currentState.view,
        responseCode: newState.responseCode,
        locationLabel: newState.destinationLabel,
        bagLabel: newState.bagLabel,
        displayPackageId: currentState.displayPackageId,
        packageHolderStation: currentState.packageHolderStation,
        floorLabel: currentState.floorLabel,
        scope: currentState.scope
    }
}

const constructFlowInput = (currentState, newState) => {
    return {
        view: currentState.view,
        responseCode: newState.responseCode,
        locationLabel: currentState.destinationLabel,
        locationScannable: newState.locationScannable,
        packageId: newState.packageId,
        displayPackageId: newState.displayPackageId
    }
}

const printSALForConPackage = (data) => {
    if (data.workflow === WORKFLOW.CON_PACKAGE) {
        const SAL_DATA = getSalData(data.salContent);
        handleSALPrintingForConPackageType(SAL_DATA);
    }
};

const getSalData = (salContent) => {
    if(!salContent) return null;
    let salData = {};
    salData.binLabel = (salContent.binLabel)?salContent.binLabel:"";
    salData.cycle = (salContent.cycle)?salContent.cycle:"";
    salData.packageTrackingId = (salContent.packageTrackingId)?salContent.packageTrackingId:"";

    if(salContent.conveyance && salContent.conveyance.printableLabel) {
        salData.conveyancePrintableLabel = salContent.conveyance.printableLabel;
    } else {
        salData.chuteLabel = (salContent.chuteLabel)?salContent.chuteLabel:"";
    }

    if(salContent.routeCode) {
        salData.routeCode = salContent.routeCode;
    }

    salData.deliveryAssistLabel = (salContent.deliveryAssistLabel)?salContent.deliveryAssistLabel:"";
    return salData;
};
