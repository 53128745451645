import React, {Component} from "react";
import PrepareContainerContextProvider from "../prepare-container/context/PrepareContainerContext";
import PrepareRouteView from "./view/PrepareRouteView";

class PrepareRoute extends Component {

    render() {

        return (
            <PrepareContainerContextProvider>
                <PrepareRouteView history={this.props.history}/>
            </PrepareContainerContextProvider>
        )
    }
}
export default PrepareRoute
