import React, {useContext, useEffect} from 'react';
import {ConfigContext} from "../../../config/context/ConfigContext";
import {StowPackageContext} from "../../context/StowPackageContext";
import {NotificationContext} from "../../../notification/context/NotificationContext";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {VIEW, WORKFLOW} from "../../../workflow/Workflow";
import {getFormattedString, isNonNull} from "../../../utils/Util";
import {APP_NAVIGATION_STRINGS} from "../../../../constants/Strings";
import StowScanLocationFlowView from "./scan-location-flow/StowScanLocationFlowView";
import StowScanPackageFlowView from "./scan-package-flow/StowScanPackageFlowView";
import {setGlobalContext} from "../../../config/action/ConfigAction";
import {CUSTOM_HELP_OPTIONS} from "../../../app-navigation/AppNavigation";
import StowManualScan from "../../view/manual-scan/StowManualScanView";
import {injectIntl} from "react-intl";

const StowFlowModeView = (props) => {

    const {configViewModel, configViewDispatch} = useContext(ConfigContext);
    const {stowPackageModel} = useContext(StowPackageContext);
    const {notificationActions: {setNotification}} = useContext(NotificationContext);
    const {navigationModel, navActions: {setNavTitle}} = useContext(AppNavigationContext)

    useEffect(() => {
        if (isNonNull(stowPackageModel.notification)) {
            setNotification(stowPackageModel.notification)
        }
        if (isNonNull(stowPackageModel.nextRoute)) {
            props.history.push(stowPackageModel.nextRoute)
        }
        setGlobalContext(configViewDispatch, stowPackageModel)
    }, [stowPackageModel]);

    useEffect(() => {
        setNavTitle(getFormattedString(props, APP_NAVIGATION_STRINGS.stow_flow))
    }, []);

    const renderView = () => {
        if (VIEW.STOW_FLOW === stowPackageModel.view || WORKFLOW.STOW_FLOW === configViewModel.workflow) {
            if (navigationModel.customHelpOption.open
                && CUSTOM_HELP_OPTIONS.STOW_MANUAL_SCAN_PACKAGE === navigationModel.customHelpOption.type) {
                return <StowManualScan history={props.history}/>
            }
            return <StowScanPackageFlowView history={props.history}/>
        }
        else {
            return <StowScanLocationFlowView history={props.history}/>
        }
    }

    return (
        renderView()
    )
}

export default injectIntl(StowFlowModeView)
