import {getSALTemplateForPrinter} from "../network/StowSelectionNetwork";
import {RESPONSE} from "../../../constants/ResponseCodes";


export const getSALTemplateForPrinterResource = async (printerType) => {

    const response = await getSALTemplateForPrinter(printerType)
    return {
        responseCode: RESPONSE[response.responseCode],
        template: response.template
    }
}