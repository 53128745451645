import React, {Component} from "react";
import StowConveyableSelectionView from "./view/StowConveyableSelectionView";
import StowSelectionContextProvider from "./context/StowSelectionContext"

class StowConveyableSelection extends Component {

    render() {
        return (
            <StowSelectionContextProvider>
                <StowConveyableSelectionView history={this.props.history}/>
            </StowSelectionContextProvider>
        )
    }
}

export default StowConveyableSelection
