import {DataHandler, RedirectHandler} from "@amzn/dolphin-web-framework";
import {Modules} from "../../constants/Modules";
import {getStageExecutionURL} from "../utils/network/BaseUrlUtils";
import {REDIRECT_HASH} from "../../constants/RedirectConstants";
import AppDataStore from "../app-data";
import {ROUTING} from "../../constants/Routing";
import {Logger} from "@amzn/dolphin-web-framework";
import {SCOPE} from "../../constants/Scope";
import CartStageReadinessPollerInstance from "../../util/CartStageReadinessPoller";

/**
 * Transfer control to Stage Module for staging or handle response
 * from callback by stage.
 */
async function stageCart(props, cartId, routeId) {

    const redirectData = DataHandler.getRedirectData();

    const invokeStaging = async () => {

        const redirectData = {
            programType: SCOPE.AR_NEXT_GEN,
            activeCartId: cartId,
            activeCartLabel: cartId,
            routeId: routeId
        };

        // Get Stage Execution Website Host
        const stageURL = getStageExecutionURL();

        // Get current URL for callback
        const currentURL = new URL(window.location.href);

        // Store that we are going to redirect to Stage
        AppDataStore.redirectModule();

        // Store program-type, current cart, total carts touched so far in session storage to retrieve this data back after redirect completes.
        AppDataStore.saveProgramType(SCOPE.AR_NEXT_GEN);
        AppDataStore.saveCurrentCartInfo(cartId);
        AppDataStore.saveCartList(CartStageReadinessPollerInstance.getCartList());

        // Invoke Stage Module
        RedirectHandler.invokeModule(Modules.STOW, Modules.STAGE, stageURL.host, REDIRECT_HASH.STAGE.SCAN_STAGING_AREA,
            redirectData, currentURL.host, REDIRECT_HASH.STOW.PREPARE_STOW_LOCATION, stageURL.pathname, currentURL.pathname);
    };


    const continueAfterStaging = async () => {
        AppDataStore.redirectComplete();
        // Reload Component
        props.history.replace(ROUTING.PREPARE_STOW_LOCATION);
        props.history.go(0);
    };

    // If we are redirected back from Stage module after performing cart staging
    if (AppDataStore.wasModuleRedirected() && redirectData &&
        redirectData.redirectFromModule === Modules.STAGE) {
        Logger.log.info("Redirected back from Stage Module");
        await continueAfterStaging();
    }
    // Else we need to open stage module for staging cart
    else {
        Logger.log.info("Redirecting to Stage Module");
        await invokeStaging();
    }
}

export default stageCart;