import beep from "../../audio/res/beep.ogg";
import beep_warning from "../../audio/res/beep_warning.ogg";
import scanner_beep from "../../audio/res/scanner_beep.ogg";
import silent from "../../audio/res/silent.mp3";
import {NotificationType} from "../../constants/Constants";
import {Logger} from "@amzn/dolphin-web-framework";

const VIBRATION_DURATION_IN_MS = 200;

function initializeSoundObject(resource) {
    let audio = new Audio(resource);
    audio.preload = 'auto';
    return audio
}

let beep_sound = initializeSoundObject(beep);
let beep_warning_sound = initializeSoundObject(beep_warning);
let scanner_beep_sound = initializeSoundObject(scanner_beep);
let silent_sound = initializeSoundObject(silent);

export const SoundType = {
    BEEP: beep_sound,
    BEEP_WARNING: beep_warning_sound,
    SCANNER_BEEP: scanner_beep_sound,
    SILENT: silent_sound
};

export const playSoundByAlertType = (alertType) => {
    switch(alertType) {
        case NotificationType.SUCCESS:
            SoundType.BEEP.play().catch(error => {
                Logger.log.warn("Unable to play sound : " + error);
            });
            window.navigator.vibrate(VIBRATION_DURATION_IN_MS);
            break;
        case NotificationType.WARNING:
            SoundType.BEEP_WARNING.play().catch(error => {
                Logger.log.warn("Unable to play sound : " + error);
            });
            window.navigator.vibrate(VIBRATION_DURATION_IN_MS);
            break;
        case NotificationType.ERROR:
            SoundType.SCANNER_BEEP.play().catch(error => {
                Logger.log.warn("Unable to play sound : " + error);
            });
            window.navigator.vibrate(VIBRATION_DURATION_IN_MS);
            break;
        default:
            break
    }
};