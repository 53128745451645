import React, {Component} from 'react'
import ReplaceContainerView from "./view/ReplaceContainerView";

class ReplaceContainer extends Component {

    render () {
        return <ReplaceContainerView history = {this.props.history}
                                     containerScannableType={this.props.match.params.containerScannableType}/>
    }
}

export default ReplaceContainer
