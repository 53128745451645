import React, {useContext, useEffect} from 'react';
import {HeaderView, SnapshotView} from "@amzn/dolphin-uiweb-framework";
import {StowPackageContext} from "../../context/StowPackageContext";
import {getSortInfoForPackage} from "../../action/StowPackageAction";
import {ConfigContext} from "../../../config/context/ConfigContext";
import {getRescanCardViewData} from "./data/StowRescanViewData";
import {blockBackPress, EMPTY, resetBlockedBackPress} from "../../../utils/Util";
import {injectIntl} from "react-intl";
import Column from "@amzn/meridian/column";
import SnapshotWithImageView from "./SnapshotWithImageView";
import Tick from "../../../../images/tick.png";
import Cross from "../../../../images/cross.png";
import {SCOPE} from "../../../../constants/Scope";
import {COMMON_STRINGS} from "../../../../constants/Strings";
import Box from "@amzn/meridian/box";
import ScanView from "../../../scan-view/ScanView";
import {COMMON_ACTIONS} from "../../../../constants/ActionType";
import {PlayerContext} from "../../../player/context/PlayerContext";
import {ScanTypes} from "../../../player/PlayerConstant";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";

const StowRescanPackageView = (props) => {

    const {configViewModel} = useContext(ConfigContext)
    const {stowPackageModel, stowPackageDispatch} = useContext(StowPackageContext);
    const {statePlayer} = useContext(PlayerContext);
    const {navActions: {initContext, closeBackEnabled, openBackEnabled, openBackDrop}} = useContext(AppNavigationContext)

    useEffect(() => {
        blockBackPress(initContext, closeBackEnabled);
        return () => {
            resetBlockedBackPress(openBackEnabled, initContext, openBackDrop);
        };
    },[]);

    const onScan = (input) => {
        stowPackageDispatch({type: COMMON_ACTIONS.LOADING})
        let shouldNotifySbl = true;
        if(input !== configViewModel.packageId) {
            shouldNotifySbl = false;
        }
        statePlayer.scanType = ScanTypes.PACKAGE_RESCAN;
        getSortInfoForPackage(stowPackageDispatch, input, configViewModel.scope, EMPTY, configViewModel.packageId, configViewModel.sblEndpoint, shouldNotifySbl, statePlayer)
    }

    const renderView = () => {
        const data = getRescanCardViewData(configViewModel.scope, stowPackageModel)
        switch (configViewModel.scope) {
            case SCOPE.AR:
            case SCOPE.AR_NEXT_GEN:
            case SCOPE.SSD:
                return <Box type="outline">
                    <SnapshotView headerFontSize={"Large"} title={stowPackageModel.packageId}
                                  description={props.intl.formatMessage({id: COMMON_STRINGS.package_id})}/>

                    <SnapshotWithImageView headerFontSize={"Large"} title={data.wrongLocationScannedTitle}
                                           description={props.intl.formatMessage({id: COMMON_STRINGS.wrong_location_scanned})}
                                           image={Cross}/>
                    <SnapshotWithImageView headerFontSize={"Large"} title={data.correctLocationScannedTitle}
                                           description={props.intl.formatMessage({id: COMMON_STRINGS.correct_location})}
                                           image={Tick}/>
                </Box>

            default:
                return <Box type="outline">
                    <SnapshotView headerFontSize={"Large"} title={stowPackageModel.packageId}
                                  description={props.intl.formatMessage({id: COMMON_STRINGS.package_id})}/>
                    {stowPackageModel.incorrectDestinationScanned &&
                    <SnapshotWithImageView headerFontSize={"Large"} title={data.wrongLocationScannedTitle}
                                           description={props.intl.formatMessage({id: COMMON_STRINGS.wrong_location_scanned})}
                                           image={Cross}/>}
                    <SnapshotWithImageView headerFontSize={"Large"} title={data.correctLocationScannedTitle}
                                           description={props.intl.formatMessage({id: COMMON_STRINGS.correct_location})}
                                           image={Tick}/>
                </Box>
        }
    }

    return (
        <div>
            <Column spacingInset="medium" alignmentHorizontal="center">
                <HeaderView primaryTitle={{
                    title: "Rescan package",
                    type: "Bold",
                    size: "Large"
                }}/>
            </Column>
            <Column spacingInset="small">
                {renderView()}
            </Column>
            <ScanView onScan={onScan}/>
        </div>
    )
}

export default injectIntl(StowRescanPackageView)
