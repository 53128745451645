import React, {useContext, useEffect} from 'react';
import {APP_NAVIGATION_STRINGS, SELECT_CONTAINER_VIEW_STRINGS} from "../../../../constants/Strings";
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import {CONTAINER_TYPE} from "../../../../constants/ContainerType";
import {FormattedMessage, injectIntl} from "react-intl";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {STOW_AREA_OPERATION} from "../../../../constants/StowAreaOperation";
import {SLASH} from "../../../utils/Util";
import {ROUTING} from "../../../../constants/Routing";
import {interceptBackButton, interceptBackButtonStop} from "../../../bridge/BackButton";
import {ApplicationActions} from "../../../player/action/ApplicationActions";
import {PlayerContext} from "../../../player/context/PlayerContext";

const SelectContainerView = (props) => {

    const {navActions: {setNavTitle, setHelpOption, initContext, clearContext, openBackDrop}} = useContext(AppNavigationContext)
    const {dispatchPlayer} = useContext(PlayerContext);

    const isPrepareContainerOperation = () => STOW_AREA_OPERATION.PREPARE_CONTAINER === props.stowAreaOperation
    const isCloseContainerOperation = () => STOW_AREA_OPERATION.CLOSE_CONTAINER === props.stowAreaOperation

    const handleNavigation = (containerScannableType) => {
        interceptBackButtonStop();
        if (isPrepareContainerOperation()) {
            dispatchPlayer({type: ApplicationActions.CONTAINER_SELECTION, data: {operation: STOW_AREA_OPERATION.PREPARE_CONTAINER, type: containerScannableType}});
            props.history.push(ROUTING.PREPARE_CONTAINER + SLASH + containerScannableType)
        } else if (isCloseContainerOperation()) {
            dispatchPlayer({type: ApplicationActions.CONTAINER_SELECTION, data: {operation: STOW_AREA_OPERATION.CLOSE_CONTAINER, type: containerScannableType}});
            props.history.push(ROUTING.CLOSE_CONTAINER + SLASH + containerScannableType)
        }
    }

    const init = {
        pageTitle: <FormattedMessage id={SELECT_CONTAINER_VIEW_STRINGS.select_container}/>,
        tasks: [
            {
                title: props.intl.formatMessage({id: SELECT_CONTAINER_VIEW_STRINGS.bags}),
                description: props.intl.formatMessage({id: SELECT_CONTAINER_VIEW_STRINGS.select_bags_guidance}),
                onClick: () => handleNavigation(CONTAINER_TYPE.BAG)
            },
            {
                title: props.intl.formatMessage({id: SELECT_CONTAINER_VIEW_STRINGS.carts}),
                description: props.intl.formatMessage({id: SELECT_CONTAINER_VIEW_STRINGS.select_carts_guidance}),
                onClick: () => handleNavigation(CONTAINER_TYPE.CART)
            },
            {
                title: props.intl.formatMessage({id: SELECT_CONTAINER_VIEW_STRINGS.pallets}),
                description: props.intl.formatMessage({id: SELECT_CONTAINER_VIEW_STRINGS.select_pallets_guidance}),
                onClick: () => handleNavigation(CONTAINER_TYPE.PALLET)
            }
        ]
    }

    useEffect(() => {
        if (isPrepareContainerOperation()) {
            setNavTitle(<FormattedMessage id={APP_NAVIGATION_STRINGS.prepare_container}/>);
        } else if (isCloseContainerOperation()) {
            setNavTitle(<FormattedMessage id={APP_NAVIGATION_STRINGS.close_container}/>);
        }
        setHelpOption(true, {list: []});
        const onBackPressed = openBackDrop;
        initContext({onBackPressed});
        interceptBackButton('select container view');
        return () => {setHelpOption(false, {}); clearContext()}
    }, []);

    return (
        <div>
            <TaskView {...init} />
        </div>
    )
}

export default injectIntl(SelectContainerView)
