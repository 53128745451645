import React, {useContext, useEffect} from 'react';
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import {
    APP_NAVIGATION_STRINGS,
    COMMON_STRINGS,
    HELP_CENTER_STRINGS,
    SCRUB_CONTAINER_STRINGS
} from "../../../constants/Strings";
import {ROUTING} from "../../../constants/Routing";
import {FormattedMessage} from "react-intl";
import {AppNavigationContext} from "../../app-navigation/context/AppNavigationContext";
import {getHelpOptions, getSelectContainerRoutingPath} from "../../utils/Util";
import {STOW_AREA_OPERATION} from "../../../constants/StowAreaOperation";
import {interceptBackButton, interceptBackButtonStop} from "../../bridge/BackButton";
import {ApplicationActions} from "../../player/action/ApplicationActions";
import {PlayerContext} from "../../player/context/PlayerContext";

const ScrubTaskView = (props) => {

    const {navActions: {setNavTitle, setHelpOption, closeHelpOption, openBackDrop, initContext, clearContext}} = useContext(AppNavigationContext)
    const {dispatchPlayer} = useContext(PlayerContext);

    const handleClick = (scrubOperation) => {
        interceptBackButtonStop();
        dispatchPlayer({type: ApplicationActions.SCRUB, data: {operation: scrubOperation.type}});
        props.history.push(scrubOperation.nextRoute)
    }

    const init = {
        pageTitle: <FormattedMessage id={COMMON_STRINGS.select_scrub_task}/>,
        tasks: [
            {
                title: <FormattedMessage id={SCRUB_CONTAINER_STRINGS.scrub_location_title}/>,
                description: <FormattedMessage id={SCRUB_CONTAINER_STRINGS.scrub_location_task_desc}/>,
                onClick: () => handleClick({nextRoute: ROUTING.SCRUB_LOCATION, type: SCRUB_CONTAINER_STRINGS.scrub_location_title})
            },
            {
                title: <FormattedMessage id={SCRUB_CONTAINER_STRINGS.scrub_container_title}/>,
                description: <FormattedMessage id={SCRUB_CONTAINER_STRINGS.scrub_container_task_desc}/>,
                onClick: () => handleClick({nextRoute: ROUTING.SCRUB_CONTAINER, type: SCRUB_CONTAINER_STRINGS.scrub_container_title})
            }
        ]
    }

    useEffect(() => {
        setNavTitle(<FormattedMessage id={APP_NAVIGATION_STRINGS.stow}/>);
        const callbacks = [
            () => {
                dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HELP_CENTER_STRINGS.CLOSE_CONTAINER_HELP}});
                props.history.push(getSelectContainerRoutingPath(STOW_AREA_OPERATION.CLOSE_CONTAINER))
            },
            closeHelpOption
        ]
        setHelpOption(true, getHelpOptions(callbacks,
            <FormattedMessage id={HELP_CENTER_STRINGS.STOW_PROBLEMS_HELP}/>,
            <FormattedMessage id={HELP_CENTER_STRINGS.CLOSE_CONTAINER}/>));
        const onBackPressed = openBackDrop;
        initContext({onBackPressed});
        interceptBackButton('scrub task');
        return () => {setHelpOption(false, {});clearContext()}
    }, [])

    return (
        <div>
            <TaskView {...init} />
        </div>
    )
}

export default ScrubTaskView
