import {NOTIFICATION_TYPE} from "../../../../constants/NotificationType";
import {NOTIFICATION_STRING} from "../../../../constants/Strings";

export const getInvalidLocationScannedData = (data) => {

    return {
        view: data.view,
        notification: {
            type: NOTIFICATION_TYPE.ERROR,
            message: NOTIFICATION_STRING.ALERT_ERROR_INCORRECT_LOCATION,
            values: {locationLabel: data.scannedInput}
        }
    }
}
