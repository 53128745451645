import {getDolphinBaseURL} from "./BaseUrlUtils";
import {DolphinAPIClientProvider, PUMAMetricHelper} from "@amzn/dolphin-web-framework";

export function getDolphinClient() {
    DolphinAPIClientProvider.generateDolphinAPIClient(getDolphinBaseURL());
    return DolphinAPIClientProvider.dolphinAPIClient;
}

export async function axiosPOST(metricName, path, requestData) {
    const startTime = Date.now();
    try {
        PUMAMetricHelper.publishCountToDolphinCSM(metricName);
        const response = await getDolphinClient().post(path, requestData);
        PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, metricName);
        return response;
    } catch(error) {
        publishErrorMetric(metricName, startTime, error);
        throw error;
    }
}

export async function axiosGET(metricName, path, params) {
    const startTime = Date.now();
    try {
        PUMAMetricHelper.publishCountToDolphinCSM(metricName);
        const response = await getDolphinClient().get(path, params);
        PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, metricName);
        return response;
    } catch(error) {
        publishErrorMetric(metricName, startTime, error);
        throw error;
    }
}

export function publishErrorMetric(metricName, startTime, error) {

    //Emitting Failure Metrics
    PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, metricName);
    PUMAMetricHelper.publishCountToDolphinCSM(metricName+"-failure");
    if (error.status >= 400 && error.status < 500) {
        PUMAMetricHelper.publishCountToDolphinCSM("4xxError");
    }
    else {
        PUMAMetricHelper.publishCountToDolphinCSM("5xxError");
    }
}

export const API_PATH = {
    VALIDATE_CONTAINER_BEFORE_ATTACH: "nss/attach/validateContainer",
    ATTACH_CONTAINER_TO_DESTINATION: "nss/attach/container/to/destination",
    GET_SORT_INFO_FOR_PACKAGE: "nss/get/packageSortInfo",
    PROCESS_STOW: "nss/process/stow",
    GET_BAG_SORT_INFO_CLOSE: "nss/get/bagCloseSortInfo",
    CLOSE_BAG: "nss/close/bag",
    CLOSE_CONTAINER: "nss/close/container",
    SAL_TEMPLATE: "nss/get/salTemplate",
    GET_SCOPE_FOR_NODE: "nss/get/scopeForNode",
    MOVE_TO_PROBLEM_SOLVE: "nss/move/problemsolve",
    GET_ROUTES_FOR_SORT_LOCATION: "nss/get/routesForSortLocation",
    GET_STOW_PROGRESS_ROUTES_FOR_SORT_LOCATION: "nss/get/routesStowProgressForSortLocation",
    VALIDATE_LOCATION_BEFORE_STOW: "nss/stow/validateLocationBeforeStow",
    VALIDATE_BAG_BEFORE_OPEN: "nss/open/validateBag",
    OPEN_BAG: "nss/open/bag",
    BATCH_GET_ITEM_STAGE_INFO: "/sps/picklists/batchGetItemStageInfo"
}
