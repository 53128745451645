import React, {Component} from "react";
import {SnapshotView} from "@amzn/dolphin-uiweb-framework";
import Box from "@amzn/meridian/box";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";

class DetailsCardWithCartView extends Component {

    createSnapshots = (snapshots, headerFontSize) => {
        return snapshots.map((currentSnapshot, index) =>
            <SnapshotView key={index} title={currentSnapshot.title} description={currentSnapshot.description}
                          headerFontSize={headerFontSize}/>
        )
    }

    setDisplay = (snapshots) => {
        return (
            <Column width="100%" spacing="none">
                <Row>
                    {snapshots[0]}
                    {snapshots[1]}
                </Row>
                <Row>
                    {snapshots[2]}
                </Row>
            </Column>)
    }


    render() {
        const { backgroundColor, headerFontSize, snapshots } = this.props
        const dataSnapshots = this.createSnapshots(snapshots, headerFontSize)
        const displayData = this.setDisplay(dataSnapshots)
        return (
            <div>
                <Box type="outline">
                    <Box backgroundColor={backgroundColor} spacingInset="small">
                        {displayData}
                    </Box>
                </Box>
            </div>
        )
    }
}

export default DetailsCardWithCartView;