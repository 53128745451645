import {RESPONSE} from "../../../constants/ResponseCodes";
import {getRoutesStowProgressForSortLocation} from "../network/StowProgressNetwork";
import {EMPTY_ARRAY} from "../../utils/Util";

export const getRoutesStowProgressForSortLocationResource = async (scannableId) => {

    const response = await getRoutesStowProgressForSortLocation(scannableId)
    return {
        responseCode: RESPONSE[response.responseCode],
        routesStowProgress: transformRoutesStowProgress(response.routesStowProgress)
    }
}

const transformRoutesStowProgress = (routesStowProgress) => {
    return routesStowProgress
        ? routesStowProgress.map(routeStowProgress => ({
            routeStowProgress: transformRouteStowProgress(routeStowProgress)
        }))
        : EMPTY_ARRAY
}

const transformRouteStowProgress = (routeStowProgress) => {
    return {
        isStaged: routeStowProgress.isStaged,
        plannedNotStowedCount: routeStowProgress.plannedNotStowedCount,
        plannedNotStowedPackages: routeStowProgress.plannedNotStowedPackages,
        plannedPackagesCount: routeStowProgress.plannedPackagesCount,
        plannedStowedCount: routeStowProgress.plannedStowedCount,
        routeCode: routeStowProgress.routeCode,
        routeId: routeStowProgress.routeId,
        sortLocationLabels: routeStowProgress.sortLocationLabels,
        unplannedStowedCount: routeStowProgress.unplannedStowedCount,
        unplannedStowedPackages: routeStowProgress.unplannedStowedPackages
    }
}