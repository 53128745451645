import {RESPONSE} from "../../../../../../../constants/ResponseCodes";

export const TEST_RESPONSE_200 = () => {
    return {
        status: 200,
        data: TEST_SUCCESS_RESPONSE
    }
}

const TEST_SUCCESS_RESPONSE = {
    responseCode: RESPONSE.SUCCESS
}
