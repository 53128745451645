import React, {createContext, useReducer} from 'react'
import {ConfigReducer} from "../reducer/ConfigReducer";

export const ConfigContext = createContext();

const ConfigContextProvider = (props) => {

    const [configViewModel, configViewDispatch] = useReducer(ConfigReducer, {})
    return (
        <ConfigContext.Provider value={{configViewModel: configViewModel, configViewDispatch: configViewDispatch}}>
            {props.children}
        </ConfigContext.Provider>
    )
}

export default ConfigContextProvider
