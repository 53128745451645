import React, {useContext, useEffect} from 'react';
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import BarCode from "../../../../images/ic_package_scan.png";
import {NOTIFICATION_STRING, SCAN_PACKAGE_VIEW_STRINGS} from "../../../../constants/Strings";
import {CLEAR_STOW_PACKAGE_CONTEXT} from "../../reducer/StowPackageReducer";
import {ROUTING} from "../../../../constants/Routing";
import {WORKFLOW} from "../../../workflow/Workflow";
import {injectIntl} from "react-intl";
import {getSortInfoForPackage} from "../../action/StowPackageAction";
import {StowPackageContext} from "../../context/StowPackageContext";
import {ConfigContext} from "../../../config/context/ConfigContext";
import ScanView from "../../../scan-view/ScanView";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {getConfigForViewOfConPackages, getHelpData} from "./data/StowScanPackageViewData";
import {COMMON_ACTIONS} from "../../../../constants/ActionType";
import {getFormattedString} from "../../../utils/Util";
import {interceptBackButtonStop} from "../../../bridge/BackButton";
import Column from "@amzn/meridian/column";
import {NotificationContext} from "../../../notification/context/NotificationContext";
import {NOTIFICATION_TYPE} from "../../../../constants/NotificationType";
import {ScanTypes} from "../../../player/PlayerConstant";
import {PlayerContext} from "../../../player/context/PlayerContext";

const StowScanPackageView = (props) => {

    const {configViewModel} = useContext(ConfigContext)
    const {navActions: {setHelpOption, closeHelpOption, openCustomHelpOption}} = useContext(AppNavigationContext)
    const {stowPackageDispatch} = useContext(StowPackageContext);
    const {notificationActions: {setNotification}} = useContext(NotificationContext)
    const {statePlayer, dispatchPlayer} = useContext(PlayerContext);

    const onClickChangePrinter = (showNotification) => {
        interceptBackButtonStop();
        if(showNotification) {
            setNotification({
                type: NOTIFICATION_TYPE.ERROR,
                message: NOTIFICATION_STRING.ALERT_ERROR_PRINTER_NOT_CONNECTED
            });
        }
        props.history.push(ROUTING.STOW_CON_PACKAGE);
    };

    const onScan = (input) => {
        stowPackageDispatch({type: COMMON_ACTIONS.LOADING})
        const workflow = props.isTopupFlow ? WORKFLOW.STOW_TOPUP : configViewModel.workflow
        statePlayer.scanType = ScanTypes.PACKAGE_SCAN
        getSortInfoForPackage(stowPackageDispatch, input, configViewModel.scope, workflow, null, configViewModel.sblEndpoint, true)
    }

    const checkAveryConnectionStatus = async () => {
        const response = await NativeMeshInteractor.getAveryConnectionStatus();
        if(response !== "true") {
            onClickChangePrinter(true);
        }
    };

    useEffect( () => {
        if (WORKFLOW.CON_PACKAGE === configViewModel.workflow) {
            checkAveryConnectionStatus();
        }
        stowPackageDispatch({type: CLEAR_STOW_PACKAGE_CONTEXT})
        setHelpOption(true, getHelpData(configViewModel.scope, props, closeHelpOption, openCustomHelpOption, dispatchPlayer))
    }, []);

    const init_default = {
        primaryTitle: {
            title: getFormattedString(props, SCAN_PACKAGE_VIEW_STRINGS.scan_package),
            size: "Large",
            type: "Bold"
        },
        image: BarCode
    }

    const renderView = () => {
        if (WORKFLOW.CON_PACKAGE === configViewModel.workflow) {
            const init_con_packages = getConfigForViewOfConPackages(props, onClickChangePrinter);
            return (
                <ScanWithCodeView {...init_con_packages}/>
            )
        }
        return <ScanWithCodeView {...init_default}/>
    }

    return (
        <Column spacingInset="medium" alignmentHorizontal="center" height="100%">
            {renderView()}
            <ScanView onScan={onScan}/>
        </Column>
    )
}

export default injectIntl(StowScanPackageView)
