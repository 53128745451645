import React from 'react'
import {RESPONSE} from "../../../../../constants/ResponseCodes";
import {PRINTER} from "../../../../utils/PrinterUtils";
import {ROUTING} from "../../../../../constants/Routing";
import {NOTIFICATION_TYPE} from "../../../../../constants/NotificationType";
import {FormattedMessage} from "react-intl";
import {NOTIFICATION_STRING} from "../../../../../constants/Strings";
import {Logger, NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {ChangePrinterResponse} from "./ChangePrinterResponse";

const AVERY_SAL_RESOURCE_LABEL_TAG = "StowSALTemplate";

export const isPrinterConnected = () => {
    //todo: logic to check for printer
    return true
}

export const enablePrinting = () => {
    // todo: enabling printing code ref (https://tiny.amazon.com/f6zpkcae/codeamazpackStatblob0e9esrc)
}

export const processSALResponse = (response) => {

    if (RESPONSE.SUCCESS === response.responseCode){
        NativeMeshInteractor.setSalFormat(response.template, AVERY_SAL_RESOURCE_LABEL_TAG);
        return true
    }
    return false
}

export const getPrinterConnection = (text, setNotification, history) => {

    if (PRINTER.PRINTER_BAR_CODE_LENGTH === text.length) {
        connectDevice(text, setNotification, history)
    } else {
        processPrinterResponse(text, PRINTER.UNSUPPORTED_BARCODE, setNotification)
    }
}

export const processPrinterResponse = (text, response, fnc, history) => {

    switch (response) {
        case PRINTER.UNSUPPORTED_BARCODE:
            fnc({type: NOTIFICATION_TYPE.ERROR, message: <FormattedMessage id = {NOTIFICATION_STRING.ALERT_ERROR_INCORRECT_BARCODE_SCANNED}/>});
            return;
        case PRINTER.PRINTER_CANCEL:
            return ROUTING.STOW_CONVEYABLE_SELECTION;
        case PRINTER.PRINTER_CONNECTION_SUCCESS:
            fnc({type: NOTIFICATION_TYPE.SUCCESS, message: <FormattedMessage id={NOTIFICATION_STRING.ALERT_SUCCESS_PRINTER_CONNECTED} values={{printerId: text}}/>});
            history.goBack();
            //getSalTemplateForPrinter(dispatcher, PRINTER.PRINTER_TYPE_AVERY)
            return;
        case PRINTER.PRINTER_CONNECTION_ERROR:
            fnc({type: NOTIFICATION_TYPE.ERROR, message: <FormattedMessage id={NOTIFICATION_STRING.ALERT_ERROR_CONNECTION_FAILED}/>});
            return;
        case PRINTER.PRINTER_CONNECTION_ERROR_UNPAIRED_DEVICE:
            fnc({type: NOTIFICATION_TYPE.ERROR, message: <FormattedMessage id={NOTIFICATION_STRING.ALERT_ERROR_CONNECTION_FAILED_UNPAIRED_DEVICE}/>});
            return;
        case PRINTER.PRINTER_CONNECTION_ERROR_PRINTER_OFF:
            fnc({type: NOTIFICATION_TYPE.ERROR, message: <FormattedMessage id={NOTIFICATION_STRING.ALERT_ERROR_CONNECTION_FAILED_PRINTER_OFF}/>});
            return;
        default:
            return

    }
};

const connectDevice = (printerScannableId, setNotification, history) => {
    NativeMeshInteractor.connectAvery(printerScannableId).then((response)=>{
        processPrinterResponse(printerScannableId, handleChangePrinterResponse(response), setNotification, history);
    }).catch(()=>{
        processPrinterResponse(printerScannableId, PRINTER.PRINTER_CONNECTION_ERROR, setNotification, history);
    });
};

const handleChangePrinterResponse = (response) => {
    if(!response) {
        return PRINTER.PRINTER_CONNECTION_SUCCESS;
    }
    switch (response) {
        case ChangePrinterResponse.CONNECTION_FAILED_PRINTER_OFF:
            return PRINTER.PRINTER_CONNECTION_ERROR_PRINTER_OFF;
        case ChangePrinterResponse.CONNECTION_FAILED_UNPAIRED_DEVICE:
            return PRINTER.PRINTER_CONNECTION_ERROR_UNPAIRED_DEVICE;
        default:
            return PRINTER.PRINTER_CONNECTION_ERROR;
    }
};

export const handleSALPrintingForConPackageType = (salContent) => {
    if (printSAL(salContent)) {
        //success
    }
    //error
}

const printSAL = (salContent) => {
    if (null != salContent) {
        try {
            NativeMeshInteractor.printAveryLabel(getPrintInput(salContent), AVERY_SAL_RESOURCE_LABEL_TAG);
            return true
        } catch (e) {
            Logger.log.error("Avery print error ", e);
            return false
        }
    }
};


export function getPrintInput(averyPrinterObj) {
    let printInput = '';
    for (const key in averyPrinterObj) {
        if (printInput === '')
            printInput += averyPrinterObj[key];
        else
            printInput += `|${averyPrinterObj[key]}`;
    }
    return printInput;
}