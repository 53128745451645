/**
 * Routes for redirecting module and handling callback.
 * @type {{STOW: {PREPARE_CART: string}, STAGE: {SCAN_STAGING_AREA: string}}}
 */
export const REDIRECT_HASH = {
    STOW: {
        PREPARE_STOW_LOCATION: "#/prepare-stow-location"
    },
    STAGE: {
        SCAN_STAGING_AREA: "#/scanStagingArea"
    }
};