import React, {Component} from "react";
import {SnapshotView} from "@amzn/dolphin-uiweb-framework";
import Box from "@amzn/meridian/box";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import ShelfImage31 from "../../../images/shelf/ic_shelf_floor3_1.png";
import ShelfImage32 from "../../../images/shelf/ic_shelf_floor3_2.png";
import ShelfImage33 from "../../../images/shelf/ic_shelf_floor3_3.png";
import ShelfImage41 from "../../../images/shelf/ic_shelf_floor4_1.png";
import ShelfImage42 from "../../../images/shelf/ic_shelf_floor4_2.png";
import ShelfImage43 from "../../../images/shelf/ic_shelf_floor4_3.png";
import ShelfImage44 from "../../../images/shelf/ic_shelf_floor4_4.png";

class DetailsCardWithShelfImageView extends Component {

    createSnapshots = (snapshots, headerFontSize) => {
        return snapshots.map((currentSnapshot, index) =>
            <SnapshotView key={index} title={currentSnapshot.title} description={currentSnapshot.description}
                          headerFontSize={headerFontSize}/>
        )
    }

    setDisplay = (snapshots, display, displayShelfImage) => {
        switch(display) {
            case "Row" : return (
                <Row width="100%" alignmentHorizontal="justify">
                    <Row>
                        {snapshots}
                    </Row>
                    <Column>
                        <img src={displayShelfImage} alt = ""/>
                    </Column>
                </Row>);
            case "Column": return (<Column>{snapshots}</Column>);
            default: return snapshots;
        }
    }

    isRackValid = (isOVPackage, rackSize, stowCoordinates) => {

        if (isOVPackage || rackSize.verticalLimit < 0 || stowCoordinates.verticalLimit < 0 ) {
            return false;
        }
        return true;
    }

    getDisplayShelfImage = (shelfImageData) => {
        const {isOVPackage, rackSize, stowCoordinates} = shelfImageData
        if (this.isRackValid(isOVPackage, rackSize, stowCoordinates)) {
            return this.shelfImage(rackSize.verticalLimit, stowCoordinates.verticalLimit)
        }
        return null;
    }

    shelfImage = (rackSize, rackNumber) =>  {

        if (rackSize === 3) {
            if (rackNumber === 1) {
                return ShelfImage31
            }
            if (rackNumber === 2) {
                return ShelfImage32
            }
            if (rackNumber === 3) {
                return ShelfImage33
            }
        }
        if (rackSize === 4) {
            if (rackNumber === 1) {
                return ShelfImage41
            }
            if (rackNumber === 2) {
                return ShelfImage42
            }
            if (rackNumber === 3) {
                return ShelfImage43
            }
            if (rackNumber === 4) {
                return ShelfImage44
            }
        }
        return null;
    }

    render() {
        const { backgroundColor, headerFontSize, snapshots, display, shelfImageData } = this.props
        const dataSnapshots = this.createSnapshots(snapshots, headerFontSize)
        const displayShelfImage = this.getDisplayShelfImage(shelfImageData)
        const displayData = this.setDisplay(dataSnapshots, display, displayShelfImage)
        return (
            <div>
                <Box type="outline">
                    <Box backgroundColor={backgroundColor} spacingInset="small">
                        {displayData}
                    </Box>
                </Box>
            </div>
        )
    }
}

export default DetailsCardWithShelfImageView;