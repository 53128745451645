export const ApplicationActions = {
    TASK_SELECTION: "TASK_SELECTION",
    BACK_SELECTED: "BACK_SELECTED",
    BACK_OPTION_SELECTED: "BACK_OPTION_SELECTED",
    HELP_SELECTED: "HELP_SELECTED",
    HELP_CLOSED: "HELP_CLOSED",
    HELP_OPTION_SELECTED: "HELP_OPTION_SELECTED",
    CONTAINER_TASK_SELECTION: "CONTAINER_TASK_SELECTION",
    CONTAINER_SELECTION: "CONTAINER_SELECTION",
    CONTAINER_SCAN: "CONTAINER_SCAN",
    CONTAINER_LOCATION_SCAN: "CONTAINER_LOCATION_SCAN",
    REPLACE_CART: "REPLACE_CART",
    CONTAINER_CART_LOCATION_SCAN: "CONTAINER_CART_LOCATION_SCAN",
    CONTAINER_CART_SCAN: "CONTAINER_CART_SCAN",
    CONTAINER_SCRUB_OPTION_SELECTION: "CONTAINER_SCRUB_OPTION_SELECTION",
    CONTAINER_SCRUB_BAG_CONFIRM_VIEW: "CONTAINER_SCRUB_BAG_CONFIRM_VIEW",
    CONTAINER_SCRUB_BAG_DECLINED: "CONTAINER_SCRUB_BAG_DECLINED",
    CONTAINER_SCRUB_BAG_ACCEPTED: "CONTAINER_SCRUB_BAG_ACCEPTED",
    CONTAINER_SCRUB_LOCATION_CONFIRM_VIEW: "CONTAINER_SCRUB_LOCATION_CONFIRM_VIEW",
    PACKAGE_SCAN: "PACKAGE_SCAN",
    PACKAGE_RESCAN: "PACKAGE_RESCAN",
    PACKAGE_LOCATION_SCAN: "PACKAGE_LOCATION_SCAN",
    PROBLEM_SOLVE_OPTION_SELECT: "PROBLEM_SOLVE_OPTION_SELECT",
    PROBLEM_SOLVE_SCAN: "PROBLEM_SOLVE_SCAN",
    MOVE_TO_BUFFER: "MOVE_TO_BUFFER",
    SCRUB: "SCRUB",
    SCRUB_CONTAINER_OR_LOCATION: "SCRUB_CONTAINER_OR_LOCATION",
    SCRUB_LOCATION_CONFIRM_VIEW: "SCRUB_LOCATION_CONFIRM_VIEW",
    SCRUB_CONTAINER_CONFIRM_VIEW: "SCRUB_CONTAINER_CONFIRM_VIEW"
};
