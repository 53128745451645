import React, {createContext, useReducer} from 'react'
import StowSelectionReducer from "../reducer/StowSelectionReducer";

export const StowSelectionContext = createContext()

export const model = {
}

const StowSelectionContextProvider = (props) => {

    const [stowSelectionModel, stowSelectionDispatch] = useReducer(StowSelectionReducer, model)

    return (
        <StowSelectionContext.Provider value={{stowSelectionModel, stowSelectionDispatch}}>
            {props.children}
        </StowSelectionContext.Provider>
    )
}

export default StowSelectionContextProvider