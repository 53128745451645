import React, {useContext} from 'react';
import {DetailsCardView} from "@amzn/dolphin-uiweb-framework";
import Column from "@amzn/meridian/column";
import {ConfigContext} from "../../../../../config/context/ConfigContext";

const LocationView = () => {

    const {configViewModel} = useContext(ConfigContext)

    const data = [
        {
            description: "Aisle",
            title: configViewModel.aisle
        }, {
            description: "Shelf",
            title: configViewModel.rack
        }
    ]

    return <Column spacingInset="medium">
        <DetailsCardView snapshots={data} headerFontSize="Large" display={"Row"}/>
    </Column>
}

export default LocationView
