import React, {useContext, useEffect} from 'react';
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import BarCode from "../../../../../images/ic_package_scan.png";
import {ConfigContext} from "../../../../config/context/ConfigContext";
import {StowPackageContext} from "../../../context/StowPackageContext";
import {AppNavigationContext} from "../../../../app-navigation/context/AppNavigationContext";
import {OPERATIONAL_MODE} from "../../../../../constants/Constants";
import {WORKFLOW} from "../../../../workflow/Workflow";
import {SCAN_PACKAGE_VIEW_STRINGS} from "../../../../../constants/Strings";
import ScanView from "../../../../scan-view/ScanView";
import Column from "@amzn/meridian/column";
import {injectIntl} from "react-intl";
import {processStow} from "../../../action/StowPackageAction";
import {getHelpData} from "../../../view/scan-package/data/StowScanPackageViewData";
import {getFormattedString} from "../../../../utils/Util";

const StowScanPackageFlowView = (props) => {

    const {configViewModel} = useContext(ConfigContext)
    const {navActions: {setHelpOption, closeHelpOption, openCustomHelpOption}} = useContext(AppNavigationContext)
    const {stowPackageModel, stowPackageDispatch} = useContext(StowPackageContext);


    const getPackageDestinationInfo = (scannedInput) => {
        return {
            packageTrackingId: scannedInput,
            scannedDestination: stowPackageModel.destinationLabel,
            operationalMode: OPERATIONAL_MODE.FLOW_STOW_TO_ROUTE,
            scope: configViewModel.scope
        }
    }

    const onScan = (input) => {
        processStow(stowPackageDispatch, getPackageDestinationInfo(input), WORKFLOW.STOW_FLOW, configViewModel.sblEndpoint)
    }

    useEffect(() => {
        setHelpOption(true, getHelpData(configViewModel.scope, props, closeHelpOption, openCustomHelpOption))
        return () => setHelpOption(true, {list: []})
    }, []);

    const init_default = {
        primaryTitle: {
            title: getFormattedString(props, SCAN_PACKAGE_VIEW_STRINGS.scan_package),
            size: "Large",
            type: "Bold"
        },
        image: BarCode
    }

    const renderView = () => {
        return <ScanWithCodeView {...init_default}/>
    }

    return (
        <Column spacingInset="medium" alignmentHorizontal="center">
            {renderView()}
            <ScanView onScan={onScan}/>
        </Column>
    )
}

export default injectIntl(StowScanPackageFlowView)
