import React, {createContext, useReducer} from 'react'
import {ScrubContainerReducer} from "../reducer/ScrubContainerReducer";

export const ScrubContainerContext = createContext();

const ScrubContainerContextProvider = (props) => {

    const model = {
        containerScannableId: '',
        destinationScannableId: '',
        containerType: '',
        responseCode: '',
        containerLabel: '',
        destinationLabel: '',
        node: '',
        scope: '',
        view: ''
    }

    const [scrubContainerViewModel, scrubContainerDispatch] = useReducer(ScrubContainerReducer, model)
    return (
        <ScrubContainerContext.Provider value={{
            scrubContainerViewModel: scrubContainerViewModel,
            scrubContainerDispatch: scrubContainerDispatch
        }}>
            {props.children}
        </ScrubContainerContext.Provider>
    )
}

export default ScrubContainerContextProvider
