import {model} from "../context/NotificationContext";

export const SET_NOTIFICATION = "SET_NOTIFICATION"
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION"

export const NotificationReducer = (state, action) => {
    switch (action.type) {
        case SET_NOTIFICATION:
            return {...state, ...action.data}
        case CLEAR_NOTIFICATION:
            return {...model}
        default:
            return state;
    }
}
