import React, {Component} from 'react';
import ScrubTaskView from "./view/ScrubTaskView";

class ScrubTask extends Component {

    render() {

        return (
            <div>
                <ScrubTaskView history={this.props.history}/>
            </div>
        )
    }
}

export default ScrubTask
