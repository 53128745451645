import React, {createContext, useReducer} from 'react'
import {PrepareContainerReducer} from "../reducer/PrepareContainerReducer";
import {VIEW} from "../../../workflow/Workflow";

export const PrepareContainerContext = createContext();

export const model = {
    view: VIEW.SCAN_BAG,
    activeBinIndex: 0,
    binList: null,
    activeBin: null,
    activeCartId: '',
    activeBagId: ''
}

const PrepareContainerContextProvider = (props) => {

    const [prepareContainerModel, prepareContainerDispatch] = useReducer(PrepareContainerReducer, model)
    return (
        <PrepareContainerContext.Provider value={{prepareContainerModel, prepareContainerDispatch}}>
            {props.children}
        </PrepareContainerContext.Provider>
    )
}

export default PrepareContainerContextProvider
