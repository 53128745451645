import React, {Component} from 'react';
import LandingView from "./view/LandingView";

class Landing extends Component {

    render() {
        return (
            <div>
                <LandingView history = {this.props.history}/>
            </div>
        )
    }
}

export default Landing
