import {TEST_RESPONSE_200} from "../../../tst/components/problem-solve/test-data/ProblemSolveTestData";
import {dev_debug, NO_INTERNET_RESPONSE, UNKNOWN_ERROR_OCCURRED_RESPONSE} from "../../utils/Util";
import {API_PATH, axiosPOST} from "../../utils/network/NetworkUtils";
import {AnalyticEventKeys, Constants, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";

export const moveToProblemSolve = async (requestBO) => {
    const startTime = Date.now();
    const request = {
        scannableId: requestBO.packageId,
        destinationScannableId: requestBO.destinationScannableId,
        problemCode: requestBO.problemSolveReasonCode,
        scope: requestBO.scope
    }

    try {
        const response = dev_debug === true
            ? TEST_RESPONSE_200()
            : await axiosPOST(AnalyticEventKeys.Modules.MOVE_TO_PROBLEM_SOLVE, API_PATH.MOVE_TO_PROBLEM_SOLVE, request);
        if (response.status === 200) {
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.MOVE_TO_PROBLEM_SOLVE, startTime);
            return response.data
        }
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_SORT_INFO_FOR_PACKAGE, startTime, true);
    } catch(error) {
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_SORT_INFO_FOR_PACKAGE, startTime, true);
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE
        }
        return UNKNOWN_ERROR_OCCURRED_RESPONSE

    }
    MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.MOVE_TO_PROBLEM_SOLVE, startTime, true);
}
