import React, {useContext, useEffect} from 'react';
import {AppNavigationContext} from "../../../../app-navigation/context/AppNavigationContext";
import Column from "@amzn/meridian/column";
import ScanView from "../../../../scan-view/ScanView";
import {HELP_CENTER_STRINGS, SCAN_PALLET_LOCATION_VIEW_STRINGS} from "../../../../../constants/Strings";
import {PrepareContainerContext} from "../../context/PrepareContainerContext";
import {attachContainerToDestination} from "../../action/PrepareContainerAction";
import {CONTAINER_TYPE} from "../../../../../constants/ContainerType";
import ScanQrCodeView from "../../../../scan-qr-code-view/ScanQrCodeView";
import {COMMON_ACTIONS} from "../../../../../constants/ActionType";
import {getHelpOptions, getSelectContainerRoutingPath} from "../../../../utils/Util";
import {STOW_AREA_OPERATION} from "../../../../../constants/StowAreaOperation";
import {FormattedMessage} from "react-intl";
import {ApplicationActions} from "../../../../player/action/ApplicationActions";
import {PlayerContext} from "../../../../player/context/PlayerContext";
import {ScanTypes} from "../../../../player/PlayerConstant";

const ScanPalletLocationView = (props) => {

    const {prepareContainerDispatch} = useContext(PrepareContainerContext);
    const {navActions: {setHelpOption, closeHelpOption}} = useContext(AppNavigationContext)
    const {statePlayer, dispatchPlayer} = useContext(PlayerContext);

    const onScan = (input) => {
        const request = {
            destinationScannableId: input,
            containerScannableType: CONTAINER_TYPE.PALLET
        }
        prepareContainerDispatch({type: COMMON_ACTIONS.LOADING})
        statePlayer.scanType = ScanTypes.PREP_PALLET_SCAN
        attachContainerToDestination(prepareContainerDispatch, request)
    }

    const init = {
        primaryTitle: SCAN_PALLET_LOCATION_VIEW_STRINGS.scan_pallet_location_guidance,
        secondaryTitle: SCAN_PALLET_LOCATION_VIEW_STRINGS.scan_pallet_location
    }

    useEffect(() => {
        const callbacks = [
            () => {
                dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP}});
                props.history.push(getSelectContainerRoutingPath(STOW_AREA_OPERATION.PREPARE_CONTAINER))
            },
            closeHelpOption
        ];
        setHelpOption(true, getHelpOptions(callbacks,
            <FormattedMessage id={HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP}/>,
            <FormattedMessage id={HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP_TEXT}/>));
        return () => setHelpOption(false, {});
    }, []);

    return (
        <Column spacingInset="medium" alignmentHorizontal="center">
            <ScanQrCodeView {...init}/>
            <ScanView onScan={onScan}/>
        </Column>
    )
}

export default ScanPalletLocationView
