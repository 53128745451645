import {getProblemSolveResource} from "../resource/ProblemSolveResource";
import {MOVE_TO_PROBLEM_SOLVE_RESPONSE} from "../reducer/ProblemSolveReducer";

const moveToProblemSolveResponse = (data, workflow) => {
    return {
        type: MOVE_TO_PROBLEM_SOLVE_RESPONSE,
        data : {
            ...data,
            workflow: workflow
        }
    }
}

export const moveToProblemSolve = (dispatcher, request, workflow) => {
    getProblemSolveResource(request).then(response => {
        dispatcher(moveToProblemSolveResponse(response, workflow))
    }).catch(() => {
    })
}
