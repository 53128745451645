import PollerInstance from "./Poller";
import {API_PATH, axiosPOST} from "../components/utils/network/NetworkUtils";
import {AnalyticEventKeys} from "@amzn/dolphin-web-framework";

/**
 * Poller that polls for fetching status of
 * carts ready for staging.
 *
 */
class CartStageReadinessPoller {

    constructor() {
        this.poller = PollerInstance;
        this.cartList = {};
        this.CART_STATUS = {READY: "READY", IN_PROGRESS: "IN_PROGRESS"}
    }

    /**
     * Adds a new cart to polling list
     * @param cartId
     * @param stationPair
     * @param location
     */
    addCart(cartId, stationPair, location) {
        this.cartList[cartId] = {stationPair: stationPair, location: location};
    }

    /**
     * Returns list of carts being polled
     * @returns {{}}
     */
    getCartList() {
        return this.cartList;
    }

    /**
     * Sets initial cartList
     * @param cartList
     */
    initializeCartList(cartList) {
        this.cartList = cartList;
    }

    /**
     * Removes a cartId from being polled
     * @param cartId
     */
    removeCart(cartId) {
        if(this.cartList && this.cartList[cartId]) {
            delete this.cartList[cartId];
        }
    }

    /**
     * Deletes all carts from being polled
     */
    resetCartList() {
        this.cartList = {};
    }

    /**
     * Starts poller
     * @param callbackWhenCartReady
     * @param interval
     */
    startPolling(callbackWhenCartReady, interval) {
        this.poller.startPolling(() => this.cartReadinessChecker(callbackWhenCartReady), interval);
    }

    /**
     * Stops the poller
     */
    stopPolling() {
        this.poller.stopPolling();
    }

    /**
     * Pauses the poller
     */
    pausePolling() {
        this.poller.pausePolling();
    }

    /**
     * Resumes the polling
     */
    resumePolling() {
        this.poller.resumePolling();
    }

    /**
     * Checks for cart stage readiness by initiating a network call to SPS
     * and gives callback when a cart is ready for hotswap
     * @param cartReadyCallback
     */
    /* eslint-disable */
    async cartReadinessChecker(cartReadyCallback) {
        let callBackSent = false;
        if(this.cartList && Object.keys(this.cartList).length !== 0) {
            let cartIdsArray = Object.keys(this.cartList);
            while(cartIdsArray.length) {
                const params = {
                    itemIds: cartIdsArray.splice(0,5)
                };
                await axiosPOST(AnalyticEventKeys.Modules.BATCH_GET_ITEM_STAGE_INFO, API_PATH.BATCH_GET_ITEM_STAGE_INFO, params)
                    .then((result) => {
                        Object.keys(result.data.successfulItems).some(cart => {
                            const {routeId, status} = result.data.successfulItems[cart];
                            if(status === this.CART_STATUS.READY) {
                                cartReadyCallback(cart, this.cartList[cart].stationPair, this.cartList[cart].location, routeId);
                                callBackSent = true;
                                return true;
                            } else if (status === this.CART_STATUS.IN_PROGRESS) {
                                delete this.cartList[cart];
                            }
                            return false;
                        });
                    });
                if(callBackSent === true) {
                    break;
                }
            }
        }
    }
}

const CartStageReadinessPollerInstance = new CartStageReadinessPoller();
export default CartStageReadinessPollerInstance;