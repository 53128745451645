import {SLASH} from "../components/utils/Util";

export const ROUTING = {
    ENTRY: "/",
    MANAGE_STOW_AREA: "/manage-stow-area",
    STOW_PACKAGE: "/stow-package",
    STOW_PACKAGE_WITH_PROPS: "/stow-package/:flow",
    SELECT_CONTAINER: "/select-container",
    PROBLEM_SOLVE: "/problem-solve",
    MOVE_TO_BUFFER: "/move-to-buffer",
    PREPARE_CONTAINER: "/prepare-container",
    CLOSE_CONTAINER: "/close-container",
    SCRUB_SELECT_TASK: "/scrub-task",
    SCRUB_CONTAINER: "/scrub-container",
    SCRUB_LOCATION: "/scrub-location",
    REPLACE_CONTAINER: "/replace-container",
    STOW_PROGRESS: "/stow-progress",
    STOW_FLOW_MODE: "/stow-flow-mode",
    PREPARE_CART: "/prepare-cart",
    CART_HOT_SWAP: "/cart-hot-swap",
    PREPARE_STOW_LOCATION: "/prepare-stow-location",
    STOW_CONVEYABLE_SELECTION: "/stow-conveyable-selection",
    STOW_CON_PACKAGE: "/stow-conveyable-selection/con-package",
    PREPARE_ROUTE: "/prepare-route",
    NO_INTERNET: "/no-internet"
}

export const getContainerRoutingPath = (path, operation) => {
    return path + SLASH + operation
}