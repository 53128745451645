import {RESPONSE} from "../../../../../../constants/ResponseCodes";
import {TEST_CONSTANTS} from "../../../../test-data/TestConstants";

export const TEST_RESPONSE_200 = () => {
    return {
        status: 200,
        data: TEST_SUCCESS_RESPONSE
    }
}

export const TEST_SUCCESS_RESPONSE = {
    responseCode: RESPONSE.INVALID_SCAN_DONE,
    bagLabel: TEST_CONSTANTS.BAG_LABEL,
    destinationLabel: TEST_CONSTANTS.ROUTE_CODE,
    packageLabel: TEST_CONSTANTS.FORWARD_TRACKING_ID
}
