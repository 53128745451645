import {
    GET_SORT_INFO_FOR_PACKAGE_RESPONSE,
    PROCESS_STOW_RESPONSE,
    VALIDATE_LOCATION_BEFORE_STOW
} from "../reducer/StowPackageReducer";
import {
    getStowScanLocationResource, getStowScanPackageFlowResource,
    getStowScanPackageResource,
    getValidateLocationBeforeStowResource,
    getStowSblSourceScanResource, getStowSblDestinationScanResource
} from "../resource/StowPackageResource";
import {WORKFLOW} from "../../workflow/Workflow";

const getSortInfoForPackageResponse = (data, workflow, expectedPackageId) => {
    return {
        type: GET_SORT_INFO_FOR_PACKAGE_RESPONSE,
        data: {
            ...data,
            workflow: workflow,
            expectedPackageId: expectedPackageId
        }
    }
}

const processStowResponse = (data, workflow) => {
    return {
        type: PROCESS_STOW_RESPONSE,
        data: {
            ...data,
            workflow: workflow
        }
    }
}

const getValidateLocationBeforeStowResponse = (data, workflow) => {
    return {
        type: VALIDATE_LOCATION_BEFORE_STOW,
        data: {
            ...data,
            workflow: workflow
        }
    }
}

export const getSortInfoForPackage = (dispatcher, packageScannableId, scope, workflow, expectedPackageId, sblEndpoint, shouldNotifySbl, statePlayer) => {
    if(shouldNotifySbl) {
        getStowSblSourceScanResource(sblEndpoint, packageScannableId)
    }
    getStowScanPackageResource(packageScannableId, scope).then(response => {
        if(statePlayer){
            statePlayer.isValidPackageReScan = (expectedPackageId === response.packageTrackingId);
        }
        dispatcher(getSortInfoForPackageResponse(response, workflow, expectedPackageId))
    }).catch(() => {
    })
}

export const processStow = (dispatcher, packageDestinationInfo, workflow, sblEndpoint) => {
    getStowSblDestinationScanResource(sblEndpoint, packageDestinationInfo.packageTrackingId,
        packageDestinationInfo.scannedDestination)
    if (WORKFLOW.STOW_FLOW === workflow) {
        getStowScanPackageFlowResource(packageDestinationInfo).then(response => {
            dispatcher(processStowResponse(response, workflow))
        }).catch(() => {
        })
    }
     else {
         getStowScanLocationResource(packageDestinationInfo).then(response => {
            dispatcher(processStowResponse(response, workflow))
        }).catch(() => {
        })
    }
}

export const getValidateLocationBeforeStow = (dispatcher, packageScannableId, scope, workflow) => {
    getValidateLocationBeforeStowResource(packageScannableId, scope).then(response => {
        if(!response.targetSortLocation) {
            workflow = null
        }
        dispatcher(getValidateLocationBeforeStowResponse(response, workflow))
    }).catch(() => {
    })
}
