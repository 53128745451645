import React, {useContext, useEffect} from 'react'
import {FormattedMessage, injectIntl} from "react-intl";
import {CONTAINER_TYPE} from "../../../../constants/ContainerType";
import {COMMON_STRINGS, HELP_CENTER_STRINGS, SCAN_PALLET_LOCATION_VIEW_STRINGS} from "../../../../constants/Strings";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {CloseContainerContext} from "../context/CloseContainerContext";
import {getHelpOptions, getSelectContainerRoutingPath} from "../../../utils/Util";
import Column from "@amzn/meridian/column";
import ScanView from "../../../scan-view/ScanView";
import {closeContainer} from "../action/CloseContainerAction";
import {STOW_AREA_OPERATION} from "../../../../constants/StowAreaOperation";
import ScanQrCodeView, {HEADING_TYPE} from "../../../scan-qr-code-view/ScanQrCodeView";
import {PlayerContext} from "../../../player/context/PlayerContext";
import {ApplicationActions} from "../../../player/action/ApplicationActions";
import {ScanTypes} from "../../../player/PlayerConstant";

const CloseDefaultContainerView = (props) => {

    const {navActions: {setHelpOption, closeHelpOption}} = useContext(AppNavigationContext)
    const {closeContainerDispatch} = useContext(CloseContainerContext)
    const {statePlayer, dispatchPlayer} = useContext(PlayerContext);

    const onScan = (input) => {
        const request = {
            containerScannable: input,
            containerScannableType: props.containerScannableType
        }
        statePlayer.scanType = (props.containerScannableType === CONTAINER_TYPE.CART) ? ScanTypes.CLOSE_CART_SCAN: ScanTypes.CLOSE_PALLET_SCAN
        closeContainer(closeContainerDispatch, request)
    }

    useEffect(() => {
        const callbacks = [
            () => {
                dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HELP_CENTER_STRINGS.CLOSE_CONTAINER_HELP}});
                props.history.push(getSelectContainerRoutingPath(STOW_AREA_OPERATION.CLOSE_CONTAINER))
            },
            closeHelpOption
        ]
        setHelpOption(true, getHelpOptions(callbacks,
            <FormattedMessage id={HELP_CENTER_STRINGS.CLOSE_CONTAINER_HELP}/>,
            <FormattedMessage id={HELP_CENTER_STRINGS.CLOSE_CONTAINER_HELP_TEXT}/>));
        return () => setHelpOption(false, {});
    }, [])

    const getTitle = () => {
        switch (props.containerScannableType) {
            case CONTAINER_TYPE.PALLET:
                return SCAN_PALLET_LOCATION_VIEW_STRINGS.scan_pallet_location
            case CONTAINER_TYPE.CART:
                return COMMON_STRINGS.SCAN_CART
            default:
                return
        }
    }

    const init = {
        primaryTitle: getTitle(),
        secondaryTitle: COMMON_STRINGS.SCAN_CONTAINER_OR_LOCATION
    }

    return (
        <Column spacingInset="medium" alignmentHorizontal="center">
            <ScanQrCodeView {...init} type={HEADING_TYPE.PRIMARY}/>
            <ScanView onScan={onScan}/>
        </Column>
    )
}

export default injectIntl(CloseDefaultContainerView)
