import * as StowScanLocationTestData
    from "../../../tst/components/stow/view/scan-location/test-data/StowScanLocationTestData";
import * as StowScanPackageTestData
    from "../../../tst/components/stow/view/scan-package/test-data/StowScanPackageTestData";
import {dev_debug, NO_INTERNET_RESPONSE, UNKNOWN_ERROR_OCCURRED_RESPONSE} from "../../utils/Util";
import {API_PATH, axiosGET, axiosPOST, publishErrorMetric} from "../../utils/network/NetworkUtils";
import {TEST_RESPONSE_200} from "../../../tst/components/stow/flow-mode/view/scan-location-flow/StowScanLocationFlowTestData";
import {
    AnalyticEventKeys,
    Constants,
    Logger,
    MobileAnalyticsHelper,
    NativeMeshInteractor
} from "@amzn/dolphin-web-framework";

export const getSortInfoForPackage = async (packageScannableId, scope) => {
    const startTime = Date.now();
    const params = {
        params: {
            scannableId: packageScannableId,
            // TODO: Do we need scope ?
            scope: scope
        }
    };

    try {
        const response = dev_debug === true
            ? StowScanPackageTestData.TEST_RESPONSE_200(scope)
            : await axiosGET(AnalyticEventKeys.Modules.GET_SORT_INFO_FOR_PACKAGE, API_PATH.GET_SORT_INFO_FOR_PACKAGE, params);

        if (response.status === 200) {
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_SORT_INFO_FOR_PACKAGE, startTime);
            return response.data
        }
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_SORT_INFO_FOR_PACKAGE, startTime, true);
    } catch (error) {
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_SORT_INFO_FOR_PACKAGE, startTime, true);
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE
        }
        return UNKNOWN_ERROR_OCCURRED_RESPONSE
    }
}

export const sblSourceScan = (sblEndpoint, packageScannableId) => {
    const startTime = Date.now();
    Logger.log.info("sblSourceScan request"+ packageScannableId);
    try {
        NativeMeshInteractor.sblSourceScan(packageScannableId, "PACKAGE");
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.SBL_SOURCE_SCAN, startTime);
        return true;
    } catch(error) {
        Logger.log.info("sblSourceScan error response"+JSON.stringify(error));
        publishErrorMetric(AnalyticEventKeys.Modules.SBL_SOURCE_SCAN, startTime, error)
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.SBL_SOURCE_SCAN, startTime, true)
        return false;
    }
};

export const sblDestinationScan = (sblEndpoint, packageScannableId, destinationScannableId) => {
    const startTime = Date.now();
    Logger.log.info("sblDestinationScan request" + destinationScannableId + " " + packageScannableId);
    try {
        NativeMeshInteractor.sblDestinationScan(destinationScannableId, packageScannableId, "LOCATION");
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.SBL_DESTINATION_SCAN, startTime);
        return true;
    } catch(error) {
        Logger.log.info("sblDestinationScan error response"+JSON.stringify(error));
        publishErrorMetric(AnalyticEventKeys.Modules.SBL_DESTINATION_SCAN, startTime, error)
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.SBL_DESTINATION_SCAN, startTime, true)
        return false;
    }
};

export const processStow = async (packageDestinationInfo) => {
    const startTime = Date.now();
    const request = {
        packageScannableId: packageDestinationInfo.packageTrackingId,
        destinationScannableId: packageDestinationInfo.scannedDestination,
        destinationAttributes: {
            correctLocationScanned: packageDestinationInfo.correctLocationScanned
        },
        packageInfo: packageDestinationInfo.packageInfo,
        // TODO: Fix this field, make it more specific, will require backend changes
        getSortInfoForPackageOutput: packageDestinationInfo.getSortInfoForPackageOutput,
        scope: packageDestinationInfo.scope,
        operationalContext: {
            operationalMode: packageDestinationInfo.operationalMode
        },
        // TODO: Do we need this?
    }
    try {
        const response = dev_debug === true
            ? StowScanLocationTestData.TEST_RESPONSE_200()
            : await axiosPOST(AnalyticEventKeys.Modules.PROCESS_STOW, API_PATH.PROCESS_STOW, request);
        if (response.status === 200) {
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.PROCESS_STOW, startTime);
            MobileAnalyticsHelper.processAnalytics(startTime, response.responseCode, response,
                AnalyticEventKeys.Modules.STOW, packageDestinationInfo.packageTrackingId, response.destinationLabel,
                AnalyticEventKeys.Events.USER_SCANNED_PACKAGE, false, AnalyticEventKeys.Modules.PACKAGE_STOW)
            return response.data
        }
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.PROCESS_STOW, startTime, true);
        MobileAnalyticsHelper.processAnalytics(startTime, response.responseCode, response,
            AnalyticEventKeys.Modules.STOW, packageDestinationInfo.packageTrackingId, response.destinationLabel,
            AnalyticEventKeys.Events.USER_SCANNED_PACKAGE, true, AnalyticEventKeys.Modules.PACKAGE_STOW)
    } catch (error) {
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.PROCESS_STOW, startTime, true);
        MobileAnalyticsHelper.processAnalytics(startTime, "", "",
            AnalyticEventKeys.Modules.STOW, packageDestinationInfo.packageTrackingId, "",
            AnalyticEventKeys.Events.USER_SCANNED_PACKAGE, true, AnalyticEventKeys.Modules.PACKAGE_STOW)
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE
        }
        return UNKNOWN_ERROR_OCCURRED_RESPONSE
    }
}

export const getValidateLocationBeforeStow = async (packageScannableId) => {
    const startTime = Date.now();
    const request = {
        scannableId: packageScannableId,
    };
    try {
        const response = dev_debug === true
            ? TEST_RESPONSE_200()
            : await axiosPOST(AnalyticEventKeys.Modules.VALIDATE_LOCATION_BEFORE_STOW,
                API_PATH.VALIDATE_LOCATION_BEFORE_STOW, request);

        if (response.status === 200) {
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.VALIDATE_LOCATION_BEFORE_STOW, startTime);
            return response.data
        }
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.VALIDATE_LOCATION_BEFORE_STOW, startTime, true);
    } catch (error) {
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.VALIDATE_LOCATION_BEFORE_STOW, startTime, true);
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE
        }
        return UNKNOWN_ERROR_OCCURRED_RESPONSE
    }
}