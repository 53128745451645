// Export Interceptors
import {Logger} from "@amzn/dolphin-web-framework";

export const sblInterceptorBuilder = (axiosInstance) => {
    return {
        onBeforeRequest,
        onRequestError,
        onResponseSuccess,
        onResponseError
    };
};

// Interceptors
const onBeforeRequest = async (config) => {
    const logConfig = (({method, url, headers, data, params}) => ({method, url, headers, data, params}))(config);
    Logger.log.info("SBL Interceptor Request", logConfig);
    return config;
};

const onRequestError = (error) => {
    Logger.log.error("SBL Interceptor Request Error", error);
    throw error;
};

const onResponseSuccess = async (response) => {
    Logger.log.info("SBL Response Success", response);
    return response;
};

const onResponseError = async (error) => {
    Logger.log.error("SBL Response Error", error);
    throw error;
};