import React, {useContext, useEffect} from 'react';
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import QrCode from "../../../../../images/ic_qr_code.png";
import {AR_VIEW_STRINGS} from "../../../../../constants/Strings";
import {FormattedMessage} from "react-intl";
import {NotificationContext} from "../../../../notification/context/NotificationContext";
import {CONTAINER_TYPE} from "../../../../../constants/ContainerType";
import {ConfigContext} from "../../../../config/context/ConfigContext";
import ScanView from "../../../../scan-view/ScanView";
import Column from "@amzn/meridian/column";
import {attachContainerToDestination} from "../../../prepare-container/action/PrepareContainerAction";
import {PrepareContainerContext} from "../../../prepare-container/context/PrepareContainerContext";
import {PlayerContext} from "../../../../player/context/PlayerContext";
import {ScanTypes} from "../../../../player/PlayerConstant";
import AppDataStore from "../../../../app-data";
import {SCOPE} from "../../../../../constants/Scope";
import {AppNavigationContext} from "../../../../app-navigation/context/AppNavigationContext";
import {getHelpData} from "../../../../cart-hot-swap/data/CartHotSwapViewData";

const ScanContainerLocationView = (props) => {

    const {prepareContainerModel, prepareContainerDispatch} = useContext(PrepareContainerContext)
    const {notificationActions: {clearNotification}} = useContext(NotificationContext)
    const {configViewModel} = useContext(ConfigContext)
    const {statePlayer} = useContext(PlayerContext);
    const {navActions: {setHelpOption, closeHelpOption}} = useContext(AppNavigationContext);

    const onScan = (input) => {
        const request = {
            workflow: configViewModel.workflow,
            containerScannableId: prepareContainerModel.activeCartId,
            destinationScannableId: input,
            containerScannableType: CONTAINER_TYPE.CART,
            scope: configViewModel.scope
        }
        statePlayer.scanType = ScanTypes.PREP_CART_LOCATION_SCAN
        attachContainerToDestination(prepareContainerDispatch, request)
    }

    const init = {
        primaryTitle: {
            title: <FormattedMessage id={AR_VIEW_STRINGS.confirm_cart_location}/>,
        },
        secondaryTitle: {
            title: <FormattedMessage id={AR_VIEW_STRINGS.scan_location}/>,
            type: "Bold"
        },
        image: QrCode,
        onScan: onScan
    }

    const renderView = () => {
        return <ScanWithCodeView {...init}/>
    }

    useEffect(() => {
        clearNotification();
        if (AppDataStore.isHotSwapInProgress() && (SCOPE.AR_NEXT_GEN === configViewModel.scope)) {
            setHelpOption(true, getHelpData(props, closeHelpOption));
        }
        return () => setHelpOption(false, {});
    }, []);

    return (
        <Column spacingInset="medium" alignmentHorizontal="center">
            {renderView()}
            <ScanView onScan={onScan}/>
        </Column>
    )
}

export default ScanContainerLocationView
