import {RESPONSE} from "../../../../../../constants/ResponseCodes";
import {VIEW} from "../../../../../workflow/Workflow";
import {NOTIFICATION_TYPE} from "../../../../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../../../../constants/Strings";
import {getNotificationFormat} from "../../../../../utils/Util";

export const getBagScanViewData = (input) => {

    const values = {scannedBagId: input.activeBagId}
    switch (input.responseCode) {

        case RESPONSE.SUCCESS:
            return {
                view: VIEW.BAG_SCAN_LOCATION_VIEW
            }

        case RESPONSE.INVALID_SCAN_DONE:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_INVALID_BAG_SCAN, values)
            }

        case RESPONSE.NON_EMPTY_BAG:
            return {
                view: VIEW.REPLACE_BAG
            }

        case RESPONSE.CART_ALREADY_ATTACHED:
        case RESPONSE.NON_EMPTY_CART:
        default:
            return {
                notification: {
                    type: NOTIFICATION_TYPE.ERROR,
                    message: COMMON_STRINGS.default_error,
                }
            }
    }

}