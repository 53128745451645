import {RESPONSE} from "../../../../../../constants/ResponseCodes";
import {BIN_STATUS} from "../../../../../../constants/BinStatus";
import {getNotificationFormat, getSelectContainerRoutingPath, isAttachCartWorkflow} from "../../../../../utils/Util";
import {NOTIFICATION_TYPE} from "../../../../../../constants/NotificationType";
import {COMMON_STRINGS, HELP_CENTER_STRINGS, NOTIFICATION_STRING} from "../../../../../../constants/Strings";
import {VIEW, WORKFLOW} from "../../../../../workflow/Workflow";
import {ROUTING} from "../../../../../../constants/Routing";
import {ApplicationActions} from "../../../../../player/action/ApplicationActions";
import {STOW_AREA_OPERATION} from "../../../../../../constants/StowAreaOperation";
import AppDataStore from "../../../../../app-data";
import {getHotSwapExitButton} from "../../../../../cart-hot-swap/data/CartHotSwapViewData";

export const getScanBagCartViewData = (input) => {
    const values = {
        scannedCartId: input.activeCartId,
        binListSize: input.binListSize,
        destinationLabel: input.existingLocationLabel
    }
    switch (input.responseCode) {
        case RESPONSE.SUCCESS:
            if (input.activeBinIndex < input.binListSize) {
                if (BIN_STATUS.BIN_WITH_PS_BAG === input.activeBin.binStatus) {
                    //todo: pending routing to ReplaceBag
                    return
                } else {
                    let notification
                    if (isAttachCartWorkflow(input.workflow) || WORKFLOW.ATTACH_EMPTY_CART_AT_STOWING === input.workflow) {
                        notification = getNotificationFormat(NOTIFICATION_TYPE.WARNING,
                            NOTIFICATION_STRING.ALERT_WARNING_CART_NOT_SETUP)
                    }
                    return {
                        view: VIEW.BAG_SCAN_VIEW,
                        notification: notification
                    }
                }
            } else {

                const notification = getNotificationFormat(NOTIFICATION_TYPE.SUCCESS,
                    NOTIFICATION_STRING.ALERT_SUCCESS_BINS_ALREADY_IN_CART, values)
                if (isAttachCartWorkflow(input.workflow) || WORKFLOW.ATTACH_EMPTY_CART_AT_STOWING === input.workflow) {
                    return {
                        view: VIEW.SCAN_CONTAINER_LOCATION,
                        notification: input.binListSize !== 0 ? notification : undefined
                    }
                }
                return {
                    notification: notification
                }
            }
        case RESPONSE.NO_INTERNET:
            return {
                nextRoute: ROUTING.NO_INTERNET
            }
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_INVALID_CART_SCAN, values)
            }

        case RESPONSE.NON_EMPTY_CART:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_CART_CANT_BE_USED, values)
            }

        case RESPONSE.CART_ALREADY_ATTACHED:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.WARNING,
                    NOTIFICATION_STRING.ALERT_WARNING_CART_ALREADY_ATTACHED, values)
            }

        case RESPONSE.NON_EMPTY_BAG:
        default:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    COMMON_STRINGS.default_error)
            }
    }
}

export const getHelpData = (props, dispatchPlayer, closeHelpOption) => {
    const list = [
        {
            title: props.intl.formatMessage({id: HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP_TEXT}),
            onClick: () => {
                closeHelpOption();
                dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP}});
                props.history.push(getSelectContainerRoutingPath(STOW_AREA_OPERATION.PREPARE_CONTAINER))
            }
        }
    ];
    if (AppDataStore.isHotSwapInProgress()) {
        list.push(getHotSwapExitButton(props, closeHelpOption));
    }
    return {
        pageTitle: HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP,
        list: list
    }
};
