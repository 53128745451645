import React, {createContext, useReducer} from 'react'
import {VIEW} from "../../workflow/Workflow";
import StowProgressReducer from "../reducer/StowProgressReducer";

export const StowProgressContext = createContext()

const model = {
    view: VIEW.STOW_PROGRESS_SCAN_LOCATION
}

const StowProgressContextProvider = (props) => {

    const [stowProgressModel, stowProgressDispatch] = useReducer(StowProgressReducer, model)
    return (
        <StowProgressContext.Provider value={{stowProgressModel, stowProgressDispatch}}>
            {props.children}
        </StowProgressContext.Provider>
    )
}

export default StowProgressContextProvider