import {RESPONSE} from "../../../../../constants/ResponseCodes";
import {getNotificationFormat} from "../../../../utils/Util";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../../../constants/Strings";
import {NOTIFICATION_TYPE} from "../../../../../constants/NotificationType";
import {ROUTING} from "../../../../../constants/Routing";

export const getCloseContainerViewData = (input) => {
    const values = {
        containerScannableType: input.containerScannableType,
        locationLabel: input.locationLabel
    }

    switch (input.responseCode) {
        case RESPONSE.SUCCESS:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.SUCCESS, NOTIFICATION_STRING.ALERT_SUCCESS_CONTAINER_CLOSED, values),
            }
        case RESPONSE.NO_INTERNET:
            return {
                nextRoute: ROUTING.NO_INTERNET
            }
        case RESPONSE.EMPTY_LOCATION:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_NO_ACTIVE_CONTAINER_AT_LOCATION, values)
            }
        case RESPONSE.CONTAINER_NOT_OPEN:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_NO_OPEN_CONTAINER, values)
            }
        case RESPONSE.TOO_MANY_CONTAINERS:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_TOO_MANY_CONTAINERS, values)
            }
        case RESPONSE.EMPTY_CONTAINER:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_EMPTY_CONTAINER, values)
            }
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, NOTIFICATION_STRING.ALERT_ERROR_INVALID_BARCODE_SCANNED)
            }
        default:
            return {
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR, COMMON_STRINGS.default_error)
            }
    }
}
