import React, {useContext} from 'react'
import Column from "@amzn/meridian/column";

import {injectIntl} from "react-intl";
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import {COMMON_STRINGS, STOW_PROGRESS_VIEW_STRINGS} from "../../../../constants/Strings";
import {getFormattedString} from "../../../utils/Util";
import ScanView from "../../../scan-view/ScanView";
import QrCode from "../../../../images/ic_qr_code.png";
import {getRoutesStowProgressForSortLocation} from "../../action/StowProgressAction";
import {StowProgressContext} from "../../context/StowProgressContext";

const ScanLocationForStowProgressView = (props) => {

    const {stowProgressDispatch} = useContext(StowProgressContext)

    const onScan = (input) => {
        getRoutesStowProgressForSortLocation(stowProgressDispatch, input)
    }

    const init = {
        primaryTitle: {
            title: getFormattedString(props, STOW_PROGRESS_VIEW_STRINGS.scan_location_desc)
        },
        secondaryTitle: {
            title: getFormattedString(props, COMMON_STRINGS.scan_location),
            type: "Bold"
        },
        image: QrCode,
        onScan: onScan
    }

    return (
        <Column spacingInset="medium" alignmentHorizontal="center">
            <ScanWithCodeView {...init} />
            <ScanView onScan={onScan}/>
        </Column>
    )
}

export default injectIntl(ScanLocationForStowProgressView)