import {getScrubContainerResource, validateContainerBeforeScrubResource} from "../resource/ScrubContainerResource";
import {SCRUB_CONTAINER_RESPONSE, VALIDATE_CONTAINER_BEFORE_SCRUB_RESPONSE} from "../reducer/ScrubContainerReducer";


const scrubContainerResponse = (data) => {
    return {
        type: SCRUB_CONTAINER_RESPONSE,
        data
    }
}

const validateContainerBeforeScrubResponse = (data) => {
    return {
        type: VALIDATE_CONTAINER_BEFORE_SCRUB_RESPONSE,
        data
    }
}

export const scrubContainerAction = (dispatcher, containerScannableId, containerType, destinationScannableId) => {
    getScrubContainerResource(containerScannableId, containerType, destinationScannableId).then(response => {
        dispatcher(scrubContainerResponse(response))
    })
}

export const validateContainerBeforeScrubAction = (dispatcher, containerScannableId) => {
    validateContainerBeforeScrubResource(containerScannableId).then(response => {
        dispatcher(validateContainerBeforeScrubResponse(response))
    })
}
