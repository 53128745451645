let prevBackPressedListener = undefined;
let interceptBackButtonReason = undefined;

window.addEventListener('popstate', function () {
    if (interceptBackButtonReason) {
        prevBackPressedListener && prevBackPressedListener();
        window.history.pushState({noBack: true}, '')
    }
});

export function setBackPressedEventListener(listener) {
    prevBackPressedListener = listener;
}

export function clearBackPressedEventListener() {
    prevBackPressedListener = undefined;
}

export function interceptBackButton(reason) {
    interceptBackButtonReason = reason;
    window.history.pushState({noBack: true}, '');
}

export function interceptBackButtonStop() {
    interceptBackButtonReason = undefined;
}