import React, {createContext, useReducer} from 'react'
import {CartHotSwapReducer} from "../reducer/CartHotSwapReducer";

export const CartHotSwapContext = createContext();

const model = {
};

const CartHotSwapContextProvider = (props) => {

    const [hotSwapModel, hotSwapDispatch] = useReducer(CartHotSwapReducer, model)
    return (
        <CartHotSwapContext.Provider value={{hotSwapModel, hotSwapDispatch}}>
            {props.children}
        </CartHotSwapContext.Provider>
    )
};

export default CartHotSwapContextProvider
