import React, {useEffect} from 'react';
import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import {injectIntl} from "react-intl";
import {getFormattedString} from "../../../utils/Util";
import {STOW_PROGRESS_VIEW_STRINGS} from "../../../../constants/Strings";
import Divider from "@amzn/meridian/divider";
import {Colors} from "../../../utils/Colors";
import {clearBackPressedEventListener, setBackPressedEventListener} from "../../../bridge/BackButton";

const TrackingIdsDialogView = (props) => {

    useEffect(() => {
        setBackPressedEventListener(() => {
            props.closeTrackingIdsDialog()
        });
        return clearBackPressedEventListener;
    })

    const onClick = (e) => {
        //prevents further bubbling up to parent elements of the current event.
        e.stopPropagation()
    }

    return (
        <div className="dialog" onClick={onClick}>
            <Box alignment="center">
                <Row backgroundColor={Colors.GREY_COL_300} spacingInset="small" spacing="none">
                    <Box width="100%">
                        <Text type="h200" alignment="center">
                            {props.modalData.title}
                        </Text>
                    </Box>
                </Row>
                <Row spacingInset="small" >
                    <Box width="100%">
                        <Text type="h200" alignment="center">
                            {getFormattedString(props, STOW_PROGRESS_VIEW_STRINGS.yet_to_be_stowed)}
                        </Text>
                    </Box>
                </Row>
                <Divider size="small"/>
                <Row spacingInset="small">
                    <Box width="100%">
                        {props.modalData.text}
                    </Box>
                </Row>
            </Box>
        </div>
    )
}

export default injectIntl(TrackingIdsDialogView)
