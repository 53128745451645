import {RESPONSE} from "../../constants/ResponseCodes";
import {SCOPE} from "../../constants/Scope";

export const PlayerConstants = {
    PROCESS_PATH_STOW_SSD: "Stow_DRS",
    PROCESS_PATH_STOW: "Stow_By_Light",
    VERSION: "V1.0.136.0"
};

export const HelpTypes = {
    PACKAGE_SCAN: 'PackageScan',
    LOCATION_SCAN: 'LocationScan',
    CONTAINER_PREPARE: 'ContainerPrep',
    CONTAINER_CLOSE: 'ContainerClose',
    DEFAULT: 'Default'
};

export const ScanTypes = {
    PACKAGE_SCAN: 'PACKAGE_SCAN',
    LOCATION_SCAN: 'LOCATION_SCAN',
    PACKAGE_RESCAN: 'PACKAGE_RESCAN',
    SCRUB_LOCATION: 'SCRUB_LOCATION',
    SCRUB_CONTAINER: 'SCRUB_CONTAINER',
    CLOSE_BAG_SCAN: 'CLOSE_BAG_SCAN',
    CLOSE_CART_SCAN: 'CLOSE_CART_SCAN',
    CLOSE_PALLET_SCAN: 'CLOSE_PALLET_SCAN',
    CLOSE_BAG_LOCATION_SCAN: 'CLOSE_BAG_LOCATION_SCAN',
    PREP_BAG_SCAN: 'PREP_BAG_SCAN',
    PREP_BAG_LOCATION_SCAN: 'PREP_BAG_LOCATION_SCAN',
    PREP_CART_SCAN: 'PREP_CART_SCAN',
    PREP_CART_LOCATION_SCAN: 'PREP_CART_LOCATION_SCAN',
    PREP_PALLET_SCAN: 'PREP_PALLET_SCAN',
}

export const mapErrorToPlayerEnum = (type, responseCode, scope) => {
    if (type === "CONTAINER_SCAN") {
        switch (responseCode) {
            case RESPONSE.BAG_ALREADY_OPEN: return window.DolphinPlayer.Stow.ContainerScanErrorType.CONTAINER_USED;
            case RESPONSE.INVALID_MOVE: return window.DolphinPlayer.Stow.ContainerScanErrorType.INVALID_BARCODE;
            case RESPONSE.INVALID_SCAN_DONE: return window.DolphinPlayer.Stow.ContainerScanErrorType.INVALID_BARCODE;
            case RESPONSE.BAG_ALREADY_CLOSED: return window.DolphinPlayer.Stow.ContainerScanErrorType.CONTAINER_CLOSED;
            case RESPONSE.NO_PLAN_PRESENT: return window.DolphinPlayer.Stow.ContainerScanErrorType.CONTAINER_EMPTY;
            default: return window.DolphinPlayer.Stow.ContainerScanErrorType.UNKNOWN;
        }
    }
    else if (type === "CONTAINER_LOCATION_SCAN") {
        switch (responseCode) {
            case RESPONSE.INVALID_SCAN_DONE: return window.DolphinPlayer.Stow.ContainerScanErrorType.INCORRECT_LOCATION;
            default: return window.DolphinPlayer.Stow.ContainerScanErrorType.UNKNOWN;
        }
    }
    else if (type === "PROBLEM_SOLVE") {
        if(scope === SCOPE.SSD) {
            switch (responseCode) {
                case RESPONSE.INVALID_SCAN_DONE: return window.DolphinPlayer.DrsStow.ProblemSolveErrorType.INVALID_LOCATION;
                case RESPONSE.INVALID_MOVE: return window.DolphinPlayer.DrsStow.ProblemSolveErrorType.INVALID_MOVE;
                default: return window.DolphinPlayer.DrsStow.ProblemSolveErrorType.UNKNOWN;
            }
        } else {
            switch (responseCode) {
                case RESPONSE.INVALID_SCAN_DONE: return window.DolphinPlayer.Stow.PSBinScanErrorType.INVALID_LOCATION;
                default: return window.DolphinPlayer.Stow.PSBinScanErrorType.UNKNOWN;
            }
        }
    }
    else if (type === "PACKAGE_SCAN") {
        if(scope === SCOPE.SSD) {
            switch (responseCode) {
                case RESPONSE.NO_PLAN_PRESENT: return window.DolphinPlayer.DrsStow.ProblemSolveErrorType.NO_VALID_SORT_LOCATION;
                case RESPONSE.STATION_MISMATCH:return window.DolphinPlayer.DrsStow.ProblemSolveErrorType.STATION_MISMATCH;
                case RESPONSE.INVALID_PACKAGE_STATE: return window.DolphinPlayer.DrsStow.ProblemSolveErrorType.PACKAGE_STOW_ERROR;
                case RESPONSE.BAG_NOT_OPEN:
                case RESPONSE.BAG_WEIGHT_LIMIT_REACHED:
                case RESPONSE.CONTAINER_DOES_NOT_EXIST:
                case RESPONSE.CART_NOT_ATTACHED: return window.DolphinPlayer.DrsStow.BufferPlacementErrorType.CART_NOT_READY;
                case RESPONSE.INVALID_SCAN_DONE: return window.DolphinPlayer.DrsStow.PackageScanErrorType.INVALID_PACKAGE;
                case RESPONSE.UNSUPPORTED_BARCODE: return window.DolphinPlayer.DrsStow.PackageScanErrorType.INCORRECT_BARCODE;
                default: return window.DolphinPlayer.DrsStow.PackageScanErrorType.UNKNOWN;
            }
        } else {
            switch (responseCode) {
                case RESPONSE.STATION_MISMATCH: return window.DolphinPlayer.Stow.PSBinScanErrorType.STATION_MISMATCH;
                case RESPONSE.INVALID_PACKAGE_STATE: return window.DolphinPlayer.Stow.PSBinScanErrorType.PACKAGE_STOW_ERROR;
                case RESPONSE.BAG_WEIGHT_LIMIT_REACHED: return window.DolphinPlayer.Stow.PackageScanErrorType.BAG_WEIGHT_LIMIT_REACHED;
                case RESPONSE.BAG_NOT_OPEN:
                case RESPONSE.CONTAINER_DOES_NOT_EXIST:
                case RESPONSE.CART_NOT_ATTACHED: return window.DolphinPlayer.Stow.BufferPlacementReason.BAG_NOT_OPEN;
                case RESPONSE.NO_PLAN_PRESENT: return window.DolphinPlayer.Stow.PSBinScanErrorType.NO_VALID_LOCATION;
                case RESPONSE.INVALID_SCAN_DONE: return window.DolphinPlayer.Stow.PackageScanErrorType.INVALID_PACKAGE;
                case RESPONSE.UNSUPPORTED_BARCODE: return window.DolphinPlayer.Stow.PackageScanErrorType.INVALID_BARCODE;
                default: return window.DolphinPlayer.Stow.PackageScanErrorType.UNKNOWN;
            }
        }
    }
    else if (type === "LOCATION_SCAN") {
        if(scope === SCOPE.SSD) {
            switch (responseCode) {
                case RESPONSE.NO_PLAN_PRESENT: return window.DolphinPlayer.DrsStow.ProblemSolveErrorType.PACKAGE_STOW_ERROR;
                case RESPONSE.INVALID_MOVE: return window.DolphinPlayer.DrsStow.PackageScanErrorType.INCORRECT_LOCATION;
                case RESPONSE.INVALID_PACKAGE_SORTGROUP: return window.DolphinPlayer.DrsStow.BufferPlacementErrorType.WRONG_BATCH;
                case RESPONSE.INVALID_PACKAGE_STATE: return window.DolphinPlayer.DrsStow.PackageScanErrorType.INVALID_PACKAGE_STATE;
                case RESPONSE.INVALID_SCAN_DONE: return window.DolphinPlayer.DrsStow.PackageScanErrorType.INVALID_LOCATION;
                default: return window.DolphinPlayer.DrsStow.PackageScanErrorType.UNKNOWN;
            }
        } else {
            switch (responseCode) {
                case RESPONSE.BAG_WEIGHT_LIMIT_REACHED: return window.DolphinPlayer.Stow.PackageScanErrorType.BAG_WEIGHT_LIMIT_REACHED;
                case RESPONSE.INVALID_PACKAGE_SORTGROUP:
                case RESPONSE.BAG_NOT_OPEN: return window.DolphinPlayer.Stow.BufferPlacementReason.BAG_NOT_OPEN;
                case RESPONSE.NO_PLAN_PRESENT: return window.DolphinPlayer.Stow.PSBinScanErrorType.PACKAGE_STOW_ERROR;
                case RESPONSE.INVALID_SCAN_DONE:  return window.DolphinPlayer.Stow.PackageScanErrorType.INVALID_LOCATION;
                case RESPONSE.INVALID_PACKAGE_STATE:
                case RESPONSE.INVALID_MOVE:  return window.DolphinPlayer.Stow.PackageScanErrorType.INCORRECT_LOCATION;
                default: return window.DolphinPlayer.Stow.PackageScanErrorType.UNKNOWN;
            }
        }
    }
    else if (type === "CART_SCAN") {
        switch (responseCode) {
            case RESPONSE.INVALID_SCAN_DONE: return window.DolphinPlayer.DrsStow.CartScanErrorType.INVALID_CART;
            case RESPONSE.CART_ALREADY_ATTACHED: return window.DolphinPlayer.DrsStow.CartScanErrorType.CART_ALREADY_ATTACHED;
            default: return window.DolphinPlayer.DrsStow.CartScanErrorType.UNKNOWN;
        }
    }
    else if (type === "CART_LOCATION_SCAN") {
        switch (responseCode) {
            case RESPONSE.INVALID_MOVE:
            case RESPONSE.INVALID_SCAN_DONE: return window.DolphinPlayer.DrsStow.CartScanErrorType.INVALID_LOCATION;
            case RESPONSE.NON_EMPTY_LOCATION: return window.DolphinPlayer.DrsStow.CartScanErrorType.LOCATION_NOT_EMPTY;
            default: return window.DolphinPlayer.DrsStow.CartScanErrorType.UNKNOWN;
        }
    }
};
