import React, {Component} from 'react';
import StowPackageView from "./view/StowPackageView";
import StowPackageContextProvider from "./context/StowPackageContext";

class StowPackage extends Component {

    render() {

        return (
            <div>
                <StowPackageContextProvider>
                    <StowPackageView history={this.props.history}
                                     stowFlow={this.props.match.params.flow}/>
                </StowPackageContextProvider>
            </div>
        )
    }
}

export default StowPackage
