import React, {useContext, useEffect} from "react";
import {FormattedMessage} from "react-intl";
import {APP_NAVIGATION_STRINGS} from "../../../../constants/Strings";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {VIEW, WORKFLOW} from "../../../workflow/Workflow";
import {isNonNull} from "../../../utils/Util";
import {NotificationContext} from "../../../notification/context/NotificationContext";
import BagScanView from "./bag-scan/BagScanView";
import BagScanLocationView from "./bag-scan-location/BagScanLocationView";
import {setGlobalContext} from "../../../config/action/ConfigAction";
import {ConfigContext} from "../../../config/context/ConfigContext";
import {PrepareContainerContext} from "../../prepare-container/context/PrepareContainerContext";
import ScanCartView from "../../prepare-container/view/scan-cart/ScanCartView";
import Loading from "../../../loading/Loading";
import {interceptBackButton, interceptBackButtonStop} from "../../../bridge/BackButton";
import ReplaceBagView from "./replace-bag/ReplaceBagView";
import {ROUTING} from "../../../../constants/Routing";

const PrepareCartView = (props) => {

    const {navActions: {clearContext, initContext, openBackDrop, setNavTitle}} = useContext(AppNavigationContext)
    const {configViewDispatch} = useContext(ConfigContext)
    const {prepareContainerModel} = useContext(PrepareContainerContext)
    const {notificationActions: {setNotification}} = useContext(NotificationContext)

    useEffect(() => {
        if (isNonNull(prepareContainerModel.notification)) {
            setNotification(prepareContainerModel.notification)
        }
        if (isNonNull(prepareContainerModel.nextRoute)) {
            interceptBackButtonStop()
            props.history.push(prepareContainerModel.nextRoute)
        }
    }, [prepareContainerModel]);

    useEffect(() => {
        setNavTitle(<FormattedMessage id={APP_NAVIGATION_STRINGS.prepare_carts}/>);
        setGlobalContext(configViewDispatch, {workflow: WORKFLOW.PREP_CART})
        const onBackPressed = () => {
            openBackDrop({
                secondaryButtonOnClick: () => props.history.push(ROUTING.ENTRY)
            });
        };
        initContext({onBackPressed});
        interceptBackButton('prepare cart view')
        return clearContext
    }, []);

    const renderView = () => {
        if (prepareContainerModel.loading) {
            return <Loading/>
        } else if (VIEW.BAG_SCAN_VIEW === prepareContainerModel.view) {
            return <BagScanView history={props.history}/>
        } else if (VIEW.BAG_SCAN_LOCATION_VIEW === prepareContainerModel.view) {
            return <BagScanLocationView history={props.history}/>
        } else if (VIEW.REPLACE_BAG === prepareContainerModel.view) {
            return <ReplaceBagView/>
        } else {
            return <ScanCartView history={props.history}/>
        }
    }

    return renderView()
}

export default PrepareCartView
