import {GET_ROUTES_FOR_STOW_PROGRESS} from "../action/StowProgressAction";
import {getRoutesForStowProgressViewData} from "../data/ViewData";

const StowProgressReducer = (state, action) => {

    switch (action.type) {
        case GET_ROUTES_FOR_STOW_PROGRESS:
            return {
                ...state,
                ...action.data,
                ...getRoutesForStowProgressViewData(action.data)
            }
        default:
            return state
    }
}

export default StowProgressReducer