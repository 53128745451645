import {dev_debug} from "../../utils/Util";
import {RESPONSE} from "../../../constants/ResponseCodes";
import {API_PATH, axiosGET} from "../../utils/network/NetworkUtils";
import {AnalyticEventKeys, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";

export const getSALTemplateForPrinter = async (printerType) => {
    const startTime = Date.now();
    const params = {
        params:{
            printerType: printerType
        }
    };
    const response = dev_debug === true ? TestData :
        await axiosGET(AnalyticEventKeys.Modules.GET_SAL_TEMPLATE, API_PATH.SAL_TEMPLATE, params)

    if (response.status === 200) {
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.STOW_GET_SAL_TEMPLATE_FOR_PRINTER, startTime);
        return response.data
    }
    MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.STOW_GET_SAL_TEMPLATE_FOR_PRINTER, startTime, true);
    return {
        responseCode: RESPONSE.UNKNOWN_ERROR_OCCURRED
    }
}

const TestData = {
    status: 200,
    data: {
        responseCode: "SUCCESS",
        template: ""
    }
}