import {RESPONSE} from "../../../constants/ResponseCodes";
import {scrubContainer, validateContainerBeforeScrub} from "../network/ScrubContainerNetwork";


export const getScrubContainerResource = async (containerScannableId, containerType, destinationScannableId) => {
    const response = await scrubContainer(containerScannableId, containerType, destinationScannableId)
    return {
        responseCode: RESPONSE[response.responseCode],
        destinationLabel: response.destinationLabel,
        scope: response.scope,
        node: response.node
    }
}

export const validateContainerBeforeScrubResource = async (containerScannableId) => {
    const response = await validateContainerBeforeScrub(containerScannableId)
    return {
        responseCode: RESPONSE[response.responseCode],
        containerLabel: response.containerLabel,
        containerType: response.containerType,
        node: response.node,
        scope: response.scope
    }
}

