import {COMMON_ACTIONS} from "../../../constants/ActionType";
import stageCart from "../../handler/StageCartHandler";

export const HotSwapActions = {
    REDIRECT_TO_STAGE: "REDIRECT_TO_STAGE"
};

export const CartHotSwapReducer = (state, action) => {

    switch (action.type) {
        case COMMON_ACTIONS.LOADING:
            return {
                ...state,
                loading: true,
                notification: null
            };
        case HotSwapActions.REDIRECT_TO_STAGE:
            stageCart(action.data.props, action.data.cartId, action.data.routeId);
            return {
                ...state,
                loading: false,
                notification: null
            };
        default:
            return state;
    }
};