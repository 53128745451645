import React, {useContext, useEffect} from "react";
import {injectIntl} from "react-intl";
import {INDIA_LANDING_VIEW_STRINGS} from "../../../../constants/Strings";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {getFormattedString, isNonNull} from "../../../utils/Util";
import {PrepareContainerContext} from "../../prepare-container/context/PrepareContainerContext";
import {NotificationContext} from "../../../notification/context/NotificationContext";
import {VIEW} from "../../../workflow/Workflow";
import ScanContainerLocationView from "./scan-container-location/ScanContainerLocationView";
import ScanContainerView from "./scan-container/ScanContainerView";
import Loading from "../../../loading/Loading";
import {interceptBackButton} from "../../../bridge/BackButton";

const PrepareRouteView = (props) => {

    const {prepareContainerModel} = useContext(PrepareContainerContext)
    const {navActions: {setNavTitle, setHelpOption, openBackDrop, initContext, clearContext}} = useContext(AppNavigationContext)
    const {notificationActions: {setNotification}} = useContext(NotificationContext)

    useEffect(() => {
        if (isNonNull(prepareContainerModel.notification)) {
            setNotification(prepareContainerModel.notification)
        }
        if (isNonNull(prepareContainerModel.nextRoute)) {
            props.history.push(prepareContainerModel.nextRoute)
        }
    }, [prepareContainerModel]);

    useEffect(() => {
        const onBackPressed = openBackDrop
        initContext({onBackPressed})
        setNavTitle(getFormattedString(props, INDIA_LANDING_VIEW_STRINGS.open_route_containers));
        setHelpOption(true, {list: []});
        interceptBackButton('Prepare route container')
        return clearContext
    }, []);

    const renderView = () => {
        if (prepareContainerModel.view === VIEW.SCAN_CONTAINER) {
            return <ScanContainerView/>
        } else {
            return <ScanContainerLocationView/>
        }
    }

    if (prepareContainerModel.loading) {
        return <Loading/>
    } else {
        return renderView()
    }
}

export default injectIntl(PrepareRouteView)
