import React, {createContext, useReducer} from 'react'
import {ProblemSolveReducer} from "../reducer/ProblemSolveReducer";

export const ProblemSolveContext = createContext();

const ProblemSolveContextProvider = (props) => {
    const [problemSolveViewModel, problemSolveDispatch] = useReducer(ProblemSolveReducer, {})
    return (
        <ProblemSolveContext.Provider value={{problemSolveViewModel, problemSolveDispatch}}>
            {props.children}
        </ProblemSolveContext.Provider>
    )
}

export default ProblemSolveContextProvider
