import {CONTAINER_TYPE} from "../../constants/ContainerType";
import {BIN_STATUS} from "../../constants/BinStatus";
import {chainWalk, isNonNull} from "./Util";

export const getBinIndexToBeAttached = (currentBinIndex, binList) => {

    let currentBin = null;

    let binIndexIterator = currentBinIndex;
    if (isNonNull(binList)) {
        while (binIndexIterator < binList.length) {

            currentBin = binList[binIndexIterator].bin;
            if (CONTAINER_TYPE.BIN === currentBin.binType) {
                if (BIN_STATUS.BIN_WITH_NO_BAG === currentBin.binStatus) {
                    break;
                } else if (BIN_STATUS.BIN_WITH_ATTACHED_BAG === currentBin.binStatus
                    || BIN_STATUS.BIN_WITH_PS_BAG === currentBin.binStatus) {
                    ++binIndexIterator;
                } else {
                    break;
                }
            } else {
                ++binIndexIterator;
            }
        }
    }
    return binIndexIterator;
}

export const getActiveBin = (currentBinIndex, defaultBinList, binList) => {
    const bins = isNonNull(binList) ? binList : defaultBinList
    if (isNonNull(bins) && currentBinIndex < bins.length) {
        return chainWalk(() => bins[currentBinIndex].bin, null)
    }
    return null
}