import React, {useContext, useEffect} from 'react';
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import {APP_NAVIGATION_STRINGS, COMMON_STRINGS, MANAGE_STOW_AREA_VIEW_STRINGS} from "../../../constants/Strings";
import {FormattedMessage, injectIntl} from "react-intl";
import {AppNavigationContext} from "../../app-navigation/context/AppNavigationContext";
import {getSelectContainerRoutingPath} from "../../utils/Util";
import {STOW_AREA_OPERATION} from "../../../constants/StowAreaOperation";
import {interceptBackButton, interceptBackButtonStop} from "../../bridge/BackButton";
import {PlayerContext} from "../../player/context/PlayerContext";
import {ApplicationActions} from "../../player/action/ApplicationActions";

const ManageStowAreaView = (props) => {

    const {navActions: {setNavTitle, setHelpOption, openBackDrop, initContext, clearContext}} = useContext(AppNavigationContext)
    const {dispatchPlayer} = useContext(PlayerContext);

    const handleClick = (stowAreaOperation) => {
        dispatchPlayer({type: ApplicationActions.CONTAINER_TASK_SELECTION, data: {operation: stowAreaOperation}});
        interceptBackButtonStop();
        props.history.push(getSelectContainerRoutingPath(stowAreaOperation))
    }

    const init = {
        pageTitle: props.intl.formatMessage({id: COMMON_STRINGS.select_sortation_task}),
        tasks: [
            {
                title: props.intl.formatMessage({id: MANAGE_STOW_AREA_VIEW_STRINGS.prepare_container}),
                description: props.intl.formatMessage({id: MANAGE_STOW_AREA_VIEW_STRINGS.prepare_container_desc}),
                onClick: () => handleClick(STOW_AREA_OPERATION.PREPARE_CONTAINER)
            },
            {
                title: props.intl.formatMessage({id: MANAGE_STOW_AREA_VIEW_STRINGS.close_container}),
                description: props.intl.formatMessage({id: MANAGE_STOW_AREA_VIEW_STRINGS.close_container_desc}),
                onClick: () => handleClick(STOW_AREA_OPERATION.CLOSE_CONTAINER)
            }
        ]
    }

    useEffect(() => {
        setNavTitle(<FormattedMessage id={APP_NAVIGATION_STRINGS.manage_stow_area}/>);
        setHelpOption(true, {list: []});
        const onBackPressed = openBackDrop;
        initContext({onBackPressed});
        interceptBackButton('manage stow area');
        return () => {setHelpOption(false, {});clearContext()}
    }, []);

    return (
        <div>
            <TaskView {...init} />
        </div>
    )
}

export default injectIntl(ManageStowAreaView)
