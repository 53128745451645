import {
    COMMON_STRINGS,
    INDIA_LANDING_VIEW_STRINGS,
    LANDING_VIEW_STRINGS,
    SCRUB_CONTAINER_STRINGS
} from "../../../../constants/Strings";
import {ROUTING} from "../../../../constants/Routing";
import {SCOPE} from "../../../../constants/Scope";
import {SLASH} from "../../../utils/Util";
import {CONSTANT} from "../../../../constants/Constants";
import {ApplicationActions} from "../../../player/action/ApplicationActions";

const getStowPackageTask = (intl, history, dispatchPlayer) => {
    return {
        title: intl.formatMessage({id: LANDING_VIEW_STRINGS.stow_packages}),
        description: intl.formatMessage({id: LANDING_VIEW_STRINGS.stow_packages_desc}),
        onClick : () => {
            dispatchPlayer({type: ApplicationActions.TASK_SELECTION, data: {operation: LANDING_VIEW_STRINGS.stow_packages}});
            history.push(ROUTING.STOW_PACKAGE);
        }
    }
}

const getAmzlTask = (intl, history, dispatchPlayer) => {
    return [
        {
            title: intl.formatMessage({id: LANDING_VIEW_STRINGS.manage_stow_area}),
            description: intl.formatMessage({id: LANDING_VIEW_STRINGS.manage_stow_area_desc}),
            onClick : () => {
                dispatchPlayer({type: ApplicationActions.TASK_SELECTION, data: {operation: LANDING_VIEW_STRINGS.manage_stow_area}});
                history.push(ROUTING.MANAGE_STOW_AREA);
            }
        },
        getStowPackageTask(intl, history, dispatchPlayer),
        {
            title: intl.formatMessage({id: SCRUB_CONTAINER_STRINGS.scrub_location_container}),
            description: intl.formatMessage({id: SCRUB_CONTAINER_STRINGS.scrub_location_container_desc}),
            onClick : () => {
                dispatchPlayer({type: ApplicationActions.TASK_SELECTION, data: {operation: SCRUB_CONTAINER_STRINGS.scrub_location_container}});
                history.push(ROUTING.SCRUB_SELECT_TASK);
            }
        }
    ]
}

const getArTask = (intl, scope) => {
    return [
        {
            title: intl.formatMessage({id: LANDING_VIEW_STRINGS.prepare_carts}),
            description: intl.formatMessage({id: LANDING_VIEW_STRINGS.prepare_carts_desc}),
            href: ROUTING.PREPARE_CART
        },
        {
            title: intl.formatMessage({id: LANDING_VIEW_STRINGS.prepare_stow_location}),
            description: intl.formatMessage({id: getLandingViewString(scope)}),
            href: ROUTING.PREPARE_STOW_LOCATION
        },
        {
            title: intl.formatMessage({id: LANDING_VIEW_STRINGS.stow_packages}),
            description: intl.formatMessage({id: LANDING_VIEW_STRINGS.stow_packages_desc}),
            href: ROUTING.STOW_CONVEYABLE_SELECTION
        }
    ]
}

const getSsdTask = (intl, history, dispatchPlayer) => {
    return [
        {
            title: intl.formatMessage({id: LANDING_VIEW_STRINGS.prepare_stow_location}),
            description: intl.formatMessage({id: LANDING_VIEW_STRINGS.prepare_stow_location_desc}),
            onClick : () => {
                dispatchPlayer({type: ApplicationActions.TASK_SELECTION, data: {operation: LANDING_VIEW_STRINGS.prepare_stow_location}});
                history.push(ROUTING.PREPARE_STOW_LOCATION);
            }
        },
        getStowPackageTask(intl, history, dispatchPlayer)
    ]
}

const getAmzlInTask = (intl, history, dispatchPlayer) => {
    return [
        {
            title: intl.formatMessage({id: INDIA_LANDING_VIEW_STRINGS.stow_to_route_readiness}),
            description: intl.formatMessage({id: INDIA_LANDING_VIEW_STRINGS.open_containers_before_stow_to_route}),
            href: ROUTING.PREPARE_ROUTE
        },
        getStowPackageTask(intl, history, dispatchPlayer),
        {
            title: intl.formatMessage({id: INDIA_LANDING_VIEW_STRINGS.stow_flow}),
            description: intl.formatMessage({id: INDIA_LANDING_VIEW_STRINGS.stow_flow_desc}),
            href: ROUTING.STOW_FLOW_MODE
        },
        {
            title: intl.formatMessage({id: INDIA_LANDING_VIEW_STRINGS.topup_packages}),
            description: intl.formatMessage({id: INDIA_LANDING_VIEW_STRINGS.topup_packages_desc}),
            href: ROUTING.STOW_PACKAGE + SLASH + CONSTANT.TOPUP_PACKAGE
        },
        {
            title: intl.formatMessage({id: SCRUB_CONTAINER_STRINGS.scrub_container_title}),
            description: intl.formatMessage({id: INDIA_LANDING_VIEW_STRINGS.scrub_container_desc}),
            href: ROUTING.SCRUB_CONTAINER
        }
    ]
}

const getDefaultTask = () => {
    return []
}

export const getPageTitle = (scope, intl) => {
    return intl.formatMessage({id: COMMON_STRINGS.select_sortation_task})
};

export const getTasks = (scope, intl, history, dispatchPlayer) => {
    switch (scope) {
        case SCOPE.AR:
        case SCOPE.AR_NEXT_GEN:
            return getArTask(intl, scope)
        case SCOPE.SSD:
            return getSsdTask(intl, history, dispatchPlayer)
        case SCOPE.AMZL_IN:
            return getAmzlInTask(intl, history, dispatchPlayer)
        case SCOPE.AMZL:
            return getAmzlTask(intl, history, dispatchPlayer)
        default:
            return getDefaultTask()
    }
}

const getLandingViewString = (scope) => {
    if (SCOPE.AR_NEXT_GEN === scope) {
        return LANDING_VIEW_STRINGS.scan_and_prepare_carts_near_gravity_conveyor
    } if (SCOPE.AR === scope) {
        return LANDING_VIEW_STRINGS.scan_and_prepare_carts_ar
    }
}
