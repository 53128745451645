import React, {useContext, useEffect} from 'react';
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import QrCode from "../../../../../images/ic_qr_code.png";
import {COMMON_STRINGS} from "../../../../../constants/Strings";
import {injectIntl} from "react-intl";
import {ConfigContext} from "../../../../config/context/ConfigContext";
import ScanView from "../../../../scan-view/ScanView";
import Column from "@amzn/meridian/column";
import {StowPackageContext} from "../../../context/StowPackageContext";
import {AppNavigationContext} from "../../../../app-navigation/context/AppNavigationContext";
import {getValidateLocationBeforeStow} from "../../../action/StowPackageAction";
import {getFormattedString} from "../../../../utils/Util";
import {clearGlobalContext} from "../../../../config/action/ConfigAction";
import {WORKFLOW} from "../../../../workflow/Workflow";
import {CLEAR_STOW_PACKAGE_CONTEXT} from "../../../reducer/StowPackageReducer";

const StowScanLocationFlowView = (props) => {

    const {stowPackageDispatch} = useContext(StowPackageContext)
    const {configViewModel, configViewDispatch} = useContext(ConfigContext)
    const {navActions: {setHelpOption}} = useContext(AppNavigationContext)

    const onScan = (input) => {
        getValidateLocationBeforeStow(stowPackageDispatch, input, configViewModel.scope, WORKFLOW.STOW_FLOW)
    }

    const init = {
        primaryTitle: {
            title: getFormattedString(props, COMMON_STRINGS.scan_location),
            type: "Bold",
            size: "Large"
        },
        image: QrCode
    }

    useEffect(() => {
        stowPackageDispatch({type: CLEAR_STOW_PACKAGE_CONTEXT})
        clearGlobalContext(configViewDispatch)
        setHelpOption(true, {list: []});
    }, []);

    return (
        <Column spacingInset="medium" alignmentHorizontal="center">
            <ScanWithCodeView {...init}/>
            <ScanView onScan={onScan}/>
        </Column>
    )
}

export default injectIntl(StowScanLocationFlowView)
