import React, {useContext, useEffect} from 'react';
import {HELP_CENTER_STRINGS, SCRUB_CONTAINER_STRINGS} from "../../../../../constants/Strings";
import {FormattedMessage} from "react-intl";
import {AppNavigationContext} from "../../../../app-navigation/context/AppNavigationContext";
import {getHelpOptions, getSelectContainerRoutingPath, SLASH} from "../../../../utils/Util";
import {STOW_AREA_OPERATION} from "../../../../../constants/StowAreaOperation";
import {HeaderView} from "@amzn/dolphin-uiweb-framework";
import Button from "@amzn/meridian/button";
import {VIEW} from "../../../../workflow/Workflow";
import Column from "@amzn/meridian/column";
import {PrepareContainerContext} from "../../context/PrepareContainerContext";
import {CONTAINER_TYPE} from "../../../../../constants/ContainerType";
import {ROUTING} from "../../../../../constants/Routing";
import {scrubBag} from "../../action/PrepareContainerAction";
import {PlayerContext} from "../../../../player/context/PlayerContext";
import {ApplicationActions} from "../../../../player/action/ApplicationActions";


const ScrubNonEmptyBagConfirmView = (props) => {
    const {navActions: {setNavTitle, setHelpOption, closeHelpOption, openBackEnabled}} = useContext(AppNavigationContext)
    const {prepareContainerModel, prepareContainerDispatch} = useContext(PrepareContainerContext);
    const {dispatchPlayer} = useContext(PlayerContext);


    const {primaryText, secondaryText} = {
        primaryText: {
            title: <FormattedMessage id={SCRUB_CONTAINER_STRINGS.scrub_bag_guidance}/>,
            size: "large",
            type: "primary"
        },
        secondaryText: {
            title: <b><FormattedMessage id = {SCRUB_CONTAINER_STRINGS.scrub_bag_confirmation}
                                        values = {{containerLabel: prepareContainerModel.displayBagId}}/></b>,
            size: "large",
            type: "primary"
        }
    }

    useEffect(() => {
        openBackEnabled()
    }, []);

    useEffect(() => {
        const callbacks = [
            () => {
                dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP}});
                props.history.push(getSelectContainerRoutingPath(STOW_AREA_OPERATION.PREPARE_CONTAINER))
            },
            closeHelpOption
        ]
        setHelpOption(true, getHelpOptions(callbacks,
            <FormattedMessage id={HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP}/>,
            <FormattedMessage id={HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP_TEXT}/>));
        return () => setHelpOption(false, {});
    }, [])

    const handleCancel = () => {
        prepareContainerModel.view = VIEW.REPLACE_NON_EMPTY_BAG;
        dispatchPlayer({type: ApplicationActions.CONTAINER_SCRUB_BAG_CONFIRM_VIEW, data: {operation: SCRUB_CONTAINER_STRINGS.scrub_cancel_button}});
        props.history.push(ROUTING.PREPARE_CONTAINER + SLASH + CONTAINER_TYPE.BAG)
    }

    const handleConfirm = () => {
        dispatchPlayer({type: ApplicationActions.CONTAINER_SCRUB_BAG_CONFIRM_VIEW, data: {operation: SCRUB_CONTAINER_STRINGS.scrub_confirm_button}});
        scrubBag(prepareContainerDispatch, prepareContainerModel.bagLabel, prepareContainerModel.displayBagId);
    }

    useEffect(() => {
        setNavTitle(<FormattedMessage id={SCRUB_CONTAINER_STRINGS.scrub_bag_title}/>);
    }, [])

    return (
        <Column spacingInset="medium" alignmentHorizontal="center">
            <HeaderView primaryTitle={primaryText} secondaryTitle={secondaryText}/>
            <Column className={"footer"}>
                <Button size="xlarge" type="secondary" minWidth="100%"
                        onClick={handleCancel}>{<FormattedMessage id={SCRUB_CONTAINER_STRINGS.scrub_cancel_button}/>}</Button><br/>
                <Button size="xlarge" type="primary" minWidth="100%"
                        onClick={handleConfirm}>{<FormattedMessage id={SCRUB_CONTAINER_STRINGS.scrub_confirm_button}/>}</Button>
            </Column>
        </Column>
    )
}

export default ScrubNonEmptyBagConfirmView

