import React, {useContext, useEffect} from "react"
import {ScanWithInputView} from "@amzn/dolphin-uiweb-framework"
import Column from "@amzn/meridian/column";
import {getFormattedString} from "../../../utils/Util";
import {HELP_CENTER_STRINGS} from "../../../../constants/Strings";
import {injectIntl} from "react-intl";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import closeTokens from "@amzn/meridian-tokens/base/icon/close";
import Row from "@amzn/meridian/row";
import {getSortInfoForPackage, processStow} from "../../action/StowPackageAction";
import {StowPackageContext} from "../../context/StowPackageContext";
import {ConfigContext} from "../../../config/context/ConfigContext";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {OPERATIONAL_MODE} from "../../../../constants/Constants";
import {WORKFLOW} from "../../../workflow/Workflow";
import {NotificationContext} from "../../../notification/context/NotificationContext";

const StowManualScanView = (props) => {

    const {stowPackageModel, stowPackageDispatch} = useContext(StowPackageContext);
    const {configViewModel} = useContext(ConfigContext)
    const {notificationActions: {clearNotification}} = useContext(NotificationContext)
    const {navActions: {closeCustomHelpOption}} = useContext(AppNavigationContext)

    const getPackageDestinationInfo = (scannedInput) => {
        return {
            packageTrackingId: scannedInput,
            scannedDestination: stowPackageModel.destinationLabel,
            operationalMode: OPERATIONAL_MODE.FLOW_STOW_TO_ROUTE,
            scope: configViewModel.scope
        }
    }

    const onSubmit = (input) => {
        closeCustomHelpOption()
        if (WORKFLOW.STOW_FLOW === configViewModel.workflow) {
            processStow(stowPackageDispatch, getPackageDestinationInfo(input), WORKFLOW.STOW_FLOW)
        } else {
            const workflow = props.isTopupFlow ? WORKFLOW.STOW_TOPUP : configViewModel.workflow
            getSortInfoForPackage(stowPackageDispatch, input, configViewModel.scope, workflow, null)
        }
    }

    const init = {
        title: getFormattedString(props, HELP_CENTER_STRINGS.PACKAGE_ID),
        description: getFormattedString(props, HELP_CENTER_STRINGS.TRACKING_ID_MANUAL_INPUT),
        onSubmit: onSubmit
    }

    useEffect(() =>{
        clearNotification()
    },[])
    return (
        <Column className={"helpView"} width={"100%"} height={"100%"} backgroundColor="#ffffff">
            <Row backgroundColor="#191919" width="100%" widths={["fill"]} spacingInset="medium" spacing="none">
                <Text type="h200" color={"inverted"} alignment={"center"}>Stow</Text>
                <div style={{position: "absolute", right: 0}}>
                    <Button label={"close"} type="icon" color={"inverted"} onClick={closeCustomHelpOption}>
                        <Text color={"inverted"}><Icon tokens={closeTokens}/></Text>
                    </Button>
                </div>
            </Row>
            <ScanWithInputView {...init}/>
        </Column>
    )
}

export default injectIntl(StowManualScanView)