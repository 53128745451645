import {RESPONSE} from "../../../../../../../constants/ResponseCodes";
import {LOCATION_TYPE} from "../../../../../../../constants/LocationType";

export const TEST_RESPONSE_200 = () => {
    return {
        status: 200,
        data: TEST_SUCCESS_RESPONSE
    }
}

const TEST_SUCCESS_RESPONSE = {
    responseCode: RESPONSE.SUCCESS,
    sortLocations: [
        {
            locationInfo: {
                aisle: "A14",
                locationLabel: "ENDCP.A14",
                locationType: LOCATION_TYPE.OVERFLOW,
                rack: "ENDCP",
                scannableId: "26b69d7f-d2f3-c29f-3db4-f3fdfe5da9dc"
            }
        },
        {
            locationInfo: {
                aisle: "A14",
                locationLabel: "OV-A14-AG",
                locationType: LOCATION_TYPE.OVERFLOW,
                rack: "AG",
                scannableId: "2eb55e7f-776b-5f1e-4800-22d67b1f9865"
            }
        }
    ]
}

export const TEST_BAG_SCANNABLE_ID = "AB-F-3709-709-BLK"
