import {Logger} from "@amzn/dolphin-web-framework";

/**
 * A generic poller
 */
class Poller {
    constructor() {
        this.timer = null;
        this.isActive = false;
        this.TAG = "Poller: ";
    }

    startPolling(method, interval = 1000) {
        Logger.log.info(this.TAG + "startPolling");
        this.isActive = true;
        this.timer = setInterval(()=>{
            if(this.isActive) {
                method();
            }
        }, interval);
    }

    stopPolling() {
        Logger.log.info(this.TAG + "stopPolling");
        this.isActive = false;
        clearInterval(this.timer);
    }

    pausePolling() {
        Logger.log.info(this.TAG + "pausePolling");
        this.isActive = false;
    }

    resumePolling() {
        Logger.log.info(this.TAG + "resumePolling");
        this.isActive = true;
    }
}

const PollerInstance = new Poller();
export default PollerInstance;