import React, {Component} from 'react';
import StowFlowModeView from "./view/StowFlowModeView";
import StowPackageContextProvider from "../context/StowPackageContext";

class StowFlowMode extends Component {

    render() {

        return (
            <div>
                <StowPackageContextProvider>
                    <StowFlowModeView history={this.props.history}/>
                </StowPackageContextProvider>
            </div>
        )
    }
}

export default StowFlowMode
