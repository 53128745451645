import React, {useContext, useEffect} from 'react';
import {SnapshotView} from "@amzn/dolphin-uiweb-framework";
import {CLOSE_SCAN_BAG_LOCATION_VIEW_STRINGS, COMMON_STRINGS} from "../../../../../constants/Strings";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box";
import {closeBag} from "../../action/CloseContainerAction";
import {injectIntl} from "react-intl";
import {AppNavigationContext} from "../../../../app-navigation/context/AppNavigationContext";
import {CloseContainerContext} from "../../context/CloseContainerContext";
import ScanView from "../../../../scan-view/ScanView";
import {ConfigContext} from "../../../../config/context/ConfigContext";
import HeadingView, {HEADING_TYPE} from "../../../../heading-view/HeadingView";
import {interceptBackButtonStop} from "../../../../bridge/BackButton";
import {PlayerContext} from "../../../../player/context/PlayerContext";
import {ScanTypes} from "../../../../player/PlayerConstant";

const CloseScanBagLocationView = (props) => {

    const {configViewModel} = useContext(ConfigContext)
    const {navActions: {setHelpOption}} = useContext(AppNavigationContext)
    const {closeContainerModel, closeContainerDispatch} = useContext(CloseContainerContext)
    const {statePlayer} = useContext(PlayerContext);

    const onScan = (input) => {
        interceptBackButtonStop();
        statePlayer.scanType = ScanTypes.CLOSE_BAG_LOCATION_SCAN
        closeBag(closeContainerDispatch, closeContainerModel.bagLabel, input, configViewModel.scope)
    }

    useEffect(() => {
        setHelpOption(true, {list: []});
        return () => setHelpOption(false, {});
    }, [])

    const init = {
        primaryTitle: CLOSE_SCAN_BAG_LOCATION_VIEW_STRINGS.close_bag_location_guidance,
        secondaryTitle: COMMON_STRINGS.scan_location,
        type: HEADING_TYPE.SECONDARY
    }

    return (
        <div>
            <Column spacingInset="medium" alignmentHorizontal="center">
                <HeadingView {...init}/>
            </Column>
            <Column spacingInset="small">
                <Box type="outline" spacingInset="small">
                    <Box spacingInset="small">
                        <Row>
                            <SnapshotView title={closeContainerModel.aisle} headerFontSize="Medium"
                                          description="Aisle"/>
                            <SnapshotView title={closeContainerModel.rack} headerFontSize="Medium"
                                          description="Overflow area"/>
                        </Row>
                        <SnapshotView title={closeContainerModel.bagLabel} headerFontSize="Medium"
                                      description="Bag Id"/>
                    </Box>
                </Box>
            </Column>
            <ScanView onScan={onScan}/>
        </div>
    )
}

export default injectIntl(CloseScanBagLocationView)
