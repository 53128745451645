import {RESPONSE} from "../../../../constants/ResponseCodes";

export const TEST_RESPONSE_200 = () => {
    return {
        status: 200,
        data: TEST_SUCCESS_RESPONSE
    }
}

const TEST_SUCCESS_RESPONSE = {
    "destinationLabel": "PS-D53-D54",
    responseCode: RESPONSE.SUCCESS,
    "scannableLabel": "TBA072804956404"
}
