import intlMessageDE from '../il8n/locales/de.json';
import intlMessageEN from '../il8n/locales/en.json';
import intlMessageES from '../il8n/locales/es.json';
import intlMessageFR from '../il8n/locales/fr.json';
import intlMessageHI from '../il8n/locales/hi.json';
import intlMessageIT from '../il8n/locales/it.json';
import intlMessageJP from '../il8n/locales/ja.json';
import intlMessagePT from '../il8n/locales/pt.json';
import intlMessageRO from '../il8n/locales/ro.json';
import intlMessageAR from '../il8n/locales/ar.json';
import intlMessageTR from '../il8n/locales/tr.json';
import intlMessageNL from '../il8n/locales/nl.json';
import {DataHandler} from "@amzn/dolphin-web-framework";

export const getLocale = () => {
    const locale = DataHandler.getUserLocale();
    switch (locale) {
        case "de":
        case "de-CH":
        case "de-AT":
        case "de-LU":
        case "de-LI":
            return "de"
        case "es":
        case "es-AR":
        case "es-GT":
        case "es-CR":
        case "es-PA":
        case "es-DO":
        case "es-MX":
        case "es-VE":
        case "es-CO":
        case "es-PE":
        case "es-EC":
        case "es-CL":
        case "es-UY":
        case "es-PY":
        case "es-BO":
        case "es-SV":
        case "es-HN":
        case "es-NI":
        case "es-PR":
            return "es"
        case "fr":
        case "fr-BE":
        case "fr-CA":
        case "fr-CH":
        case "fr-LU":
            return "fr"
        case "hi":
        case "hi-IN":
            return "hi"
        case "it":
        case "it-CH":
            return "it"
        case "ja":
        case "jp":
            return "ja"
        case "pt":
        case "pt-BR":
        case "pt-PT":
            return "pt"
        case "ro":
        case "ro-RO":
            return "ro"
        case "nl":
        case "nl-NL":
        case "nl-BE":
            return "nl"
        case "tr":
        case "tr-TR":
            return "tr"
        case "ar":
        case "ar-SA":
        case "ar-IQ":
        case "ar-EG":
        case "ar-LY":
        case "ar-DZ":
        case "ar-MA":
        case "ar-TN":
        case "ar-OM":
        case "ar-YE":
        case "ar-SY":
        case "ar-JO":
        case "ar-LB":
        case "ar-KW":
        case "ar-AE":
        case "ar-BH":
        case "ar-QA":
            return "ar"
        default:
            return "en"
    }
}

export const getDirection = () => {
    return getLocale() === "ar" ? "rtl" : "ltr";
}

export const getMessages = () => {
    const locale = getLocale();
    switch (locale) {
        case "de":
            return intlMessageDE
        case "en":
            return intlMessageEN
        case "es":
            return intlMessageES
        case "fr":
            return intlMessageFR
        case "hi":
            return intlMessageHI
        case "it":
            return intlMessageIT
        case "ja":
            return intlMessageJP
        case "pt":
            return intlMessagePT
        case "ro":
            return intlMessageRO
        case "ar":
            return intlMessageAR
        case "nl":
            return intlMessageNL
        case "tr":
            return intlMessageTR
        default:
            return intlMessageEN
    }
}
