import {
    getAttachContainerToDestinationResource,
    getRoutesForSortLocationResource,
    getScanBagResource,
    getScanLocationResource,
    getValidateContainerBeforeAttachResource
} from "../resource/PrepareContainerResource";
import {getScrubContainerResource} from "../../../scrub/resource/ScrubContainerResource";
import {CONTAINER_TYPE} from "../../../../constants/ContainerType";
import {SCRUB_CONTAINER_STRINGS} from "../../../../constants/Strings";

export const VALIDATE_BAG_FOR_OPEN_RESPONSE = "VALIDATE_BAG_FOR_OPEN_RESPONSE"
export const OPEN_BAG_RESPONSE = "OPEN_BAG_RESPONSE"
export const VALIDATE_CONTAINER_BEFORE_ATTACH = "VALIDATE_CONTAINER_BEFORE_ATTACH"
export const ATTACH_CONTAINER_TO_DESTINATION = "ATTACH_CONTAINER_TO_DESTINATION"
export const CLEAR_CONTEXT = "CLEAR_CONTEXT"
export const INVALID_LOCATION_SCANNED = "INVALID_LOCATION_SCANNED"
export const CHANGE_ACTIVE_VIEW = "CHANGE_ACTIVE_VIEW"
export const GET_ROUTES_FOR_SORT_LOCATION = "GET_ROUTES_FOR_SORT_LOCATION"
export const OPEN_BAG_AFTER_SCRUB_RESPONSE = "OPEN_BAG_AFTER_SCRUB_RESPONSE"
export const SCRUB_LOCATION_RESPONSE = "SCRUB_LOCATION_RESPONSE"
export const SCRUB_BAG_RESPONSE = "SCRUB_BAG_RESPONSE"

const validateBagForOpenResponse = (data, bagLabel) => {
    return {
        type: VALIDATE_BAG_FOR_OPEN_RESPONSE,
        data: {
            ...data,
            bagLabel: bagLabel
        }
    }
}

const openBagResponse = (data, workflow) => {
    return {
        type: OPEN_BAG_RESPONSE,
        data: {
            ...data,
            workflow: workflow
        }
    }
}

const openBagAfterScrubResponse = (data, workflow) => {
    return {
        type: OPEN_BAG_AFTER_SCRUB_RESPONSE,
        data: {
            ...data,
            workflow: workflow
        }
    }
}

const scrubLocationResponse = (response,destinationLabel) => {
    return {
        type: SCRUB_LOCATION_RESPONSE,
        data: {
            ...response,
            destinationLabel: destinationLabel
        }
    }
}

const scrubBagResponse = (response, displayBagId) => {
    return {
        type: SCRUB_BAG_RESPONSE,
        data: {
            ...response,
            displayBagId: displayBagId
        }
    }
}

const attachContainerToDestinationResponse = (data) => {
    return {
        type: ATTACH_CONTAINER_TO_DESTINATION,
        data
    }
}

export const validateBagForOpen = (dispatcher, bagLabel, scope) => {
    getScanBagResource(bagLabel, scope).then(response => {
        dispatcher(validateBagForOpenResponse(response, bagLabel))
    }).catch(() => {
    })
}

export const openBag = (dispatcher, bagScannableId, destinationScannableId, scope, workflow) => {
    getScanLocationResource(bagScannableId, destinationScannableId, scope).then(response => {
        dispatcher(openBagResponse(response, workflow))
    }).catch(() => {
    })
}

export const scrubBag = (dispatcher, bagLabel, displayBagId) => {
    getScrubContainerResource(bagLabel, CONTAINER_TYPE.BAG,
        SCRUB_CONTAINER_STRINGS.DUMMY_DESTINATION).then(response => {
        dispatcher(scrubBagResponse(response, displayBagId))
    })
}

export const scrubLocation = (dispatcher, destinationScannableId, destinationLabel) => {
    getScrubContainerResource(destinationScannableId, CONTAINER_TYPE.STACKING_AREA,
        SCRUB_CONTAINER_STRINGS.DUMMY_DESTINATION).then(response => {
            dispatcher(scrubLocationResponse(response, destinationLabel))
    })
}

export const openBagAfterScrub = (dispatcher, bagScannableId, destinationScannableId, scope, workflow) => {
    getScanLocationResource(bagScannableId, destinationScannableId, scope).then(response => {
        dispatcher(openBagAfterScrubResponse(response, workflow))
    }).catch(() => {
    })
}

export const validateContainerBeforeAttach = (dispatcher, request) => {
    getValidateContainerBeforeAttachResource(request).then(data => {
        dispatcher({
            type: VALIDATE_CONTAINER_BEFORE_ATTACH,
            data
        })
    }).catch(() => {
    })
}

export const attachContainerToDestination = (dispatcher, request) => {
    getAttachContainerToDestinationResource(request)
        .then(response => {
            dispatcher(attachContainerToDestinationResponse(response))
        }).catch(() => {
    })
}

export const getRoutesForSortLocation = (dispatcher, request) => {
    getRoutesForSortLocationResource(request)
        .then(data => {
            dispatcher({
                type: GET_ROUTES_FOR_SORT_LOCATION,
                data
            })
        }).catch(() => {
    })
}

export const clearContext = (dispatcher) => {
    dispatcher({type: CLEAR_CONTEXT})
}

export const invalidLocationScanned = (dispatcher, data) => {
    dispatcher({
        type: INVALID_LOCATION_SCANNED,
        data
    })
}
