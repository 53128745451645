import React, {createContext, useReducer} from 'react'
import {StowPackageReducer} from "../reducer/StowPackageReducer";
import {VIEW} from "../../workflow/Workflow";

export const StowPackageContext = createContext();

export const model = {
    view: VIEW.STOW_PACKAGE
}

const StowPackageContextProvider = (props) => {

    const [stowPackageModel, stowPackageDispatch] = useReducer(StowPackageReducer, model)
    return (
        <StowPackageContext.Provider value={{stowPackageModel, stowPackageDispatch}}>
            {props.children}
        </StowPackageContext.Provider>
    )
}

export default StowPackageContextProvider
