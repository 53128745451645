import React, {useContext, useEffect} from "react";
import {NOTIFICATION_STRING} from "../../../constants/Strings";
import {SCOPE} from "../../../constants/Scope";
import {FeatureManager} from "@amzn/dolphin-web-framework";
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import {CONSTANT} from "../../../constants/Constants";
import {ROUTING} from "../../../constants/Routing";
import {StowSelectionContext} from "../context/StowSelectionContext";
import {isNonNull} from "../../utils/Util";
import {NotificationContext} from "../../notification/context/NotificationContext";
import {enablePrinting, isPrinterConnected} from "./con-package/data/StowPrintingData";
import {getSalTemplateForPrinter} from "../action/StowSelectionAction";
import {NOTIFICATION_TYPE} from "../../../constants/NotificationType";
import {PRINTER} from "../../utils/PrinterUtils";
import {WORKFLOW} from "../../workflow/Workflow";
import {ConfigContext} from "../../config/context/ConfigContext";
import {getTask} from "./data/TaskData";
import {clearWorkflow, setGlobalContext} from "../../config/action/ConfigAction";
import {injectIntl} from "react-intl";

const StowConveyableSelectionView = (props) => {

    const {stowSelectionModel, stowSelectionDispatch} = useContext(StowSelectionContext)
    const {notificationActions: {setNotification}} = useContext(NotificationContext)
    const {configViewModel, configViewDispatch} = useContext(ConfigContext)

    const handleSALPrinting = () => {
        if (isPrinterConnected()) {
            enablePrinting()
            getSalTemplateForPrinter(stowSelectionDispatch, PRINTER.PRINTER_TYPE_AVERY)
        } else {
            const notification = {
                type: NOTIFICATION_TYPE.ERROR,
                message: NOTIFICATION_STRING.ALERT_ERROR_PRINTER_NOT_CONNECTED
            }
            setNotification(notification)
            return props.history.push(ROUTING.STOW_CON_PACKAGE)
        }
    }

    const handleNavigation = (packageType) => {
        if (CONSTANT.CON_PACKAGE === packageType) {
            setGlobalContext(configViewDispatch, {workflow: WORKFLOW.CON_PACKAGE})
            handleSALPrinting()
        } else if (CONSTANT.NON_CON_PACKAGE === packageType) {
            props.history.push(ROUTING.STOW_PACKAGE)
        }
    }

    useEffect(() => {
        clearWorkflow(configViewDispatch)
    }, [])

    useEffect(() => {
        if (isNonNull(stowSelectionModel.notification)) {
            setNotification(stowSelectionModel.notification)
        }
        if (isNonNull(stowSelectionModel.nextRoute)) {
            props.history.push(stowSelectionModel.nextRoute)
        }
    }, [stowSelectionModel]);

    const isPrintSALAtPickEnabled = () => {
        if(configViewModel.scope === SCOPE.AR_NEXT_GEN && FeatureManager.isFeatureEnabled(FeatureManager.Features.AR_NEXT_GEN_PRINT_SAL_AT_PICK_ENABLED)) {
            props.history.push(ROUTING.STOW_PACKAGE)
        }
    }

    const renderView = () => {
        isPrintSALAtPickEnabled();
        return <div>
            <TaskView {...getTask(configViewModel.scope, handleNavigation, props)}/>
        </div>
    }

    return renderView()
}

export default injectIntl(StowConveyableSelectionView)
