import {NOTIFICATION_TYPE} from "../../../constants/NotificationType";
import {COMMON_STRINGS} from "../../../constants/Strings";
import {RESPONSE} from "../../../constants/ResponseCodes";
import {COMMON_ACTIONS} from "../../../constants/ActionType";

export const GET_SCOPE_FOR_NODE_RESPONSE = "GET_SCOPE_FOR_NODE_RESPONSE";
export const SET_GLOBAL_CONTEXT = "SET_GLOBAL_CONTEXT"
export const CLEAR_GLOBAL_CONTEXT = "CLEAR_GLOBAL_CONTEXT"
export const INITIALIZE_SBL = "INITIALIZE_SBL"
export const SET_SCOPE = "SET_SCOPE";

export const ConfigReducer = (state, action) => {
    switch (action.type) {
        case GET_SCOPE_FOR_NODE_RESPONSE:
            const notification = getNotification(action.data.responseCode)
            return {...state, scope: action.data.scope, notification: notification, loading: false, workflow: undefined};
        case SET_GLOBAL_CONTEXT:
            return {...state, ...action.data}
        case COMMON_ACTIONS.LOADING:
            return {
                ...state,
                loading: true
            }
        case CLEAR_GLOBAL_CONTEXT:
            return {scope: state.scope, sblEndpoint: state.sblEndpoint}
        case INITIALIZE_SBL:
            return {...state , sblEndpoint: action.data.sblEndpoint}
        case SET_SCOPE:
            return {...state, scope: action.data.scope};
        default:
            return state;
    }
}

const getNotification = (responseCode) => {
    if (RESPONSE.SUCCESS !== responseCode) {
        return {
            type: NOTIFICATION_TYPE.ERROR,
            message: COMMON_STRINGS.default_error
        }
    }
}
