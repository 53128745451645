import {PlayerHelper} from "@amzn/dolphin-web-framework";
import {ApplicationActions} from "../action/ApplicationActions";
import {RESPONSE} from "../../../constants/ResponseCodes";
import {Logger} from "@amzn/dolphin-web-framework";
import {COMMON_STRINGS, HELP_CENTER_STRINGS, LANDING_VIEW_STRINGS, SCRUB_CONTAINER_STRINGS} from "../../../constants/Strings";
import {STOW_AREA_OPERATION} from "../../../constants/StowAreaOperation";
import {CONTAINER_TYPE} from "../../../constants/ContainerType";
import {PROBLEM_SOLVE} from "../../../constants/ProblemSolveCodes";
import {WORKFLOW} from "../../workflow/Workflow";
import {SCOPE} from "../../../constants/Scope";
import {mapErrorToPlayerEnum, HelpTypes} from "../PlayerConstant";

export const PlayerReducer = (state, action) => {
    try{
        if(state.scope === SCOPE.AMZL || state.scope === SCOPE.SSD) {
            if(window && window.DolphinPlayer) {
                let event, nextState;
                switch (action.type) {
                    case ApplicationActions.TASK_SELECTION: {
                        if(action.data.operation === LANDING_VIEW_STRINGS.manage_stow_area) {
                            event = new window.DolphinPlayer.Stow.TaskSelectedEvent(window.DolphinPlayer.Stow.StowTask.MANAGE_STOW_AREA);
                            nextState = new window.DolphinPlayer.Stow.ManageStowAreaOptionsState();
                            PlayerHelper.sendMessage(event, nextState);
                            return {
                                ...state,
                                helpType: HelpTypes.DEFAULT,
                                backState: nextState,
                                scanType: undefined
                            };
                        }
                        else if (action.data.operation === LANDING_VIEW_STRINGS.stow_packages) {
                            event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.TaskSelectedEvent(window.DolphinPlayer.Stow.StowTask.STOW_PACKAGES) : new window.DolphinPlayer.DrsStow.TaskSelectedEvent(window.DolphinPlayer.DrsStow.StowTask.STOW_PACKAGES);
                            nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.PackageScanState(state.isFirstPackage, false) : new window.DolphinPlayer.DrsStow.PackageScanState(state.isFirstPackage, false);
                            PlayerHelper.sendMessage(event, nextState);
                            return {
                                ...state,
                                backState: nextState,
                                helpType: (state.scope === SCOPE.AMZL) ? HelpTypes.PACKAGE_SCAN : HelpTypes.DEFAULT,
                                scanType: undefined
                            };
                        }
                        else if (action.data.operation === SCRUB_CONTAINER_STRINGS.scrub_location_container) {
                            event = new window.DolphinPlayer.Stow.TaskSelectedEvent(window.DolphinPlayer.Stow.StowTask.SCRUB);
                            nextState = new window.DolphinPlayer.Stow.ScrubOptionsState();
                            PlayerHelper.sendMessage(event, nextState);
                            return {
                                ...state,
                                backState: nextState,
                                helpType: HelpTypes.CONTAINER_CLOSE,
                                scanType: undefined
                            };
                        }
                        else if (action.data.operation === LANDING_VIEW_STRINGS.prepare_stow_location) {
                            event = new window.DolphinPlayer.DrsStow.TaskSelectedEvent(window.DolphinPlayer.DrsStow.StowTask.PREPARE_STOW_LOCATION);
                            nextState = new window.DolphinPlayer.DrsStow.CartScanState(state.isFirstCart, false, false);
                            PlayerHelper.sendMessage(event, nextState);
                            return {
                                ...state,
                                backState: nextState,
                                helpType: HelpTypes.DEFAULT
                            };
                        }
                        return state;
                    }
                    case ApplicationActions.BACK_SELECTED: {
                        event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BackActionSelectedEvent(): new window.DolphinPlayer.DrsStow.BackActionSelectedEvent();
                        nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BackActionConfirmState(): new window.DolphinPlayer.DrsStow.BackActionConfirmState();
                        PlayerHelper.sendMessage(event, nextState);
                        return state;
                    }
                    case ApplicationActions.BACK_OPTION_SELECTED: {
                        if(action.data.operation === COMMON_STRINGS.CONTINUE_STOWING) {
                            event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.ActionAcknowledgedEvent(): new window.DolphinPlayer.DrsStow.ActionAcknowledgedEvent();
                            nextState = state.backState
                            PlayerHelper.sendMessage(event, nextState);
                        } else if (action.data.operation === COMMON_STRINGS.END_STOWING) {
                            event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.ActionDeclinedEvent(): new window.DolphinPlayer.DrsStow.ActionDeclinedEvent();
                            nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.TaskSelectState(): new window.DolphinPlayer.DrsStow.TaskSelectState();
                            PlayerHelper.sendMessage(event, nextState);
                        } else if (action.data.operation === COMMON_STRINGS.CANCEL){
                            event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.ActionAcknowledgedEvent(): new window.DolphinPlayer.DrsStow.ActionAcknowledgedEvent();
                            nextState = state.backState
                            PlayerHelper.sendMessage(event, nextState);
                        }
                        return {...state, backState: nextState};
                    }
                    case ApplicationActions.HELP_SELECTED: {
                        if(state.helpType === HelpTypes.CONTAINER_CLOSE) {
                            event = new window.DolphinPlayer.Stow.HelpCenterOpenedEvent();
                            nextState = new window.DolphinPlayer.Stow.HelpCenterState(window.DolphinPlayer.Stow.HelpCenterContext.CONTAINER_CLOSE);
                            PlayerHelper.sendMessage(event, nextState);
                        } else if(state.helpType === HelpTypes.CONTAINER_PREPARE) {
                            event = new window.DolphinPlayer.Stow.HelpCenterOpenedEvent();
                            nextState = new window.DolphinPlayer.Stow.HelpCenterState(window.DolphinPlayer.Stow.HelpCenterContext.CONTAINER_PREPARE);
                            PlayerHelper.sendMessage(event, nextState);
                        } else if(state.helpType === HelpTypes.PACKAGE_SCAN) {
                            event = new window.DolphinPlayer.Stow.HelpCenterOpenedEvent();
                            nextState = new window.DolphinPlayer.Stow.HelpCenterState(window.DolphinPlayer.Stow.HelpCenterContext.PACKAGE_SCAN);
                            PlayerHelper.sendMessage(event, nextState);
                        } else if(state.helpType === HelpTypes.LOCATION_SCAN) {
                            event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.HelpCenterOpenedEvent(): new window.DolphinPlayer.DrsStow.HelpCenterOpenedEvent();
                            nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.HelpCenterState(window.DolphinPlayer.Stow.HelpCenterContext.LOCATION_SCAN): new window.DolphinPlayer.DrsStow.HelpCenterState(window.DolphinPlayer.DrsStow.HelpCenterContext.LOCATION_SCAN);
                            PlayerHelper.sendMessage(event, nextState);
                        } else if(state.helpType === HelpTypes.DEFAULT) {
                            event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.HelpCenterOpenedEvent(): new window.DolphinPlayer.DrsStow.HelpCenterOpenedEvent();
                            nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.HelpCenterState(window.DolphinPlayer.Stow.HelpCenterContext.NOT_IMPLEMENTED): new window.DolphinPlayer.DrsStow.HelpCenterState(window.DolphinPlayer.DrsStow.HelpCenterContext.NOT_IMPLEMENTED);
                            PlayerHelper.sendMessage(event, nextState);
                        }
                        return state;
                    }
                    case ApplicationActions.HELP_OPTION_SELECTED: {
                        if(action.data.operation === HELP_CENTER_STRINGS.CLOSE_CONTAINER_HELP) {
                            event = new window.DolphinPlayer.Stow.HelpCenterOptionSelectedEvent(window.DolphinPlayer.Stow.HelpCenterOption.CLOSE_DIFFERENT_CONTAINER);
                            nextState = new window.DolphinPlayer.Stow.ContainerCloseOptionsState();
                        } else if(action.data.operation === HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP) {
                            event = new window.DolphinPlayer.Stow.HelpCenterOptionSelectedEvent(window.DolphinPlayer.Stow.HelpCenterOption.PREP_DIFFERENT_CONTAINER);
                            nextState = new window.DolphinPlayer.Stow.ContainerPrepOptionsState();
                        } else if(action.data.operation === HELP_CENTER_STRINGS.MARK_FOR_PROBLEM_SOLVE) {
                            event = new window.DolphinPlayer.Stow.HelpCenterOptionSelectedEvent(window.DolphinPlayer.Stow.HelpCenterOption.PROBLEM_SOLVE);
                            nextState = new window.DolphinPlayer.Stow.ProblemSolveState();
                        } else if(action.data.operation === HELP_CENTER_STRINGS.BAG_FULL) {
                            event = new window.DolphinPlayer.Stow.HelpCenterOptionSelectedEvent(window.DolphinPlayer.Stow.HelpCenterOption.CLOSE_BAG);
                            nextState = new window.DolphinPlayer.Stow.BufferPlacementState(window.DolphinPlayer.Stow.BufferPlacementReason.BAG_FULL);
                        } else if(action.data.operation === HELP_CENTER_STRINGS.DAMAGED_PACKAGE) {
                            event = new window.DolphinPlayer.DrsStow.HelpCenterOptionSelectedEvent(window.DolphinPlayer.DrsStow.HelpCenterOption.PACKAGE_DAMAGED);
                            nextState = new window.DolphinPlayer.DrsStow.ProblemSolveBinScanState(window.DolphinPlayer.DrsStow.ProblemSolveErrorType.PACKAGE_DAMAGED);
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            backState: nextState,
                            helpType: HelpTypes.DEFAULT
                        };
                    }
                    case ApplicationActions.HELP_CLOSED: {
                        event = new window.DolphinPlayer.Stow.ActionDeclinedEvent();
                        if(state.backState === undefined) {
                            nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.TaskSelectState(): new window.DolphinPlayer.DrsStow.TaskSelectState()
                        } else {
                            nextState = state.backState
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            backState: nextState,
                        }
                    }

                    case ApplicationActions.CONTAINER_TASK_SELECTION: {
                        if(action.data.operation === STOW_AREA_OPERATION.PREPARE_CONTAINER) {
                            event = new window.DolphinPlayer.Stow.ManageStowAreaOptionSelectedEvent(window.DolphinPlayer.Stow.ManageStowAreaOption.PREPARE_CONTAINERS);
                            nextState = new window.DolphinPlayer.Stow.ContainerPrepOptionsState();
                        } else if (action.data.operation === STOW_AREA_OPERATION.CLOSE_CONTAINER) {
                            event = new window.DolphinPlayer.Stow.ManageStowAreaOptionSelectedEvent(window.DolphinPlayer.Stow.ManageStowAreaOption.CLOSE_CONTAINERS);
                            nextState = new window.DolphinPlayer.Stow.ContainerCloseOptionsState();
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            backState: nextState,
                            helpType: HelpTypes.DEFAULT
                        };
                    }
                    case ApplicationActions.CONTAINER_SELECTION: {
                        if(action.data.operation === STOW_AREA_OPERATION.PREPARE_CONTAINER) {
                            if(action.data.type === CONTAINER_TYPE.BAG){
                                event = new window.DolphinPlayer.Stow.ContainerPrepOptionSelectedEvent(window.DolphinPlayer.Stow.ContainerPrepOption.BAG);
                                nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.BAG, window.DolphinPlayer.Stow.ContainerScanAction.OPEN, false);
                                PlayerHelper.sendMessage(event, nextState);
                            } else if(action.data.type === CONTAINER_TYPE.CART){
                                event = new window.DolphinPlayer.Stow.ContainerPrepOptionSelectedEvent(window.DolphinPlayer.Stow.ContainerPrepOption.CART);
                                nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.CART, window.DolphinPlayer.Stow.ContainerScanAction.OPEN, false);
                                PlayerHelper.sendMessage(event, nextState);
                            } else if(action.data.type === CONTAINER_TYPE.PALLET){
                                event = new window.DolphinPlayer.Stow.ContainerPrepOptionSelectedEvent(window.DolphinPlayer.Stow.ContainerPrepOption.PALLET);
                                nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.PALLET, window.DolphinPlayer.Stow.ContainerScanAction.OPEN, false);
                                PlayerHelper.sendMessage(event, nextState);
                            }
                            return {
                                ...state,
                                backState: nextState,
                                helpType: HelpTypes.CONTAINER_PREPARE
                            };
                        }
                        else if(action.data.operation === STOW_AREA_OPERATION.CLOSE_CONTAINER) {
                            if(action.data.type === CONTAINER_TYPE.BAG){
                                event = new window.DolphinPlayer.Stow.ContainerPrepOptionSelectedEvent(window.DolphinPlayer.Stow.ContainerPrepOption.BAG);
                                nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.BAG, window.DolphinPlayer.Stow.ContainerScanAction.CLOSE, false);
                                PlayerHelper.sendMessage(event, nextState);
                            } else if(action.data.type === CONTAINER_TYPE.CART){
                                event = new window.DolphinPlayer.Stow.ContainerPrepOptionSelectedEvent(window.DolphinPlayer.Stow.ContainerPrepOption.CART);
                                nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.CART, window.DolphinPlayer.Stow.ContainerScanAction.CLOSE, false);
                                PlayerHelper.sendMessage(event, nextState);
                            } else if(action.data.type === CONTAINER_TYPE.PALLET){
                                event = new window.DolphinPlayer.Stow.ContainerPrepOptionSelectedEvent(window.DolphinPlayer.Stow.ContainerPrepOption.PALLET);
                                nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.PALLET, window.DolphinPlayer.Stow.ContainerScanAction.CLOSE, false);
                                PlayerHelper.sendMessage(event, nextState);
                            }
                            return {
                                ...state,
                                backState: nextState,
                                helpType: HelpTypes.CONTAINER_CLOSE
                            };
                        }
                        return state;
                    }
                    case ApplicationActions.CONTAINER_SCAN: {
                        if(action.data.operation === STOW_AREA_OPERATION.PREPARE_CONTAINER) {
                            if(action.data.type === CONTAINER_TYPE.BAG) {
                                if(action.data.errorResponse === RESPONSE.SUCCESS) {
                                    event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(true);
                                    nextState = (action.data.location !== undefined) ? new window.DolphinPlayer.Stow.LocationScanState(window.DolphinPlayer.Stow.LocationScanType.AISLE)
                                        : new window.DolphinPlayer.Stow.LocationScanState();
                                    PlayerHelper.sendMessage(event, nextState);
                                    return {
                                        ...state,
                                        backState: nextState,
                                        scanType: undefined,
                                        helpType: HelpTypes.DEFAULT
                                    };
                                }
                                else if(action.data.errorResponse === RESPONSE.NON_EMPTY_BAG) {
                                    event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(false);
                                    nextState = new window.DolphinPlayer.Stow.ScrubBagNotEmptyState();
                                    PlayerHelper.sendMessage(event, nextState);
                                    return {
                                        ...state,
                                        backState: nextState,
                                        scanType: undefined,
                                        helpType: HelpTypes.CONTAINER_PREPARE
                                    };
                                }
                                else {
                                    event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(false);
                                    nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.BAG,
                                        window.DolphinPlayer.Stow.ContainerScanAction.OPEN, true, mapErrorToPlayerEnum("CONTAINER_SCAN", action.data.errorResponse));
                                    PlayerHelper.sendMessage(event, nextState);
                                    return {
                                        ...state,
                                        backState: nextState,
                                        scanType: undefined,
                                        helpType: HelpTypes.CONTAINER_PREPARE
                                    };
                                }
                            }
                            else if(action.data.type === CONTAINER_TYPE.CART) {
                                if(action.data.errorResponse === RESPONSE.SUCCESS) {
                                    event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(true);
                                    nextState = new window.DolphinPlayer.Stow.LocationScanState();
                                    PlayerHelper.sendMessage(event, nextState);
                                    return {...state, backState: nextState, scanType: undefined, helpType: HelpTypes.DEFAULT};
                                }
                                else {
                                    event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(false);
                                    nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.CART,
                                        window.DolphinPlayer.Stow.ContainerScanAction.OPEN, true, mapErrorToPlayerEnum("CONTAINER_SCAN", action.data.errorResponse));
                                    PlayerHelper.sendMessage(event, nextState);
                                    return {...state, backState: nextState, scanType: undefined, helpType: HelpTypes.CONTAINER_PREPARE};
                                }
                            }
                            else if(action.data.type === CONTAINER_TYPE.PALLET) {
                                if(action.data.errorResponse === RESPONSE.SUCCESS) {
                                    event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(true);
                                    nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.PALLET,
                                        window.DolphinPlayer.Stow.ContainerScanAction.OPEN, false);
                                    PlayerHelper.sendMessage(event, nextState);
                                    return {
                                        ...state,
                                        backState: nextState,
                                        scanType: undefined,
                                        helpType: HelpTypes.DEFAULT
                                    };
                                }
                                else {
                                    event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(false);
                                    nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.PALLET,
                                        window.DolphinPlayer.Stow.ContainerScanAction.OPEN, true, mapErrorToPlayerEnum("CONTAINER_SCAN", action.data.errorResponse));
                                    PlayerHelper.sendMessage(event, nextState);
                                    return {
                                        ...state,
                                        backState: nextState,
                                        scanType: undefined,
                                        helpType: HelpTypes.CONTAINER_PREPARE
                                    };
                                }
                            }
                        }
                        else if(action.data.operation === STOW_AREA_OPERATION.CLOSE_CONTAINER) {
                            if(action.data.errorResponse === RESPONSE.SUCCESS) {
                                event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(true);
                                switch (action.data.type) {
                                    case CONTAINER_TYPE.BAG:
                                        nextState = new window.DolphinPlayer.Stow.LocationScanState(window.DolphinPlayer.Stow.LocationScanType.BUFFER);
                                        break;
                                    case CONTAINER_TYPE.CART:
                                        nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.CART,
                                            window.DolphinPlayer.Stow.ContainerScanAction.CLOSE, false);
                                        break;
                                    case CONTAINER_TYPE.PALLET:
                                        nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.PALLET,
                                            window.DolphinPlayer.Stow.ContainerScanAction.CLOSE, false);
                                        break;
                                    default: return state;
                                }
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    scanType: undefined,
                                    helpType: HelpTypes.DEFAULT
                                };
                            }
                            else {
                                event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(false);
                                switch (action.data.type) {
                                    case CONTAINER_TYPE.BAG:
                                        nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.BAG,
                                            window.DolphinPlayer.Stow.ContainerScanAction.CLOSE, true, mapErrorToPlayerEnum("CONTAINER_SCAN", action.data.errorResponse));
                                        break;
                                    case CONTAINER_TYPE.CART:
                                        nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.CART,
                                            window.DolphinPlayer.Stow.ContainerScanAction.CLOSE, true, mapErrorToPlayerEnum("CONTAINER_SCAN", action.data.errorResponse));
                                        break;
                                    case CONTAINER_TYPE.PALLET:
                                        nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.PALLET,
                                            window.DolphinPlayer.Stow.ContainerScanAction.CLOSE, true, mapErrorToPlayerEnum("CONTAINER_SCAN", action.data.errorResponse));
                                        break;
                                    default: return state;
                                }
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    scanType: undefined,
                                    helpType: HelpTypes.CONTAINER_CLOSE
                                };
                            }
                        }
                        return state;
                    }
                    case ApplicationActions.CONTAINER_LOCATION_SCAN: {
                        if(action.data.operation === STOW_AREA_OPERATION.PREPARE_CONTAINER) {
                            if(action.data.type === CONTAINER_TYPE.BAG) {
                                if(action.data.errorResponse === RESPONSE.SUCCESS) {
                                    event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(true);
                                    if(action.data.workflow === WORKFLOW.OPEN_BAG_AT_PACKAGE_SCAN || action.data.workflow === WORKFLOW.OPEN_BAG_IN_SAME_LOCATION) {
                                        nextState =new window.DolphinPlayer.Stow.PackageScanState(state.isFirstPackage, false);
                                        PlayerHelper.sendMessage(event, nextState);
                                        return {
                                            ...state,
                                            backState: nextState,
                                            scanType: undefined,
                                            helpType: HelpTypes.PACKAGE_SCAN
                                        };
                                    }
                                    nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.BAG,
                                        window.DolphinPlayer.Stow.ContainerScanAction.OPEN, false);
                                }
                                else if(action.data.errorResponse === RESPONSE.NON_EMPTY_LOCATION) {
                                    event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(false);
                                    nextState = new window.DolphinPlayer.Stow.ScrubLocationNotEmptyState();
                                }
                                else if(action.data.errorResponse === RESPONSE.INVALID_SCAN_DONE) {
                                    event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(false);
                                    nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.BAG,
                                        window.DolphinPlayer.Stow.ContainerScanAction.OPEN, true, mapErrorToPlayerEnum("CONTAINER_LOCATION_SCAN", action.data.errorResponse));
                                }
                                else if(action.data.errorResponse === RESPONSE.INVALID_MOVE) {
                                    event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(false);
                                    nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.BAG,
                                        window.DolphinPlayer.Stow.ContainerScanAction.OPEN, true, mapErrorToPlayerEnum("CONTAINER_LOCATION_SCAN", action.data.errorResponse));
                                }
                                else {
                                    event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(false);
                                    nextState = new window.DolphinPlayer.Stow.LocationScanState();
                                    PlayerHelper.sendMessage(event, nextState);
                                    return {
                                        ...state,
                                        backState: nextState,
                                        scanType: undefined,
                                        helpType: HelpTypes.DEFAULT
                                    };
                                }
                                PlayerHelper.sendMessage(event, nextState);
                            }
                            else if(action.data.type === CONTAINER_TYPE.CART) {
                                if(action.data.errorResponse === RESPONSE.SUCCESS) {
                                    event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(true);
                                    nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.CART,
                                        window.DolphinPlayer.Stow.ContainerScanAction.OPEN, false);
                                }
                                else {
                                    event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(false);
                                    nextState = new window.DolphinPlayer.Stow.LocationScanState();
                                    PlayerHelper.sendMessage(event, nextState);
                                    return {
                                        ...state,
                                        backState: nextState,
                                        scanType: undefined,
                                        helpType: HelpTypes.DEFAULT
                                    };
                                }
                                PlayerHelper.sendMessage(event, nextState);
                            }
                            return {
                                ...state,
                                backState: nextState,
                                scanType: undefined,
                                helpType: HelpTypes.CONTAINER_PREPARE
                            };
                        }
                        else if(action.data.operation === STOW_AREA_OPERATION.CLOSE_CONTAINER) {
                            if (action.data.errorResponse === RESPONSE.SUCCESS) {
                                event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(true);
                                nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.BAG,
                                    window.DolphinPlayer.Stow.ContainerScanAction.OPEN, false);
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    scanType: undefined,
                                    helpType: HelpTypes.CONTAINER_PREPARE
                                };
                            }
                            else {
                                event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(false);
                                nextState = new window.DolphinPlayer.Stow.LocationScanState();
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    scanType: undefined,
                                    helpType: HelpTypes.DEFAULT
                                };
                            }
                        }
                        return state;
                    }

                    case ApplicationActions.CONTAINER_CART_SCAN: {
                        switch(action.data.errorResponse) {
                            case RESPONSE.SUCCESS:
                                event = new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(true);
                                nextState = new window.DolphinPlayer.DrsStow.LocationScanState(false, false);
                                PlayerHelper.sendMessage(event, nextState);
                                return {...state, scanType: undefined, backState: nextState};
                            case RESPONSE.NON_EMPTY_CART:
                                event = new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(false);
                                nextState = new window.DolphinPlayer.DrsStow.CartReplaceState();
                                PlayerHelper.sendMessage(event, nextState);
                                return {...state, scanType: undefined, backState: nextState};
                            case RESPONSE.CART_ALREADY_ATTACHED:
                            case RESPONSE.INVALID_SCAN_DONE:
                            default:
                                event = new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(false);
                                nextState = new window.DolphinPlayer.DrsStow.CartScanState(state.isFirstCart, false, true, mapErrorToPlayerEnum("CART_SCAN", action.data.errorResponse));
                                PlayerHelper.sendMessage(event, nextState);
                                return {...state, scanType: undefined, backState: nextState};
                        }
                    }
                    case ApplicationActions.REPLACE_CART: {
                        if(state.scope === SCOPE.SSD) {
                            event = new window.DolphinPlayer.DrsStow.ActionAcknowledgedEvent();
                            nextState = new window.DolphinPlayer.DrsStow.CartScanState(state.isFirstCart, false, false);
                            PlayerHelper.sendMessage(event, nextState);
                            return {
                                ...state,
                                backState: nextState,
                                scanType: undefined,
                                helpType: HelpTypes.DEFAULT
                            };
                        }
                        return state;
                    }
                    case ApplicationActions.CONTAINER_CART_LOCATION_SCAN: {
                        if(state.scope === SCOPE.SSD) {
                            if(action.data.errorResponse === RESPONSE.SUCCESS) {
                                event = new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(true);
                                if(action.data.workflow === WORKFLOW.ATTACH_CART) {
                                    nextState = new window.DolphinPlayer.DrsStow.CartScanState(state.isFirstCart, false, false);
                                } else {
                                    nextState = new window.DolphinPlayer.DrsStow.PackageScanState(state.isFirstPackage, false);
                                }
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    scanType: undefined,
                                    isFirstCart: false
                                };
                            }
                            else {
                                event = new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(false);
                                nextState = new window.DolphinPlayer.DrsStow.CartScanState(state.isFirstCart, false, true, mapErrorToPlayerEnum("CART_LOCATION_SCAN", action.data.errorResponse));
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                };
                            }
                        }
                        return state;
                    }

                    case ApplicationActions.CONTAINER_SCRUB_OPTION_SELECTION: {
                        if(action.data.operation === SCRUB_CONTAINER_STRINGS.open_bag_error_scrub_bag) {
                            event = new window.DolphinPlayer.Stow.ScrubBagNotEmptyOptionSelectedEvent(window.DolphinPlayer.Stow.ScrubBagNotEmptyOption.SCRUB_EMPTY_BAG);
                            nextState = new window.DolphinPlayer.Stow.ConfirmBagScrubState();
                        }
                        else if (action.data.operation === SCRUB_CONTAINER_STRINGS.open_bag_error_cancel_option) {
                            event = new window.DolphinPlayer.Stow.ScrubBagNotEmptyOptionSelectedEvent(window.DolphinPlayer.Stow.ScrubBagNotEmptyOption.CANCEL);
                            nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.BAG,
                                window.DolphinPlayer.Stow.ContainerScanAction.OPEN, false);
                        }
                        else if (action.data.operation === SCRUB_CONTAINER_STRINGS.open_bag_error_scrub_location) {
                            event = new window.DolphinPlayer.Stow.ScrubLocationNotEmptyOptionSelectedEvent(window.DolphinPlayer.Stow.ScrubLocationNotEmptyOption.SCRUB_EMPTY_LOCATION);
                            nextState = new window.DolphinPlayer.Stow.ConfirmLocationScrubState();
                        }
                        else if (action.data.operation === SCRUB_CONTAINER_STRINGS.open_bag_location_error_cancel_option) {
                            event = new window.DolphinPlayer.Stow.ScrubLocationNotEmptyOptionSelectedEvent(window.DolphinPlayer.Stow.ScrubLocationNotEmptyOption.CANCEL);
                            nextState = new window.DolphinPlayer.Stow.LocationScanState();
                            PlayerHelper.sendMessage(event, nextState);
                            return {
                                ...state,
                                backState: nextState,
                            };
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            backState: nextState,
                            helpType: HelpTypes.CONTAINER_PREPARE,
                        };
                    }
                    case ApplicationActions.CONTAINER_SCRUB_BAG_CONFIRM_VIEW: {
                        if(action.data.operation === SCRUB_CONTAINER_STRINGS.scrub_cancel_button) {
                            event = new window.DolphinPlayer.Stow.ActionDeclinedEvent();
                            nextState = new window.DolphinPlayer.Stow.BagProblemSolveState();
                        }
                        else if (action.data.operation === SCRUB_CONTAINER_STRINGS.scrub_confirm_button) {
                            event = new window.DolphinPlayer.Stow.ActionAcknowledgedEvent();
                            nextState = new window.DolphinPlayer.Stow.LocationScanState();
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            backState: nextState,
                            helpType: HelpTypes.CONTAINER_PREPARE,
                        };
                    }
                    case ApplicationActions.CONTAINER_SCRUB_BAG_DECLINED: {
                        event = new window.DolphinPlayer.Stow.ActionAcknowledgedEvent();
                        nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.BAG, window.DolphinPlayer.Stow.ContainerScanAction.OPEN, false);
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            backState: nextState,
                        };
                    }
                    case ApplicationActions.CONTAINER_SCRUB_BAG_ACCEPTED: {
                        event = new window.DolphinPlayer.Stow.ActionAcknowledgedEvent();
                        nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.BAG,
                            window.DolphinPlayer.Stow.ContainerScanAction.OPEN, false);
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            backState: nextState,
                        };
                    }
                    case ApplicationActions.CONTAINER_SCRUB_LOCATION_CONFIRM_VIEW: {
                        if(action.data.operation === SCRUB_CONTAINER_STRINGS.scrub_cancel_button) {
                            event = new window.DolphinPlayer.Stow.ActionDeclinedEvent();
                            nextState = new window.DolphinPlayer.Stow.ScrubLocationNotEmptyState();

                        } else if (action.data.operation === SCRUB_CONTAINER_STRINGS.scrub_confirm_button) {
                            event = new window.DolphinPlayer.Stow.ActionAcknowledgedEvent();
                            nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.BAG,
                                window.DolphinPlayer.Stow.ContainerScanAction.OPEN, false);
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            backState: nextState,
                        };
                    }

                    case ApplicationActions.PACKAGE_SCAN: {
                        switch (action.data.errorResponse) {
                            case RESPONSE.SUCCESS: {
                                event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BarcodeScannedEvent(true): new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(true);
                                nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.LocationScanState(window.DolphinPlayer.Stow.LocationScanType.AISLE): new window.DolphinPlayer.DrsStow.LocationScanState(true, false);
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: HelpTypes.LOCATION_SCAN
                                };
                            }
                            case RESPONSE.BAG_NOT_OPEN:
                            case RESPONSE.CART_NOT_ATTACHED:
                            case RESPONSE.CONTAINER_DOES_NOT_EXIST: {
                                event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BarcodeScannedEvent(false): new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(false);
                                nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BufferPlacementState(mapErrorToPlayerEnum("PACKAGE_SCAN", action.data.errorResponse, SCOPE.AMZL)): new window.DolphinPlayer.DrsStow.BufferPlacementState(mapErrorToPlayerEnum("PACKAGE_SCAN", action.data.errorResponse, SCOPE.SSD));
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: (state.scope === SCOPE.AMZL) ? HelpTypes.PACKAGE_SCAN: HelpTypes.DEFAULT
                                };
                            }
                            case RESPONSE.NO_PLAN_PRESENT:
                            case RESPONSE.INVALID_PACKAGE_STATE:
                            case RESPONSE.STATION_MISMATCH: {
                                event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BarcodeScannedEvent(false): new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(false);
                                nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.ProblemSolveBinScanState(true, mapErrorToPlayerEnum("PACKAGE_SCAN", action.data.errorResponse, SCOPE.AMZL)):
                                    new window.DolphinPlayer.DrsStow.ProblemSolveBinScanState(mapErrorToPlayerEnum("PACKAGE_SCAN", action.data.errorResponse, SCOPE.SSD));
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: (state.scope === SCOPE.AMZL) ? HelpTypes.PACKAGE_SCAN: HelpTypes.DEFAULT
                                };
                            }
                            case RESPONSE.PACKAGE_ALREADY_STOWED: {
                                event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BarcodeScannedEvent(false): new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(false);
                                nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.LocationScanState(window.DolphinPlayer.Stow.LocationScanType.AISLE): new window.DolphinPlayer.DrsStow.LocationScanState(false, true, window.DolphinPlayer.DrsStow.LocationScanErrorType.PACKAGE_ALREADY_STOWED);
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: HelpTypes.LOCATION_SCAN
                                };
                            }
                            case RESPONSE.BAG_WEIGHT_LIMIT_REACHED:
                            case RESPONSE.UNSUPPORTED_BARCODE:
                            case RESPONSE.INVALID_SCAN_DONE:
                            default: {
                                event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BarcodeScannedEvent(false): new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(false);
                                nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.PackageScanState(state.isFirstPackage, true, mapErrorToPlayerEnum("PACKAGE_SCAN", action.data.errorResponse, SCOPE.AMZL)):
                                    new window.DolphinPlayer.DrsStow.PackageScanState(state.isFirstPackage, true, mapErrorToPlayerEnum("PACKAGE_SCAN", action.data.errorResponse, SCOPE.SSD));
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: (state.scope === SCOPE.AMZL) ? HelpTypes.PACKAGE_SCAN: HelpTypes.DEFAULT
                                };
                            }
                        }
                    }
                    case ApplicationActions.PACKAGE_RESCAN: {
                        if(state.isValidPackageReScan !== true) {
                            event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BarcodeScannedEvent(false): new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(false);
                            nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.PackageScanState(state.isFirstPackage, true, window.DolphinPlayer.Stow.PackageScanErrorType.INCORRECT_PACKAGE):
                                new window.DolphinPlayer.DrsStow.PackageScanState(state.isFirstPackage, true, window.DolphinPlayer.DrsStow.PackageScanErrorType.INCORRECT_PACKAGE);
                            PlayerHelper.sendMessage(event, nextState);
                            return {
                                ...state,
                                backState: nextState,
                                helpType: (state.scope === SCOPE.AMZL) ? HelpTypes.PACKAGE_SCAN: HelpTypes.DEFAULT,
                            };
                        }
                        switch (action.data.errorResponse) {
                            case RESPONSE.UNSUPPORTED_BARCODE:
                            case RESPONSE.UNKNOWN_ERROR_OCCURRED: {
                                event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BarcodeScannedEvent(false): new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(false);
                                nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.LocationScanState(window.DolphinPlayer.Stow.LocationScanType.AISLE): new window.DolphinPlayer.DrsStow.LocationScanState(true, true, window.DolphinPlayer.DrsStow.LocationScanErrorType.INCORRECT_BARCODE);
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: HelpTypes.LOCATION_SCAN
                                };
                            }
                            default: {
                                event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BarcodeScannedEvent(true): new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(true);
                                nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.LocationScanState(window.DolphinPlayer.Stow.LocationScanType.AISLE): new window.DolphinPlayer.DrsStow.LocationScanState(true, false);
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: HelpTypes.LOCATION_SCAN
                                };
                            }
                        }
                    }
                    case ApplicationActions.PACKAGE_LOCATION_SCAN: {
                        switch (action.data.errorResponse) {
                            case RESPONSE.SUCCESS:
                            case RESPONSE.PACKAGE_ALREADY_STOWED:{
                                event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BarcodeScannedEvent(true): new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(true);
                                nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.PackageScanState(false, false): new window.DolphinPlayer.DrsStow.PackageScanState(false, false);
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: (state.scope === SCOPE.AMZL) ? HelpTypes.PACKAGE_SCAN: HelpTypes.DEFAULT,
                                    isFirstPackage: false
                                };
                            }
                            case RESPONSE.INVALID_PACKAGE_SORTGROUP:
                                event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BarcodeScannedEvent(false): new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(false);
                                nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BufferPlacementState(mapErrorToPlayerEnum("LOCATION_SCAN", action.data.errorResponse, SCOPE.AMZL)): new window.DolphinPlayer.DrsStow.BufferPlacementState(mapErrorToPlayerEnum("LOCATION_SCAN", action.data.errorResponse, SCOPE.SSD));
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: HelpTypes.DEFAULT
                                };
                            case RESPONSE.BAG_NOT_OPEN: {
                                if(state.scope === SCOPE.AMZL) {
                                    event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(false);
                                    nextState = new window.DolphinPlayer.Stow.BufferPlacementState(mapErrorToPlayerEnum("LOCATION_SCAN", action.data.errorResponse, SCOPE.AMZL));
                                    PlayerHelper.sendMessage(event, nextState);
                                }
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: HelpTypes.DEFAULT
                                };
                            }
                            case RESPONSE.BAG_WEIGHT_LIMIT_REACHED:
                                if(state.scope === SCOPE.AMZL) {
                                    event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(false);
                                    nextState = new window.DolphinPlayer.Stow.PackageScanState(state.isFirstPackage, true, mapErrorToPlayerEnum("LOCATION_SCAN", action.data.errorResponse, SCOPE.AMZL))
                                    PlayerHelper.sendMessage(event, nextState);
                                }
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: (state.scope === SCOPE.AMZL) ? HelpTypes.PACKAGE_SCAN: HelpTypes.DEFAULT
                                };
                            case RESPONSE.NO_PLAN_PRESENT: {
                                event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BarcodeScannedEvent(false): new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(false);
                                nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.ProblemSolveBinScanState(true, mapErrorToPlayerEnum("LOCATION_SCAN", action.data.errorResponse, SCOPE.AMZL)):
                                    new window.DolphinPlayer.DrsStow.ProblemSolveBinScanState(mapErrorToPlayerEnum("LOCATION_SCAN", action.data.errorResponse, SCOPE.SSD));
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: HelpTypes.DEFAULT
                                };
                            }
                            case RESPONSE.INVALID_SCAN_DONE:
                            case RESPONSE.INVALID_PACKAGE_STATE:
                            case RESPONSE.INVALID_MOVE:
                            default: {
                                event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BarcodeScannedEvent(false): new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(false);
                                nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.PackageScanState(state.isFirstPackage, true, mapErrorToPlayerEnum("LOCATION_SCAN",action.data.errorResponse, SCOPE.AMZL)):
                                    new window.DolphinPlayer.DrsStow.PackageScanState(state.isFirstPackage, true, mapErrorToPlayerEnum("LOCATION_SCAN", action.data.errorResponse, SCOPE.SSD));
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: (state.scope === SCOPE.AMZL) ? HelpTypes.PACKAGE_SCAN: HelpTypes.DEFAULT
                                };
                            }
                        }
                    }

                    case ApplicationActions.PROBLEM_SOLVE_OPTION_SELECT: {
                        if(action.data.operation === PROBLEM_SOLVE.DAMAGED) {
                            event = new window.DolphinPlayer.Stow.ProblemSolveOptionSelectedEvent(window.DolphinPlayer.Stow.ProblemSolveOption.DAMAGED);
                            nextState = new window.DolphinPlayer.Stow.ProblemSolveBinScanState(false);
                            PlayerHelper.sendMessage(event, nextState);
                            return state;
                        } else if (action.data.operation === PROBLEM_SOLVE.WRONG_AISLE_CLUSTER){
                            event = new window.DolphinPlayer.Stow.ProblemSolveOptionSelectedEvent(window.DolphinPlayer.Stow.ProblemSolveOption.WRONG_AISLE);
                            nextState = new window.DolphinPlayer.Stow.ProblemSolveBinScanState(false);
                            PlayerHelper.sendMessage(event, nextState);
                            return {
                                ...state,
                                backState: nextState
                            };
                        } else {
                            event = new window.DolphinPlayer.Stow.ActionDeclinedEvent()
                            nextState = new window.DolphinPlayer.Stow.LocationScanState(window.DolphinPlayer.Stow.LocationScanType.AISLE);
                            PlayerHelper.sendMessage(event, nextState);
                            return {
                                ...state,
                                backState: nextState,
                                helpType: HelpTypes.LOCATION_SCAN
                            };
                        }
                    }
                    case ApplicationActions.PROBLEM_SOLVE_SCAN: {
                        if(action.data.errorResponse === RESPONSE.SUCCESS) {
                            event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BarcodeScannedEvent(true): new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(true);
                            nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.PackageScanState(state.isFirstPackage, false): new window.DolphinPlayer.DrsStow.PackageScanState(state.isFirstPackage, false);
                            PlayerHelper.sendMessage(event, nextState);
                            return {
                                ...state,
                                backState: nextState,
                                helpType: (state.scope === SCOPE.AMZL) ? HelpTypes.PACKAGE_SCAN: HelpTypes.DEFAULT
                            };
                        }
                        else {
                            event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.BarcodeScannedEvent(false): new window.DolphinPlayer.DrsStow.BarcodeScannedEvent(false);
                            nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.ProblemSolveBinScanState(true, mapErrorToPlayerEnum("PROBLEM_SOLVE", action.data.errorResponse, SCOPE.AMZL)):
                                new window.DolphinPlayer.DrsStow.ProblemSolveBinScanState(mapErrorToPlayerEnum("PROBLEM_SOLVE", action.data.errorResponse, SCOPE.SSD));
                            PlayerHelper.sendMessage(event, nextState);
                            return {
                                ...state,
                                backState: nextState,
                                helpType: HelpTypes.DEFAULT
                            };
                        }
                    }

                    case ApplicationActions.MOVE_TO_BUFFER: {
                        event = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.ActionAcknowledgedEvent(): new window.DolphinPlayer.DrsStow.ActionAcknowledgedEvent();
                        switch(action.data.workflow){
                            case WORKFLOW.BAG_WEIGHT_LIMIT_AT_PACKAGE_SCAN:
                            case WORKFLOW.BAG_FULL:
                                nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.BAG, window.DolphinPlayer.Stow.ContainerScanAction.CLOSE, false);
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: HelpTypes.CONTAINER_CLOSE
                                };
                            case WORKFLOW.OPEN_BAG_AT_PACKAGE_SCAN:
                                nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.BAG, window.DolphinPlayer.Stow.ContainerScanAction.OPEN, false);
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: HelpTypes.CONTAINER_PREPARE
                                };
                            case WORKFLOW.ATTACH_EMPTY_CART_AT_STOWING:
                                nextState = new window.DolphinPlayer.DrsStow.CartScanState(state.isFirstCart, false, false);
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: HelpTypes.DEFAULT
                                };
                            case WORKFLOW.STOW:
                                nextState = (state.scope === SCOPE.AMZL) ? new window.DolphinPlayer.Stow.PackageScanState(state.isFirstPackage, false): new window.DolphinPlayer.DrsStow.PackageScanState(state.isFirstPackage, false);
                                PlayerHelper.sendMessage(event, nextState);
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: (state.scope === SCOPE.AMZL) ? HelpTypes.PACKAGE_SCAN: HelpTypes.DEFAULT
                                };
                            default:
                                if(state.scope === SCOPE.AMZL) {
                                    nextState = new window.DolphinPlayer.Stow.ContainerScanState(window.DolphinPlayer.Stow.ContainerType.BAG, window.DolphinPlayer.Stow.ContainerScanAction.OPEN, false);
                                    PlayerHelper.sendMessage(event, nextState);
                                }
                                return {
                                    ...state,
                                    backState: nextState,
                                    helpType: (state.scope === SCOPE.AMZL) ? HelpTypes.CONTAINER_PREPARE: HelpTypes.DEFAULT
                                };
                        }
                    }

                    case ApplicationActions.SCRUB: {
                        if(action.data.operation === SCRUB_CONTAINER_STRINGS.scrub_location_title) {
                            event = new window.DolphinPlayer.Stow.ScrubOptionSelectedEvent(window.DolphinPlayer.Stow.ScrubOption.SCRUB_LOCATION);
                            nextState = new window.DolphinPlayer.Stow.ScrubLocationScanState();
                        }
                        else if(action.data.operation === SCRUB_CONTAINER_STRINGS.scrub_container_title){
                            event = new window.DolphinPlayer.Stow.ScrubOptionSelectedEvent(window.DolphinPlayer.Stow.ScrubOption.SCRUB_CONTAINER);
                            nextState = new window.DolphinPlayer.Stow.ScrubContainerScanState();
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            backState: nextState,
                            helpType: HelpTypes.CONTAINER_CLOSE,
                        };
                    }
                    case ApplicationActions.SCRUB_CONTAINER_OR_LOCATION: {
                        if(action.data.errorResponse === RESPONSE.SUCCESS) {
                            if(action.data.type === SCRUB_CONTAINER_STRINGS.scrub_location_title) {
                                event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(true);
                                nextState = new window.DolphinPlayer.Stow.ConfirmLocationScrubState();
                            } else if (action.data.type === SCRUB_CONTAINER_STRINGS.scrub_container_title) {
                                event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(true);
                                nextState = new window.DolphinPlayer.Stow.ConfirmContainerScrubState();
                            }
                        }
                        else {
                            if(action.data.type === SCRUB_CONTAINER_STRINGS.scrub_location_title) {
                                event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(false);
                                nextState = new window.DolphinPlayer.Stow.ScrubLocationScanState();
                            } else if (action.data.type === SCRUB_CONTAINER_STRINGS.scrub_container_title) {
                                event = new window.DolphinPlayer.Stow.BarcodeScannedEvent(false);
                                nextState = new window.DolphinPlayer.Stow.ScrubContainerScanState();
                            }
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            backState: nextState,
                            scanType: undefined,
                            helpType: HelpTypes.CONTAINER_CLOSE,
                        };
                    }
                    case ApplicationActions.SCRUB_LOCATION_CONFIRM_VIEW: {
                        if(action.data.operation === SCRUB_CONTAINER_STRINGS.scrub_cancel_button) {
                            event = new window.DolphinPlayer.Stow.ActionDeclinedEvent();
                            nextState = new window.DolphinPlayer.Stow.ScrubLocationScanState();

                        } else if (action.data.operation === SCRUB_CONTAINER_STRINGS.scrub_confirm_button) {
                            event = new window.DolphinPlayer.Stow.ActionAcknowledgedEvent();
                            nextState = new window.DolphinPlayer.Stow.ScrubLocationScanState();
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            backState: nextState,
                            helpType: HelpTypes.CONTAINER_CLOSE,
                        };
                    }
                    case ApplicationActions.SCRUB_CONTAINER_CONFIRM_VIEW: {
                        if(action.data.operation === SCRUB_CONTAINER_STRINGS.scrub_cancel_button) {
                            event = new window.DolphinPlayer.Stow.ActionDeclinedEvent();
                            nextState = new window.DolphinPlayer.Stow.ScrubContainerScanState();

                        } else if (action.data.operation === SCRUB_CONTAINER_STRINGS.scrub_confirm_button) {
                            event = new window.DolphinPlayer.Stow.ActionAcknowledgedEvent();
                            nextState = new window.DolphinPlayer.Stow.ScrubContainerScanState();
                        }
                        PlayerHelper.sendMessage(event, nextState);
                        return {
                            ...state,
                            backState: nextState,
                            helpType: HelpTypes.CONTAINER_CLOSE,
                        };
                    }
                    default:
                        return state;
                }
            }
            else {
                Logger.log.info("Drona Player not initialised, message not sent");
                return {
                    ...state,
                    isInitialized: false,
                };
            }
        }
        return {
            ...state,
            isInitialized: false,
        };
    } catch(error) {
        Logger.log.info("Player app message exception", error);
    }

}
