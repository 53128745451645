export const TEST_RESPONSE_200 = () => {
    return {
        status: 200,
        data: TEST_SUCCESS_RESPONSE
    }
}

export const TEST_SUCCESS_RESPONSE = {
    "nodeId":"BOMO",
    "responseCode":"SUCCESS",
    "routesStowProgress":[
        {
            "isStaged":false,
            "plannedNotStowedCount":2,
            "plannedNotStowedPackages":[
                "260236526204",
                "151754235245"
            ],
            "plannedPackagesCount":87,
            "plannedStowedCount":85,
            "routeCode":"R-036",
            "routeId":"aa2ec625-378f-4aeb-a3b2-a1612bb53b15",
            "sortLocationLabels":[
                "H-04.large",
                "S-10.large"
            ],
            "unplannedStowedCount":0,
            "unplannedStowedPackages":[

            ]
        },
        {
            "isStaged":false,
            "plannedNotStowedCount":1,
            "plannedNotStowedPackages":[
                "260218953462"
            ],
            "plannedPackagesCount":71,
            "plannedStowedCount":70,
            "routeCode":"R-037",
            "routeId":"182f31b0-0fc9-48c8-ba3d-07227cc23d73",
            "sortLocationLabels":[
                "S-10.large"
            ],
            "unplannedStowedCount":0,
            "unplannedStowedPackages":[

            ]
        },
        {
            "isStaged":false,
            "plannedNotStowedCount":2,
            "plannedNotStowedPackages":[
                "260192131986",
                "260236526204",
                "260192131988",
                "260236526209"
            ],
            "plannedPackagesCount":33,
            "plannedStowedCount":31,
            "routeCode":"R-015",
            "routeId":"43cf405d-6473-44c6-b1cf-73de84fae5ff",
            "sortLocationLabels":[
                "S-03.large",
                "S-04.large",
                "H-04.large",
                "S-02.large",
                "S-10.large"
            ],
            "unplannedStowedCount":6,
            "unplannedStowedPackages":[
                "260231197577",
                "151755142276",
                "260230946282",
                "151755116376",
                "260230739075",
                "260231356851"
            ]
        }
    ]
}