import React, {useContext, useEffect} from 'react';
import {DetailsCardView} from "@amzn/dolphin-uiweb-framework";
import {Logger} from "@amzn/dolphin-web-framework";
import Column from "@amzn/meridian/column";
import {
    getHeaderData,
    getLocationCardViewData,
    getLocationViewType,
    LOCATION_VIEW_TYPE
} from "./data/StowLocationViewData";
import DetailsCardWithShelfImageView from "../../details-card/DetailsCardWithShelfImageView";
import {processStow} from "../../action/StowPackageAction";
import {StowPackageContext} from "../../context/StowPackageContext";
import {blockBackPress, chainWalk, resetBlockedBackPress} from "../../../utils/Util";
import {ConfigContext} from "../../../config/context/ConfigContext";
import DetailsCardWithCartView from "../../details-card/DetailsCardWithCartView";
import {injectIntl} from "react-intl";
import ScanView from "../../../scan-view/ScanView";
import HeadingView, {HEADING_TYPE} from "../../../heading-view/HeadingView";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {setGlobalContext} from "../../../config/action/ConfigAction";
import {WORKFLOW} from "../../../workflow/Workflow";
import {getHelpData} from "./data/StowScanLocationViewData";
import {COMMON_ACTIONS} from "../../../../constants/ActionType";
import {OPERATIONAL_MODE} from "../../../../constants/Constants";
import {SCOPE} from "../../../../constants/Scope";
import {PlayerContext} from "../../../player/context/PlayerContext";
import {ScanTypes} from "../../../player/PlayerConstant";

const StowScanLocationView = (props) => {

    const {navActions: {setHelpOption, closeHelpOption, openCustomHelpOption, initContext,
        closeBackEnabled, openBackEnabled, openBackDrop}} = useContext(AppNavigationContext)
    const {stowPackageModel, stowPackageDispatch} = useContext(StowPackageContext);
    const {configViewModel, configViewDispatch} = useContext(ConfigContext)
    const {statePlayer, dispatchPlayer} = useContext(PlayerContext);

    useEffect(() => {
        const setBagFullWorkflow = () => setGlobalContext(configViewDispatch, {workflow: WORKFLOW.BAG_FULL})
        setHelpOption(true, getHelpData(props, configViewModel.scope, closeHelpOption, openCustomHelpOption, setBagFullWorkflow, dispatchPlayer))

        blockBackPress(initContext, closeBackEnabled);
        return () => {
            setHelpOption(true, {list: []});
            resetBlockedBackPress(openBackEnabled, initContext, openBackDrop);
        }
    }, []);

    const isCorrectLocationScanned = (scannedText) => {

        switch (configViewModel.scope) {
            case SCOPE.AMZL:
                const sortLocations = stowPackageModel.sortLocations;

                for (const sortLocation of sortLocations) {
                    const scannableId = chainWalk(() => sortLocation.locationInfo.scannableId, null)
                    const bagLabel = chainWalk(() => sortLocation.bagInfo.bagLabel, null)
                    if (scannedText === scannableId || scannedText === bagLabel) {
                        return true
                    }
                }
                return false;
            default:
                return true;
        }
    }

    const getPackageDestinationInfo = (scannedDestination, operationalMode, sortInfoForPackageOutput) => {
        return {
            packageTrackingId: stowPackageModel.packageTrackingId,
            scannedDestination: scannedDestination,
            correctLocationScanned: isCorrectLocationScanned(scannedDestination),
            packageInfo: stowPackageModel.packageInfo,
            getSortInfoForPackageOutput: sortInfoForPackageOutput,
            operationalMode: operationalMode,
            scope: configViewModel.scope
        }
    }

    const onScan = (input) => {
        if(stowPackageModel.loading) {
            Logger.log.info("Rescan while stowing in progress");
            return;
        }
        stowPackageDispatch({type: COMMON_ACTIONS.LOADING})
        let sortInfoForPackageOutput;
        if (WORKFLOW.STOW_TOPUP === configViewModel.workflow) {
            sortInfoForPackageOutput = getPackageDestinationInfo(input, OPERATIONAL_MODE.TOP_UP_STOW_TO_ROUTE,
                null)
        } else {
            sortInfoForPackageOutput = getPackageDestinationInfo(input, null,
                stowPackageModel.getSortInfoForPackageOutput)
        }
        statePlayer.scanType = ScanTypes.LOCATION_SCAN
        processStow(stowPackageDispatch, sortInfoForPackageOutput, configViewModel.workflow, configViewModel.sblEndpoint)
    }

    const locationViewType = getLocationViewType(stowPackageModel, configViewModel.scope)
    const headerData = getHeaderData(locationViewType, props.intl)
    const locationData = getLocationCardViewData(locationViewType, stowPackageModel, props)
    const displayShelfImage = {
        isOVPackage: stowPackageModel.isOVPackage,
        rackSize: stowPackageModel.rackSize,
        stowCoordinates: stowPackageModel.stowCoordinates
    }

    const renderLocationView = () => {
        if (LOCATION_VIEW_TYPE.CORE === locationViewType) {
            return <DetailsCardWithShelfImageView snapshots={locationData} headerFontSize="Large" display={"Row"}
                                                  shelfImageData={displayShelfImage}/>
        }
        if (LOCATION_VIEW_TYPE.AR === locationViewType) {
            return <DetailsCardWithCartView snapshots={locationData} headerFontSize="Large"/>
        }
        if (LOCATION_VIEW_TYPE.SSD === locationViewType) {
            return <DetailsCardView snapshots={locationData} headerFontSize="Large" display={"Column"}/>
        }
        return <DetailsCardView snapshots={locationData} headerFontSize="Large" display={"Row"}/>
    }

    return (
        <div>
            <Column spacingInset="medium" alignmentHorizontal="center">
                <HeadingView {...headerData} type={HEADING_TYPE.PRIMARY}/>
            </Column>
            <Column spacingInset="medium">
                {renderLocationView()}
            </Column>
            <ScanView onScan={onScan}/>
        </div>
    )
}

export default injectIntl(StowScanLocationView)
