import React, {useContext} from 'react';
import {ScanWithCodeView} from "@amzn/dolphin-uiweb-framework";
import QrCode from "../../../../../images/ic_qr_code.png";
import {COMMON_STRINGS, INDIA_LANDING_VIEW_STRINGS} from "../../../../../constants/Strings";
import {injectIntl} from "react-intl";
import ScanView from "../../../../scan-view/ScanView";
import Column from "@amzn/meridian/column";
import {getRoutesForSortLocation} from "../../../prepare-container/action/PrepareContainerAction";
import {PrepareContainerContext} from "../../../prepare-container/context/PrepareContainerContext";
import {getFormattedString} from "../../../../utils/Util";
import {COMMON_ACTIONS} from "../../../../../constants/ActionType";

const ScanContainerLocationView = (props) => {

    const {prepareContainerDispatch} = useContext(PrepareContainerContext)

    const onScan = (input) => {
        const request = {
            scannableId: input,
            includeRoutesWithAttachedContainers: false
        }
        prepareContainerDispatch({type: COMMON_ACTIONS.LOADING})
        getRoutesForSortLocation(prepareContainerDispatch, request)
    }

    const init = {
        primaryTitle: {
            title: getFormattedString(props, INDIA_LANDING_VIEW_STRINGS.scan_route_location),
        },
        secondaryTitle: {
            title: getFormattedString(props, COMMON_STRINGS.scan_location),
            type: "Bold"
        },
        image: QrCode,
        onScan: onScan
    }

    const renderView = () => {
        return <ScanWithCodeView {...init}/>
    }

    return (
        <Column spacingInset="medium" alignmentHorizontal="center">
            {renderView()}
            <ScanView onScan={onScan}/>
        </Column>
    )
}

export default injectIntl(ScanContainerLocationView)
