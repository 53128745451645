import {RESPONSE} from "../../../../../../constants/ResponseCodes";
import {BIN_STATUS} from "../../../../../../constants/BinStatus";
import {VIEW} from "../../../../../workflow/Workflow";
import {getNotificationFormat, isPrepCartWorkflow} from "../../../../../utils/Util";
import {NOTIFICATION_TYPE} from "../../../../../../constants/NotificationType";
import {COMMON_STRINGS, NOTIFICATION_STRING} from "../../../../../../constants/Strings";

export const getBagScanLocationViewData = (input) => {
    const values = {
        scannedCartId: input.activeCartId,
        scannedBagId: input.activeBagId,
        binListSize: input.binListSize,
        destinationScannableId: input.destinationScannableId,
        containerLabel: input.containerLabel
    }

    switch (input.responseCode) {

        case RESPONSE.SUCCESS:
            if (input.activeBinIndex < input.binListSize) {
                if (BIN_STATUS.BIN_WITH_PS_BAG === input.activeBin.binStatus) {
                    //todo: pending routing to ReplaceBag
                    return
                } else {
                    return {
                        view: VIEW.BAG_SCAN_VIEW,
                        notification: getNotificationFormat(NOTIFICATION_TYPE.SUCCESS,
                            NOTIFICATION_STRING.ALERT_SUCCESS_BAG_READY_AT_CART, values)
                    }
                }
            } else {
                let view
                if (isPrepCartWorkflow(input.workflow)) {
                    view = VIEW.SCAN_CART
                } else {
                    view = VIEW.SCAN_CONTAINER_LOCATION
                }
                return {
                    view: view,
                    notification: getNotificationFormat(NOTIFICATION_TYPE.SUCCESS,
                        NOTIFICATION_STRING.ALERT_SUCCESS_BINS_ALREADY_IN_CART, values)
                }
            }
        case RESPONSE.INVALID_MOVE:
        case RESPONSE.INVALID_SCAN_DONE:
            return {
                view: VIEW.BAG_SCAN_VIEW,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_INVALID_LOCATION_SCANNED)
            }
        case RESPONSE.NON_EMPTY_LOCATION:
            return {
                view: VIEW.BAG_SCAN_VIEW,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    NOTIFICATION_STRING.ALERT_ERROR_ACTIVE_AT_LOCATION, values)
            }
        default:
            return {
                view: VIEW.BAG_SCAN_VIEW,
                notification: getNotificationFormat(NOTIFICATION_TYPE.ERROR,
                    COMMON_STRINGS.default_error)
            }
    }
}
