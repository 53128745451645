import {CLEAR_GLOBAL_CONTEXT, GET_SCOPE_FOR_NODE_RESPONSE, SET_GLOBAL_CONTEXT, INITIALIZE_SBL, SET_SCOPE} from "../reducer/ConfigReducer";
import {getScopeForNodeResource, sblInitializeResource} from "../resource/ConfigResource";

const getScopeForNodeResponse = (data) => {
    return {
        type: GET_SCOPE_FOR_NODE_RESPONSE,
        data
    }
}

const initializeSblResponse = (data) => {
    return {
        type: INITIALIZE_SBL,
        data
    }
}

export const getScopeForNode = (dispatcher) => {
    getScopeForNodeResource().then(response => {
        dispatcher(getScopeForNodeResponse(response))
    }).catch(() => {
    })
}

export const initializeSbl = (dispatcher) => {
    sblInitializeResource().then(response => {
        dispatcher(initializeSblResponse(response))
    }).catch(() => {
    })
}

export const setGlobalContext = (dispatcher, data) => {
    return dispatcher({
        type: SET_GLOBAL_CONTEXT,
        data
    })
}

export const setScope = (dispatcher, data) => {
    return dispatcher({
        type: SET_SCOPE,
        data: data
    })
};

export const clearGlobalContext = (dispatcher) => {
    dispatcher({type: CLEAR_GLOBAL_CONTEXT})
}

// Todo: Fix this
export const clearWorkflow = (dispatcher) => {
    return dispatcher({
        type: SET_GLOBAL_CONTEXT,
        data: {
            workflow: undefined
        }
    })
}
