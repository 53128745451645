import React, {useContext, useEffect} from 'react';
import {ConfigContext} from "../../config/context/ConfigContext";
import {SCOPE} from "../../../constants/Scope";
import {getScopeForNode} from "../../config/action/ConfigAction";
import {NotificationContext} from "../../notification/context/NotificationContext";
import {
    getFormattedString,
    getHelpOptions,
    getSelectContainerRoutingPath,
    isNonNull
} from "../../utils/Util";
import {TaskView} from "@amzn/dolphin-uiweb-framework";
import {getPageTitle, getTasks} from "./data/TaskData";
import {APP_NAVIGATION_STRINGS, COMMON_STRINGS, HELP_CENTER_STRINGS} from "../../../constants/Strings";
import {STOW_AREA_OPERATION} from "../../../constants/StowAreaOperation";
import {AppNavigationContext} from "../../app-navigation/context/AppNavigationContext";
import {injectIntl} from "react-intl";
import Loading from "../../loading/Loading";
import {COMMON_ACTIONS} from "../../../constants/ActionType";
import {ROUTING} from "../../../constants/Routing";
import {PlayerContext} from "../../player/context/PlayerContext";
import {ApplicationActions} from "../../player/action/ApplicationActions";
import CartStageReadinessPollerInstance from "../../../util/CartStageReadinessPoller";

const LandingView = (props) => {

    const {notificationActions: {setNotification, clearNotification}} = useContext(NotificationContext);
    const {configViewModel, configViewDispatch} = useContext(ConfigContext)
    const {navActions: {setNavTitle, setHelpOption, closeHelpOption, closeBackDrop}} = useContext(AppNavigationContext)
    const {dispatchPlayer, initPlayerState} = useContext(PlayerContext);

    useEffect(() => {
        configViewDispatch({type: COMMON_ACTIONS.LOADING})
        getScopeForNode(configViewDispatch)
        CartStageReadinessPollerInstance.resetCartList();
    }, [])

    useEffect(() => {
        closeBackDrop()
        clearNotification()
        if (isNonNull(configViewModel.notification)) {
            setNotification(configViewModel.notification)
        }
        setNavTitle(props.intl.formatMessage({id: APP_NAVIGATION_STRINGS.stow}));
        if (configViewModel.scope === SCOPE.AMZL) {
            const callbacks = [
                () => {
                    dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HELP_CENTER_STRINGS.CLOSE_CONTAINER_HELP}});
                    props.history.push(getSelectContainerRoutingPath(STOW_AREA_OPERATION.CLOSE_CONTAINER))
                },
                closeHelpOption
            ]
            setHelpOption(true, getHelpOptions(callbacks,
                getFormattedString(props, HELP_CENTER_STRINGS.STOW_PROBLEMS_HELP),
                getFormattedString(props, HELP_CENTER_STRINGS.CLOSE_CONTAINER)))
            return () => setHelpOption(false, {});
        } else if (configViewModel.scope === SCOPE.AMZL_IN) {
            const callbacks = [
                () => props.history.push(ROUTING.STOW_PROGRESS),
                closeHelpOption
            ]
            setHelpOption(true, getHelpOptions(callbacks,
                getFormattedString(props, HELP_CENTER_STRINGS.STOW_PROBLEMS_HELP),
                getFormattedString(props, COMMON_STRINGS.STOW_PROGRESS)))
            return () => setHelpOption(false, {});
        } else {
            setHelpOption(true, {list: []})
        }
    }, [configViewModel])

    if (configViewModel.loading) {
        return <Loading/>
    } else {
        if(isNonNull(configViewModel.scope)) {
            initPlayerState(configViewModel.scope)
        }
        return (
            <TaskView pageTitle={getPageTitle(configViewModel.scope, props.intl)}
                      tasks={getTasks(configViewModel.scope, props.intl, props.history, dispatchPlayer)}/>
        )
    }
}

export default injectIntl(LandingView)
