import React, {Component} from "react";
import PrepareCartView from "./view/PrepareCartView";
import PrepareContainerContextProvider from "../prepare-container/context/PrepareContainerContext";

class PrepareCart extends Component {

    render() {
        return (
            <PrepareContainerContextProvider>
                <PrepareCartView history={this.props.history}/>
            </PrepareContainerContextProvider>
        )
    }
}

export default PrepareCart
