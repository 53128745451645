import React, {Component} from 'react';
import PrepareContainerView from "./view/PrepareContainerView";
import PrepareContainerContextProvider from "./context/PrepareContainerContext";

class PrepareContainer extends Component {

    render() {

        return (
            <div>
                <PrepareContainerContextProvider>
                    <PrepareContainerView history={this.props.history}
                                          containerScannableType={this.props.match.params.containerScannableType}/>
                </PrepareContainerContextProvider>
            </div>
        )
    }
}

export default PrepareContainer
