import React, {useContext, useEffect} from 'react';
import {HELP_CENTER_STRINGS, SCRUB_CONTAINER_STRINGS} from "../../../../constants/Strings";
import {FormattedMessage} from "react-intl";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {getHelpOptions, getSelectContainerRoutingPath} from "../../../utils/Util";
import {STOW_AREA_OPERATION} from "../../../../constants/StowAreaOperation";
import {HeaderView} from "@amzn/dolphin-uiweb-framework";
import Button from "@amzn/meridian/button";
import {ScrubContainerContext} from "../../context/ScrubContainerContext";
import {NotificationContext} from "../../../notification/context/NotificationContext";
import {VIEW} from "../../../workflow/Workflow";
import {scrubContainerAction} from "../../action/ScrubContainerAction";
import Column from "@amzn/meridian/column";
import {ApplicationActions} from "../../../player/action/ApplicationActions";
import {PlayerContext} from "../../../player/context/PlayerContext";

const ScrubLocationConfirmationView = (props) => {
    const {scrubContainerViewModel, scrubContainerDispatch} = useContext(ScrubContainerContext);
    const {navActions: {setHelpOption, closeHelpOption}} = useContext(AppNavigationContext);
    const {notificationActions: {clearNotification}} = useContext(NotificationContext);
    const {dispatchPlayer} = useContext(PlayerContext);

    const {primaryText, secondaryText} = {
        primaryText: {
            title: <FormattedMessage id={SCRUB_CONTAINER_STRINGS.scrub_location_guidance}/>,
            size: "large",
            type: "primary"
        },
        secondaryText: {
            title: <b><FormattedMessage id={SCRUB_CONTAINER_STRINGS.scrub_location_confirmation}
                                        values = {{locationLabel: scrubContainerViewModel.containerLabel}}/></b>,
            size: "large",
            type: "primary"
        }
    }

    useEffect(() => {
        const callbacks = [
            () => {
                dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HELP_CENTER_STRINGS.CLOSE_CONTAINER_HELP}});
                props.history.push(getSelectContainerRoutingPath(STOW_AREA_OPERATION.CLOSE_CONTAINER))
            },
            closeHelpOption
        ]
        setHelpOption(true, getHelpOptions(callbacks,
            <FormattedMessage id={HELP_CENTER_STRINGS.STOW_PROBLEMS_HELP}/>,
            <FormattedMessage id={HELP_CENTER_STRINGS.CLOSE_CONTAINER}/>));
        return () => setHelpOption(false, {});
    }, [])

    const handleCancel = () => {
        clearNotification();
        dispatchPlayer({type: ApplicationActions.SCRUB_LOCATION_CONFIRM_VIEW, data: {operation: SCRUB_CONTAINER_STRINGS.scrub_cancel_button}});
        scrubContainerViewModel.view = VIEW.SCRUB_LOCATION;
    }

    const handleConfirm = () => {
        dispatchPlayer({type: ApplicationActions.SCRUB_LOCATION_CONFIRM_VIEW, data: {operation: SCRUB_CONTAINER_STRINGS.scrub_confirm_button}});
        return scrubContainerAction(scrubContainerDispatch, scrubContainerViewModel.containerScannableId,
            scrubContainerViewModel.containerType, SCRUB_CONTAINER_STRINGS.DUMMY_DESTINATION);
    }

    return (
        <Column spacingInset="medium" alignmentHorizontal="center">
            <HeaderView primaryTitle={primaryText} secondaryTitle={secondaryText}/>
            <Column className={"footer"}>
                <Button size="xlarge" type="secondary" minWidth="100%" onClick={handleCancel}>
                    {<FormattedMessage id={SCRUB_CONTAINER_STRINGS.scrub_cancel_button}/>}</Button><br/>
                <Button size="xlarge" type="primary" minWidth="100%" onClick={handleConfirm}>
                    {<FormattedMessage id={SCRUB_CONTAINER_STRINGS.scrub_confirm_button}/>}</Button>
            </Column>
        </Column>
    )
}

export default ScrubLocationConfirmationView

