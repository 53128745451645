import React, {useContext, useEffect} from 'react';
import {
    HELP_CENTER_STRINGS,
    MANAGE_STOW_AREA_VIEW_STRINGS,
    NOTIFICATION_STRING,
    SCRUB_CONTAINER_STRINGS
} from "../../../../../constants/Strings";
import {FormattedMessage} from "react-intl";
import {AppNavigationContext} from "../../../../app-navigation/context/AppNavigationContext";
import {getHelpOptions, getSelectContainerRoutingPath, SLASH} from "../../../../utils/Util";
import {STOW_AREA_OPERATION} from "../../../../../constants/StowAreaOperation";
import {HeaderView} from "@amzn/dolphin-uiweb-framework";
import Button from "@amzn/meridian/button";
import {VIEW} from "../../../../workflow/Workflow";
import Column from "@amzn/meridian/column";
import {PrepareContainerContext} from "../../context/PrepareContainerContext";
import {CONTAINER_TYPE} from "../../../../../constants/ContainerType";
import {ROUTING} from "../../../../../constants/Routing";
import {NOTIFICATION_TYPE} from "../../../../../constants/NotificationType";
import {NotificationContext} from "../../../../notification/context/NotificationContext";
import {PlayerContext} from "../../../../player/context/PlayerContext";
import {ApplicationActions} from "../../../../player/action/ApplicationActions";


const ReplaceNonEmptyBagView = (props) => {
    const {navActions: {setNavTitle, setHelpOption, closeHelpOption, openBackEnabled}} = useContext(AppNavigationContext)
    const {prepareContainerModel} = useContext(PrepareContainerContext);
    const {notificationActions: {setNotification}} = useContext(NotificationContext);
    const {dispatchPlayer} = useContext(PlayerContext);


    const {primaryText, secondaryText} = {
        primaryText: {
            title: <b><FormattedMessage id={SCRUB_CONTAINER_STRINGS.move_bag_to_problem_solve}/></b>,
            size: "large",
            type: "primary"
        },
        secondaryText: {
            title: <FormattedMessage id = {SCRUB_CONTAINER_STRINGS.move_bag_guidance}/>,
            size: "medium",
            type: "primary"
        }
    }

    useEffect(() => {
        openBackEnabled()
    }, []);

    useEffect(() => {
        const callbacks = [
            () => {
                dispatchPlayer({type: ApplicationActions.HELP_OPTION_SELECTED, data: {operation: HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP}});
                props.history.push(getSelectContainerRoutingPath(STOW_AREA_OPERATION.PREPARE_CONTAINER))
            },
            closeHelpOption
        ]
        setHelpOption(true, getHelpOptions(callbacks,
            <FormattedMessage id={HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP}/>,
            <FormattedMessage id={HELP_CENTER_STRINGS.PREPARE_CONTAINER_HELP_TEXT}/>));
        return () => setHelpOption(false, {});
    }, [])

    const handleClick = () => {
        prepareContainerModel.view = VIEW.SCAN_BAG;
        dispatchPlayer({type: ApplicationActions.CONTAINER_SCRUB_BAG_DECLINED});
        setNotification(moveNotification)
        props.history.push(ROUTING.PREPARE_CONTAINER + SLASH + CONTAINER_TYPE.BAG)
    }

    const moveNotification = {
        type: NOTIFICATION_TYPE.SUCCESS,
        message: NOTIFICATION_STRING.ALERT_SUCCESS_BAG_MOVED_TO_PS,
        values: {
            displayBagId: prepareContainerModel.displayBagId
        }
    }

    useEffect(() => {
        setNavTitle(<FormattedMessage id={MANAGE_STOW_AREA_VIEW_STRINGS.prepare_container}/>);
    }, [])

    return (
        <Column spacingInset="medium" alignmentHorizontal="center">
            <HeaderView primaryTitle={primaryText} secondaryTitle={secondaryText}/>
            <Column className={"footer"}>
                <Button size="xlarge" type="primary" minWidth="100%"
                        onClick={handleClick}>{<FormattedMessage id={SCRUB_CONTAINER_STRINGS.btn_confirm_bag_moved}/>}</Button><br/>
            </Column>
        </Column>
    )
}

export default ReplaceNonEmptyBagView

