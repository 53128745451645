import React, {useContext, useEffect} from 'react'
import {VIEW} from "../../../workflow/Workflow"
import {CloseContainerContext} from "../context/CloseContainerContext"
import CloseScanBagView from "./scan-bag/CloseScanBagView"
import CloseScanBagLocationView from "./scan-bag-location/CloseScanBagLocationView"
import {isNonNull} from "../../../utils/Util";
import {NotificationContext} from "../../../notification/context/NotificationContext"
import {FormattedMessage} from "react-intl";
import {APP_NAVIGATION_STRINGS} from "../../../../constants/Strings";
import {AppNavigationContext} from "../../../app-navigation/context/AppNavigationContext";
import {ConfigContext} from "../../../config/context/ConfigContext";
import {setGlobalContext} from "../../../config/action/ConfigAction";
import {CONTAINER_TYPE} from "../../../../constants/ContainerType";
import CloseDefaultContainerView from "./CloseDefaultContainerView";
import {ROUTING} from "../../../../constants/Routing";
import {interceptBackButton} from "../../../bridge/BackButton";
import {ScanTypes} from "../../../player/PlayerConstant";
import {ApplicationActions} from "../../../player/action/ApplicationActions";
import {STOW_AREA_OPERATION} from "../../../../constants/StowAreaOperation";
import {PlayerContext} from "../../../player/context/PlayerContext";

const CloseContainerView = (props) => {

    const {configViewDispatch} = useContext(ConfigContext)
    const {closeContainerModel} = useContext(CloseContainerContext)
    const {notificationActions: {setNotification}} = useContext(NotificationContext)
    const {navActions: {clearContext, initContext, openBackDrop, setNavTitle}} = useContext(AppNavigationContext)
    const {statePlayer, dispatchPlayer} = useContext(PlayerContext);

    useEffect(() => {
        if (isNonNull(closeContainerModel.notification)) {
            setNotification(closeContainerModel.notification)
        }
        if (isNonNull(closeContainerModel.nextRoute)) {
            props.history.push(closeContainerModel.nextRoute)
        }
        if (isNonNull(closeContainerModel.workflow)) {
            setGlobalContext(configViewDispatch, {workflow: closeContainerModel.workflow})
        }
        if (isNonNull(closeContainerModel.responseCode) && !(closeContainerModel.loading)) {
            switch (statePlayer.scanType) {
                case ScanTypes.CLOSE_BAG_SCAN:
                    dispatchPlayer({type: ApplicationActions.CONTAINER_SCAN, data: {errorResponse: closeContainerModel.responseCode,
                            operation: STOW_AREA_OPERATION.CLOSE_CONTAINER, type: CONTAINER_TYPE.BAG}});
                    break;
                case ScanTypes.CLOSE_BAG_LOCATION_SCAN:
                    dispatchPlayer({type: ApplicationActions.CONTAINER_LOCATION_SCAN, data: {errorResponse: closeContainerModel.responseCode,
                            type: CONTAINER_TYPE.BAG, operation: STOW_AREA_OPERATION.CLOSE_CONTAINER}});
                    break;
                case ScanTypes.CLOSE_CART_SCAN:
                    dispatchPlayer({type: ApplicationActions.CONTAINER_SCAN, data: {errorResponse: closeContainerModel.responseCode,
                            operation: STOW_AREA_OPERATION.CLOSE_CONTAINER, type: CONTAINER_TYPE.CART}});
                    break;
                case ScanTypes.CLOSE_PALLET_SCAN:
                    dispatchPlayer({type: ApplicationActions.CONTAINER_SCAN, data: {errorResponse: closeContainerModel.responseCode,
                            operation: STOW_AREA_OPERATION.CLOSE_CONTAINER, type: CONTAINER_TYPE.PALLET}});
                    break;
                default:
                    break;
            }
        }
    }, [closeContainerModel]);

    useEffect(() => {
        const onBackPressed = () => {
            openBackDrop({
                secondaryButtonOnClick: () => props.history.push(ROUTING.ENTRY)
            });
        };
        initContext({onBackPressed});
        interceptBackButton('close container view');
        return clearContext
    }, []);

    const renderView = () => {
        switch (props.containerScannableType) {
            case CONTAINER_TYPE.BAG:
                if (VIEW.CLOSE_SCAN_BAG_LOCATION === closeContainerModel.view) {
                    return <CloseScanBagLocationView/>
                } else {
                    return <CloseScanBagView history={props.history}/>
                }
            default:
                return <CloseDefaultContainerView history={props.history} containerScannableType={props.containerScannableType}/>
        }
    }

    useEffect(() => {
        setNavTitle(<FormattedMessage id={APP_NAVIGATION_STRINGS.close_container}/>);
    }, []);

    return (
        <div>
            {renderView()}
        </div>
    )
}

export default CloseContainerView
