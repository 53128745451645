import React, {Component} from 'react';
import ProblemSolveView from "./view/ProblemSolveView";
import ProblemSolveContextProvider from "./context/ProblemSolveContext";

class ProblemSolve extends Component {

    render() {

        return (
            <div>
                <ProblemSolveContextProvider>
                    <ProblemSolveView history={this.props.history}
                                      problemSolveReasonCode={this.props.match.params.problemSolveReasonCode}/>
                </ProblemSolveContextProvider>
            </div>
        )
    }
}

export default ProblemSolve
