import {getSALTemplateForPrinterResource} from "../resource/StowSelectionResource";

export const GET_SAL_TEMPLATE = "GET_SAL_TEMPLATE"

export const getSalTemplateForPrinter = (dispatcher, printerType) => {
    getSALTemplateForPrinterResource(printerType).then(data => {
        dispatcher({
            type:GET_SAL_TEMPLATE,
            data
        })
    })
}