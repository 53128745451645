
export const CONSTANT = {
    CON_PACKAGE: "CON_PACKAGE",
    NON_CON_PACKAGE: "NON_CON_PACKAGE",
    TOPUP_PACKAGE: "TOPUP_PACKAGE"
}

export const OPERATIONAL_MODE = {
    TOP_UP_STOW_TO_ROUTE: "TOP_UP_STOW_TO_ROUTE",
    FLOW_STOW_TO_ROUTE: "FLOW_STOW_TO_ROUTE"
}

export const NotificationType = {
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error"
}

export const NO_OP = "NO_OP";