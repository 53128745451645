import React, {Component} from 'react';
import SelectContainerView from "./view/SelectContainerView";

class SelectContainer extends Component {

    render() {

        return (
            <div>
                <SelectContainerView history={this.props.history}
                                     stowAreaOperation={this.props.match.params.stowAreaOperation}/>
            </div>
        )
    }
}

export default SelectContainer
