import {dev_debug, NO_INTERNET_RESPONSE, UNKNOWN_ERROR_OCCURRED_RESPONSE} from "../../../utils/Util";
import {API_PATH, axiosGET, axiosPOST} from "../../../utils/network/NetworkUtils";
import * as ScanBagTestData
    from "../../../../tst/components/manage-stow-area/prepare-container/view/scan-bag/test-data/ScanBagTestData";
import * as ScanBagLocationTestData
    from "../../../../tst/components/manage-stow-area/prepare-container/view/scan-bag-location/test-data/ScanBagLocationTestData";
import {CONTAINER_TYPE} from "../../../../constants/ContainerType";
import * as ScanCartTestData from "../../../../tst/components/manage-stow-area/prepare-cart/view/scan-cart/test-data/ScanCartTestData";
import * as ScanCartLocationTestData
    from "../../../../tst/components/manage-stow-area/prepare-cart/view/scan-cart-location/test-data/ScanCartLocationTestData";
import {RESPONSE} from "../../../../constants/ResponseCodes";
import * as ScanContainerLocationTestData
    from "../../../../tst/components/manage-stow-area/prepare-route/view/scan-container-location/test-data/ScanContainerLocationTestData";
import {AnalyticEventKeys, Constants, MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {METRIC_KEYS} from "../../../workflow/Workflow";


export const validateBagForOpen = async (bagScannableId, scope) => {
    const startTime = Date.now();
    const request = {
        bagScannableId: bagScannableId,
        scope: scope
    }
    try {
        const response = dev_debug === true
            ? ScanBagTestData.TEST_RESPONSE_200()
            : await axiosPOST(AnalyticEventKeys.Modules.VALIDATE_OPEN_BAG, API_PATH.VALIDATE_BAG_BEFORE_OPEN, request);

        if (response.status === 200) {
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.VALIDATE_OPEN_BAG, startTime);
            return response.data
        }
    } catch(error) {
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE
        }
        return UNKNOWN_ERROR_OCCURRED_RESPONSE
    }
    MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.VALIDATE_OPEN_BAG, startTime, true);
}

export const openBag = async (bagScannableId, destinationScannableId, scope) => {
    const startTime = Date.now();
    const request = {
        bagScannableId: bagScannableId,
        destinationScannableId: destinationScannableId,
        scope: scope
    }
    try {
        const response = dev_debug === true
            ? ScanBagLocationTestData.TEST_RESPONSE_200()
            : await axiosPOST(AnalyticEventKeys.Modules.OPEN_BAG, API_PATH.OPEN_BAG, request);
        if (response.status === 200) {
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.OPEN_BAG, startTime);
            return response.data
        }
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.OPEN_BAG, startTime, true);
    } catch(error) {
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.OPEN_BAG, startTime, true);
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE
        }
        return UNKNOWN_ERROR_OCCURRED_RESPONSE
    }
}

export const validateContainerBeforeAttach = async (requestBO) => {
    const startTime = Date.now();
    const request = {
        containerScannableId: requestBO.containerScannableId,
        containerScannableType: requestBO.containerScannableType
    };
    try {
        const response = dev_debug === true
            ? (request.containerScannableType === CONTAINER_TYPE.CART
                ? ScanCartTestData.TEST_RESPONSE_CART_200()
                : ScanCartTestData.TEST_RESPONSE_BAG_200())
            : await axiosPOST(AnalyticEventKeys.Modules.VALIDATE_CONTAINER_BEFORE_ATTACH, API_PATH.VALIDATE_CONTAINER_BEFORE_ATTACH, request);

        if (response.status === 200) {
            MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.VALIDATE_CONTAINER_BEFORE_ATTACH_OUTPUT, startTime);
            return response.data
        }
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.VALIDATE_CONTAINER_BEFORE_ATTACH_OUTPUT, startTime, true);
    } catch(error) {
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.VALIDATE_CONTAINER_BEFORE_ATTACH_OUTPUT, startTime, true);
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE
        }
        return UNKNOWN_ERROR_OCCURRED_RESPONSE
    }
    return {
        responseCode: RESPONSE.UNKNOWN_ERROR_OCCURRED
    }

}

export const attachContainerToDestination = async (requestBO) => {
    const startTime = Date.now();
    const request = {
        containerScannableType: requestBO.containerScannableType,
        containerScannableId: requestBO.containerScannableId,
        destinationScannableId: requestBO.destinationScannableId,
        operationalContext: {
            routeId: requestBO.routeId
        }
    };
    let containerLabel = request.containerScannableId ? request.containerScannableId: "";
    try {
        const response = dev_debug === true
            ? ScanCartLocationTestData.TEST_RESPONSE_200(requestBO.containerScannableType)
            : await axiosPOST(AnalyticEventKeys.Modules.ATTACH_CONTAINER_TO_DESTINATION, API_PATH.ATTACH_CONTAINER_TO_DESTINATION, request);

        if (response.status === 200) {
            if(!containerLabel && response.data && response.data.containerLabel) {
                containerLabel = response.data.containerLabel;
            }
            MobileAnalyticsHelper.processAnalytics(startTime, AnalyticEventKeys.Modules.PWA, response,
                AnalyticEventKeys.Modules.ATTACH_CONTAINER_TO_DESTINATION_OUTPUT, containerLabel,
                request.destinationScannableId, AnalyticEventKeys.Modules.ATTACH_CONTAINER_TO_DESTINATION_OUTPUT);

            MobileAnalyticsHelper.processAnalytics(startTime, requestBO.containerScannableType, response, AnalyticEventKeys.Modules.STOW
                , containerLabel, request.destinationScannableId, AnalyticEventKeys.Events.USER_SCANNED_PACKAGE,
                false, METRIC_KEYS.PREPARING_PALLET_SUCCESSFUL)
            return response.data
        }
        MobileAnalyticsHelper.processAnalytics(startTime, AnalyticEventKeys.Modules.PWA, response,
            AnalyticEventKeys.Modules.ATTACH_CONTAINER_TO_DESTINATION_OUTPUT, containerLabel,
            request.destinationScannableId, AnalyticEventKeys.Modules.ATTACH_CONTAINER_TO_DESTINATION_OUTPUT, true);
        MobileAnalyticsHelper.processAnalytics(startTime, requestBO.containerScannableType, response, AnalyticEventKeys.Modules.STOW
            , containerLabel, request.destinationScannableId, AnalyticEventKeys.Events.USER_SCANNED_PACKAGE,
            true, METRIC_KEYS.PREPARING_PALLET_FAILURE)
    } catch(error) {
        MobileAnalyticsHelper.processAnalytics(startTime, AnalyticEventKeys.Modules.PWA, "",
            AnalyticEventKeys.Modules.ATTACH_CONTAINER_TO_DESTINATION_OUTPUT, containerLabel,
            request.destinationScannableId, AnalyticEventKeys.Modules.ATTACH_CONTAINER_TO_DESTINATION_OUTPUT, true);
        MobileAnalyticsHelper.processAnalytics(startTime, requestBO.containerScannableType, "", AnalyticEventKeys.Modules.STOW
            , containerLabel, request.destinationScannableId, AnalyticEventKeys.Events.USER_SCANNED_PACKAGE,
            true, METRIC_KEYS.PREPARING_PALLET_FAILURE)
        if (error.message === Constants.ErrorCode.DEVICE_OFFLINE) {
            return NO_INTERNET_RESPONSE
        }
        return UNKNOWN_ERROR_OCCURRED_RESPONSE
    }
    return {
        responseCode: RESPONSE.UNKNOWN_ERROR_OCCURRED
    }
}

export const getRoutesForSortLocation = async (request) => {
    const startTime = Date.now();
    const params = {
        params: {
            scannableId: request.scannableId,
            includeRoutesWithAttachedContainers: request.includeRoutesWithAttachedContainers
        }
    };
    const response = dev_debug === true
        ? ScanContainerLocationTestData.TEST_RESPONSE_200()
        : await axiosGET(AnalyticEventKeys.Modules.GET_ROUTES_FOR_SORT_LOCATION, API_PATH.GET_ROUTES_FOR_SORT_LOCATION, params);

    if (response.status === 200) {
        MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_ROUTES_FOR_SORT_LOCATION, startTime);
        return response.data
    }
    MobileAnalyticsHelper.executeAPIAnalytics(AnalyticEventKeys.Modules.GET_ROUTES_FOR_SORT_LOCATION, startTime, true);
    return {
        responseCode: RESPONSE.UNKNOWN_ERROR_OCCURRED
    }
}
