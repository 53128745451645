import React, {useCallback, useContext, useEffect} from "react"
import helpTokens from "@amzn/meridian-tokens/base/icon/help"
import {HelpView, TopNavigationView} from "@amzn/dolphin-uiweb-framework"
import Column from "@amzn/meridian/column"
import {AppNavigationContext} from "./context/AppNavigationContext"
import help_icon from "../../images/ic_help_coming_up.png"
import {injectIntl} from "react-intl";
import ProblemSolveHelpView from "./ProblemSolveHelpView";
import {getFormattedString} from "../utils/Util";
import {clearBackPressedEventListener, interceptBackButton, setBackPressedEventListener} from "../bridge/BackButton";
import AppBackdrop from "./AppBackDrop";
import { useLocation } from "react-router-dom";
import {ROUTING} from "../../constants/Routing";
import {NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {ApplicationActions} from "../player/action/ApplicationActions";
import {PlayerContext} from "../player/context/PlayerContext";

export const CUSTOM_HELP_OPTIONS = {
    PROBLEM_SOLVE: "PROBLEM_SOLVE",
    STOW_MANUAL_SCAN_PACKAGE: "STOW_MANUAL_SCAN_PACKAGE"
}

const AppNavigation = (props) => {
    const {navigationModel, navActions} = useContext(AppNavigationContext)
    const {dispatchPlayer} = useContext(PlayerContext);

    const getCustomHelpOption = () => {
        if (CUSTOM_HELP_OPTIONS.PROBLEM_SOLVE === navigationModel.customHelpOption.type) {
            return <ProblemSolveHelpView history={props.history} onClose={navActions.closeCustomHelpOption}/>
        }
    }

    let location = useLocation();
    const handleBackButtonPress = () => {
        if(location.pathname === ROUTING.ENTRY) {
            NativeMeshInteractor.exitModule();
        }
        if(navigationModel.backEnabled && location.pathname !== ROUTING.ENTRY) {
            navActions.openBackDrop();
            interceptBackButton('Back Button Pressed')
        }
    };

    /**
     * The following code is listening to the back press events
     * The code is supposed to close any views like help or backdrop view
     * in case of either are not visible we call onBackPressed function from the appState
     * the default behaviour is to go back in history
     */
    const onBackPressed = useCallback(() => {
        // TODO: handle backpress for all screens for the help options to be closed
        if (navigationModel.helpOptions.open) {
            navActions.closeHelpOption()
        } else if (navigationModel.backDropOpen) {
            navActions.closeBackDrop()
        } else if (navigationModel.onBackPressed) {
            navigationModel.onBackPressed.call();
        }
    }, [navigationModel.onBackPressed, navigationModel.backDropOpen, navigationModel.helpOptions]);

    useEffect(() => {
        setBackPressedEventListener(onBackPressed);
        return clearBackPressedEventListener;
    }, [navigationModel.onBackPressed, navigationModel.backDropOpen, navigationModel.helpOptions]);

    return (
        <Column>
            {navigationModel.customHelpOption.open ? getCustomHelpOption() :
            <TopNavigationView
                title={getFormattedString(props, navigationModel.navTitle)}
                backgroundColor={navigationModel.navColor}
                onMenuButtonClicked={() => handleBackButtonPress()}
                optionsMenu={navigationModel.helpEnabled ? [{
                    icon: helpTokens, onClick: navActions.openHelpOption
                }] : []}/>}
            {navigationModel.helpOptions.open && <HelpView
                onClose={() => {navActions.closeHelpOption(); dispatchPlayer({type: ApplicationActions.HELP_CLOSED})}}
                title={getFormattedString(props, navigationModel.helpOptions.title)}
                pageTitle={getFormattedString(props, navigationModel.helpOptions.pageTitle)}
                list={navigationModel.helpOptions.list}
                help_icon={help_icon}/>}
            {navigationModel.backDropOpen && <AppBackdrop history={props.history}/>}
        </Column>
    )
}

export default injectIntl(AppNavigation)
