export const VIEW = {
    SCAN_BAG: "SCAN_BAG",
    SCAN_BAG_LOCATION: "SCAN_BAG_LOCATION",
    STOW_PACKAGE: "STOW_PACKAGE",
    STOW_RESCAN_PACKAGE: "STOW_RESCAN_PACKAGE",
    STOW_MANUAL_SCAN_PACKAGE: "STOW_MANUAL_SCAN_PACKAGE",
    STOW_LOCATION: "STOW_LOCATION",
    CLOSE_SCAN_BAG: "CLOSE_SCAN_BAG",
    CLOSE_SCAN_BAG_LOCATION: "CLOSE_SCAN_BAG_LOCATION",
    MANAGE_STOW_AREA: "MANAGE_STOW_AREA",
    MOVE_TO_BUFFER: "MOVE_TO_BUFFER",
    SCRUB_CONTAINER: "SCRUB_CONTAINER",
    SCRUB_LOCATION: "SCRUB_LOCATION",
    SCRUB_CONTAINER_CONFIRM: "SCRUB_CONTAINER_CONFIRM",
    SCRUB_LOCATION_CONFIRM: "SCRUB_LOCATION_CONFIRM",
    STOW_FLOW: "STOW_FLOW",
    STOW_PROGRESS_SCAN_LOCATION: "STOW_PROGRESS_SCAN_LOCATION",
    STOW_PROGRESS_ROUTE: "STOW_PROGRESS_ROUTE",
    SCAN_CART: "SCAN_CART",
    SCAN_CART_LOCATION: "SCAN_CART_LOCATION",
    BAG_SCAN_VIEW: "BAG_SCAN_VIEW",
    BAG_SCAN_LOCATION_VIEW: "BAG_SCAN_LOCATION_VIEW",
    SCAN_CONTAINER_LOCATION: "SCAN_CONTAINER_LOCATION",
    REPLACE_BAG: "REPLACE_BAG",
    SCAN_CONTAINER: "SCAN_CONTAINER",
    SCRUB_NON_EMPTY_BAG: "SCRUB_NON_EMPTY_BAG",
    SCRUB_NON_EMPTY_BAG_CONFIRM: "SCRUB_NON_EMPTY_BAG_CONFIRM",
    REPLACE_NON_EMPTY_BAG: "REPLACE_NON_EMPTY_BAG",
    SCRUB_NON_EMPTY_LOCATION: "SCRUB_NON_EMPTY_LOCATION",
    SCRUB_NON_EMPTY_LOCATION_CONFIRM: "SCRUB_NON_EMPTY_LOCATION_CONFIRM"
}

export const WORKFLOW = {
    BAG_FULL: "BAG_FULL",
    BAG_WEIGHT_LIMIT_AT_PACKAGE_SCAN: "BAG_WEIGHT_LIMIT_AT_PACKAGE_SCAN",
    OPEN_BAG_AT_PACKAGE_SCAN: "OPEN_BAG_AT_PACKAGE_SCAN",
    OPEN_BAG_IN_SAME_LOCATION: "OPEN_BAG_IN_SAME_LOCATION",
    PREP_CART: "PREP_CART",
    ATTACH_CART: "ATTACH_CART",
    CON_PACKAGE: "CON_PACKAGE",
    ATTACH_EMPTY_CART_AT_STOWING: "ATTACH_EMPTY_CART_AT_STOWING",
    STOW: "STOW",
    STOW_FLOW: "STOW_FLOW",
    STOW_TOPUP: "STOW_TOPUP"
}

export const METRIC_KEYS = {
    CLOSE_CONTAINER_FAILURE: "CLOSE_CONTAINER_FAILURE",
    CLOSE_CONTAINER_SUCCESSFUL: "CLOSE_CONTAINER_SUCCESSFUL",
    PREPARING_PALLET_SUCCESSFUL: "PREPARING_PALLET_SUCCESSFUL",
    PREPARING_PALLET_FAILURE: "PREPARING_PALLET_FAILURE"
}


export const getNavTitleForWorkflow = (workflow, defaultTitle) => {
    return defaultTitle
};
