import React, {useState, useContext} from "react";
import Column from "@amzn/meridian/column";
import {FormattedMessage} from "react-intl";
import {COMMON_STRINGS, PROBLEM_SOLVE_VIEW_STRINGS} from "../../constants/Strings";
import Button from "@amzn/meridian/button";
import Box from "@amzn/meridian/box";
import RadioButton from "@amzn/meridian/radio-button";
import {PROBLEM_SOLVE} from "../../constants/ProblemSolveCodes";
import {ROUTING} from "../../constants/Routing";
import {SLASH} from "../utils/Util";
import HeadingView, {HEADING_TYPE} from "../heading-view/HeadingView";
import Icon from "@amzn/meridian/icon";
import closeTokens from "@amzn/meridian-tokens/base/icon/close";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import {interceptBackButtonStop} from "../bridge/BackButton";
import {PlayerContext} from "../player/context/PlayerContext";
import {ApplicationActions} from "../player/action/ApplicationActions";

const ProblemSolveHelpView = (props) => {
    const [checkedValue, setCheckedValue] = useState(PROBLEM_SOLVE.WRONG_AISLE_CLUSTER)
    const {dispatchPlayer} = useContext(PlayerContext);

    const PROBLEM_SOLVE_REASON = "PROBLEM_SOLVE_REASON"

    const onClickConfirm = () => {
        interceptBackButtonStop();
        dispatchPlayer({type: ApplicationActions.PROBLEM_SOLVE_OPTION_SELECT, data: {operation: checkedValue}});
        props.onClose();
        props.history.push(ROUTING.PROBLEM_SOLVE + SLASH + checkedValue)
    }

    const onClickCancel = () => {
        dispatchPlayer({type: ApplicationActions.PROBLEM_SOLVE_OPTION_SELECT, data: {operation: COMMON_STRINGS.CANCEL}});
        props.onClose();
    }

    const init = {
        primaryTitle: PROBLEM_SOLVE_VIEW_STRINGS.problem_solve,
        secondaryTitle: PROBLEM_SOLVE_VIEW_STRINGS.confirm_reason,
        type: HEADING_TYPE.SECONDARY
    }

    return (
        <Column className={"helpView"} width={"100%"} height={"100%"} backgroundColor="#ffffff">
            <Row backgroundColor="#191919" width="100%" widths={["fill"]} spacingInset="medium" spacing="none">
                <Text type="h200" color={"inverted"} alignment={"center"}>Problem solve</Text>
                <div style={{position: "absolute", right: 0}}>
                    <Button label={"close"} type="icon" color={"inverted"} onClick={props.onClose}>
                        <Text color={"inverted"}><Icon tokens={closeTokens}/></Text>
                    </Button>
                </div>
            </Row>
            <HeadingView {...init}/>
            <Box type="outline" spacingInset="medium">
                <Column spacingInset="small">
                    <RadioButton
                        checked={checkedValue === PROBLEM_SOLVE.WRONG_AISLE_CLUSTER}
                        onChange={setCheckedValue}
                        name={PROBLEM_SOLVE_REASON}
                        value={PROBLEM_SOLVE.WRONG_AISLE_CLUSTER}
                    >
                        <FormattedMessage id={COMMON_STRINGS.WRONG_CLUSTER_AISLE}/>
                    </RadioButton>
                </Column>
                <Column spacingInset="small">
                    <RadioButton
                        checked={checkedValue === PROBLEM_SOLVE.DAMAGED}
                        onChange={setCheckedValue}
                        name={PROBLEM_SOLVE_REASON}
                        value={PROBLEM_SOLVE.DAMAGED}
                    >
                        <FormattedMessage id={COMMON_STRINGS.DAMAGED}/>
                    </RadioButton>
                </Column>
            </Box>
            <Column className={"footer"} spacingInset={"small"}>
                <Button size="large" onClick={onClickConfirm}>
                    <FormattedMessage id={COMMON_STRINGS.CONFIRM}/>
                </Button>
                <Button size="large" type="secondary" onClick={onClickCancel}>
                    <FormattedMessage id={COMMON_STRINGS.CANCEL}/>
                </Button>
            </Column>
        </Column>
    )
}

export default ProblemSolveHelpView
