import {
    getBagSortInfoForCloseResource,
    getCloseBagResource,
    getCloseContainerResource
} from "../resource/CloseContainerResource"

export const GET_BAG_SORT_INFO_FOR_CLOSE = "GET_BAG_SORT_INFO_FOR_CLOSE"
export const CLOSE_BAG = "CLOSE_BAG"
export const CLOSE_CONTAINER = "CLOSE_CONTAINER"

export const getBagSortInfoForClose = (dispatcher, bagScannableId, scope) => {
    getBagSortInfoForCloseResource(bagScannableId, scope).then(data => {
        dispatcher({
            type: GET_BAG_SORT_INFO_FOR_CLOSE,
            data: {
                ...data,
                bagLabel: bagScannableId
            }
        })
    }).catch(() => {
    })
}

export const closeBag = (dispatcher, containerScannableId, destinationScannableId, scope) => {
    getCloseBagResource(containerScannableId, destinationScannableId, scope).then(data => {
        dispatcher({
            type: CLOSE_BAG,
            data
        })
    }).catch(() => {
    })
}

export const closeContainer = (dispatcher, request) => {
    getCloseContainerResource(request).then(data => {
        dispatcher({
            type: CLOSE_CONTAINER,
            data
        })
    }).catch(() => {
    })
}
