import React, {useContext, useEffect} from 'react'
import {injectIntl} from "react-intl";
import {StowProgressContext} from "../context/StowProgressContext";
import {VIEW} from "../../workflow/Workflow";
import ScanLocationForStowProgress from "./scan-location/ScanLocationForStowProgressView";
import RouteForStowProgress from "./route/RouteForStowProgressView";
import {AppNavigationContext} from "../../app-navigation/context/AppNavigationContext";
import {isNonNull} from "../../utils/Util";
import {NotificationContext} from "../../notification/context/NotificationContext";
import Column from "@amzn/meridian/column";

const StowProgressView = (props) => {

    const {stowProgressModel} = useContext(StowProgressContext)
    const {navActions: {setHelpOption}} = useContext(AppNavigationContext)
    const {notificationActions: {setNotification}} = useContext(NotificationContext)

    useEffect(() => {
        if (isNonNull(stowProgressModel.notification)) {
            setNotification(stowProgressModel.notification)
        }
    }, [stowProgressModel]);

    useEffect(() => {
        setHelpOption(true, {list: []})
    }, []);

    const renderView = () => {
        if (VIEW.STOW_PROGRESS_ROUTE === stowProgressModel.view) {
            return <RouteForStowProgress history={props.history}/>
        } else {
            return <ScanLocationForStowProgress/>
        }
    }

    return (
        <Column spacing="none">
            {renderView()}
        </Column>
    )
}

export default injectIntl(StowProgressView)