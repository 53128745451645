import {GET_SAL_TEMPLATE} from "../action/StowSelectionAction";
import {processSALResponse} from "../view/con-package/data/StowPrintingData";
import {ROUTING} from "../../../constants/Routing";

const StowSelectionReducer = (state, action) => {

    let nextRoute
    switch (action.type) {
        case GET_SAL_TEMPLATE:
            nextRoute = processSALResponse(action.data) ? ROUTING.STOW_PACKAGE: ''
            return {
                ...state, nextRoute: nextRoute
            }
        default:
            return state
    }
}

export default StowSelectionReducer